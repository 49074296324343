import { apiSlice } from '../apiSlice';

const apiClientPartNotificationsSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getClientNotifications: builder.query({
            query: (query) =>
                `client/notifications?limit=3${query.page}&sort_by=published_at&sort_order=desc`,
            providesTags: ['Notifications'],
        }),
        getClientNotification: builder.query({
            query: (query) => `client/notifications/${query.id}`,
            method: 'GET',
            providesTags: ['Notifications'],
        }),
        getNewNotifications: builder.query({
            query: () => `client/notifications/new`,
            // invalidatesTags: ['Notifications'],
            providesTags: ['Notifications'],
        }),
        evaluateNotification: builder.mutation({
            query: (data) => ({
                url: `client/notifications/${data.id}/evaluation?evaluation=${data.evaluation}`,
                method: 'POST',
            }),
            invalidatesTags: ['Notifications'],
        }),
        sendFeedbackOnNotification: builder.mutation({
            query: (data) => ({
                url: `client/notifications/${data.id}/feedback`,
                method: 'POST',
                body: data.body,
            }),
            invalidatesTags: ['Notifications'],
        }),
        viewNotifications: builder.mutation({
            query: (id) => ({
                url: `client/notifications/${id}/viewed`,
                method: 'POST',
            }),
            invalidatesTags: ['Notifications'],
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetClientNotificationsQuery,
    useGetClientNotificationQuery,
    useGetNewNotificationsQuery,
    useEvaluateNotificationMutation,
    useSendFeedbackOnNotificationMutation,
    useViewNotificationsMutation,
} = apiClientPartNotificationsSlice;
