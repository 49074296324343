import { apiSlice } from '../apiSlice';

const apiPersonalInformationSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getPersonalInformation: builder.query({
            query: () => ({
                url: 'client/settings/personal',
            }),
            providesTags: ['Personal Information'],
        }),
        changePersonalInformation: builder.mutation({
            query: (client) => ({
                url: `client/settings/personal`,
                method: 'POST',
                body: client,
            }),
            invalidatesTags: ['Personal Information'],
        }),
        verificationRequest: builder.mutation({
            query: ({ field, value }) => ({
                url: `client/verification/${field}`,
                method: 'POST',
                body: {
                    [field]: value,
                },
            }),
        }),
        verifyEmail: builder.mutation({
            query: (code) => ({
                url: `client/verification/email/check?code=${code}`,
                method: 'POST',
            }),
        }),
        checkRegistrationCode: builder.mutation({
            query: (code) => ({
                url: `client/registration/email?code=${code}`,
                method: 'POST',
            }),
        }),
        deleteAvatar: builder.mutation({
            query: () => ({
                url: `client/settings/personal/avatar`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Personal Information'],
        }),
    }),

    overrideExisting: false,
});

export const {
    useGetPersonalInformationQuery,
    useChangePersonalInformationMutation,
    useVerificationRequestMutation,
    useVerifyEmailMutation,
    useCheckRegistrationCodeMutation,
    useDeleteAvatarMutation,
} = apiPersonalInformationSlice;
