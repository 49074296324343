import React, { useCallback, useEffect, useState, useContext } from 'react';

import { Box, Typography } from '@mui/material';

import { Message } from '../Message/Message';

import {
  useSaveMessageMutation,
  useUnsaveMessageMutation,
} from '../../api/clientPartApi/apiDialoguesSlice';

import { useDeleteMessageMutation } from '../../api/clientPartApi/apiCommonDialogs';

import { socket } from '../../pages/Page';

import { sortByDate, checkIsTheSameData } from '../../helpers/functions';

import { ReactComponent as AnimatedDotsIcon } from '../../assets/icons/animatedDots.svg';
import { ReactComponent as СonfirmedIcon } from '../../assets/icons/confirmed.svg';

import { PageContext } from '../../pages/Page';

const chatBodyStyles = {
  pl: 0,
  pr: 0,
  height: '50%',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
};

const chatDialogStyles = {
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0 100px',
  flex: 1,
  pr: 1.5,
  pl: 1.5,
  pt: 1.5,
  cursor: 'default',
};

const timezones = [
  {
    language: 'uk',
    zone: 'uk-UA',
  },
  {
    language: 'en',
    zone: 'en-US',
  },
  {
    language: 'ru',
    zone: 'ru-RU',
  },
];

export const ChatBody = ({
  messages,
  role,
  type,
  chatId,
  isChatActive,
  setPage,
  isResolved,
  page,
  total,
  editMessage,
  setIsEditing,
}) => {
  const { dictionary, language } = useContext(PageContext);

  const [isCompanionWriting, setIsCompanionWriting] = useState(false);
  const [deleteMessage] = useDeleteMessageMutation();
  const [saveMessage] = useSaveMessageMutation();
  const [unsaveMessage] = useUnsaveMessageMutation();
  const [timezone, setTimezone] = useState([]);

  useEffect(() => {
    const printingSocketHandler = (data) => {
      if (Number(data.id) !== Number(chatId)) return;
      setIsCompanionWriting(data.status === 'start');
    };
    socket.on(
      `${type === 'support' ? 'support' : 'dialog'}.printing.get`,
      printingSocketHandler
    );
    return () => {
      socket.off(
        `${type === 'support' ? 'support' : 'dialog'}.printing.get`,
        printingSocketHandler
      );
    };
  }, []);

  useEffect(() => {
    const scrollElement = document.querySelector('.chat-body__content');
    if (!scrollElement || +page > 1) return;
    const scrollHeight = scrollElement.scrollHeight;
    scrollElement.scrollTo(0, scrollHeight);
  }, [messages?.income?.length, messages?.outcome?.length, page]);

  useEffect(() => {
    if (language) {
      timezones.forEach((zone) => {
        if (zone.language === language) {
          setTimezone(zone.zone);
        }
      });
    }
  }, [language]);

  const deleteMessageHandler = useCallback(
    (messageId) => {
      deleteMessage({
        id: chatId,
        messageId,
        role,
        type,
      });
    },
    [messages]
  );

  const saveMessageHandler = useCallback(
    (messageId) => {
      saveMessage({
        id: chatId,
        messageId,
      });
    },
    [messages]
  );

  const unsaveMessageHandler = useCallback(
    (messageId) => {
      unsaveMessage({
        id: chatId,
        messageId,
      });
    },
    [messages]
  );

  return (
    <Box className="chat-body" sx={chatBodyStyles}>
      <Box className="chat-body__content" sx={chatDialogStyles}>
        {!!total > 0 && page - 1 < total && role === 'client' && (
          <Box
            sx={{
              position: 'sticky',
              zIndex: 2,
              top: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '1px 4px',
              margin: '15px auto 10px',
              width: 'fit-content',
              borderBottom: '1px solid',
              cursor: 'pointer',
              fontSize: '12px',
              color: '#A6A6A6',
              // backgroundColor: '#fff',
              // pointerEvents: !isChatActive ? 'none' : 'auto',
            }}
            onClick={() => {
              // scroll to a new message
              const scrollElement = document.querySelector(
                '.chat-body__content'
              );
              if (!scrollElement) return;
              scrollElement.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
              setPage((prev) => prev + 1);
            }}
          >
            {dictionary?.show_history}
          </Box>
        )}
        {[...messages?.income, ...messages?.outcome]
          .sort(sortByDate)
          ?.map((message, index, arr) => {
            const isMine =
              role === 'client'
                ? messages?.income.find((el) => el?.id === message?.id)
                : messages?.outcome.find((el) => el?.id === message?.id);

            const isTheSameAuthor = (index) => {
              return arr[index + 1]?.user?.id === message?.user?.id;
            };

            const isSingle =
              arr[index + 1]?.user?.id === message?.user?.id &&
              checkIsTheSameData(message, index, arr, false);

            const time = new Date(message?.created_at).toLocaleDateString(
              timezone,
              {
                month: 'long',
                day: 'numeric',
              }
            );

            return (
              <React.Fragment key={message.id}>
                {checkIsTheSameData(message, index, arr) && (
                  <Typography
                    component={'div'}
                    key={message?.created_at}
                    sx={{
                      display: 'block',
                      width: 'fit-content',
                      margin: '8px auto 10px',
                      fontSize: '12px',
                      color: '#A6A6A6',
                    }}
                  >
                    {time}
                  </Typography>
                )}
                <Message
                  message={message}
                  disabled={isChatActive}
                  language={
                    timezones.find((zone) => zone.language === language)?.zone
                  }
                  setIsEditing={setIsEditing}
                  editMessage={editMessage}
                  saveMessage={saveMessageHandler}
                  unsaveMessage={unsaveMessageHandler}
                  deleteMessage={deleteMessageHandler}
                  isShort={type === 'support'}
                  isLast={arr.length - 1 === index}
                  isMine={isMine}
                  isSingle={isSingle}
                  isTheSameAuthor={isTheSameAuthor(index)}
                  type={type}
                  role={role}
                  className="message ql-editor"
                />
              </React.Fragment>
            );
          })}
        {isResolved && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px',
              backgroundColor: '#DAECF9',
              padding: '10px 0 11px',
              margin: 'auto 0 10px',
              borderRadius: '5px',
            }}
          >
            <Box component={СonfirmedIcon} />
            <Typography
              sx={{
                fontSize: '14px',
                color: 'secondaryColor.main',
              }}
            >
              {dictionary?.resolved}
            </Typography>
          </Box>
        )}
        {isChatActive && (
          <Typing text={dictionary?.prints} isTyping={isCompanionWriting} />
        )}
        <Box className="chat-body__spacer" sx={{ height: '0.01px' }} />
      </Box>
    </Box>
  );
};

const Typing = ({ text, isTyping }) => (
  <Typography
    sx={{
      display: 'flex',
      alignItems: 'center',
      mt: 'auto',
      mb: 1.2,
      height: '24px',
      flexShrink: 0,
      columnGap: '8px',
      color: 'primary.main',
      fontWeight: 'bold',
    }}
  >
    {isTyping && (
      <>
        {text} <AnimatedDotsIcon />
      </>
    )}
  </Typography>
);
