import { useContext, useLayoutEffect, useEffect, useState } from 'react';
import {
  useGetVisitorsQuery,
  useConnectToVisitorMutation,
} from '../../../api/clientPartApi/apiVisitosSlice';

import { TableBody, TableRow, TableCell, Typography, Box } from '@mui/material';

import Section from '../../../layouts/Section/Section';
import TblContainer from '../../../components/Table/TableContainer';
import TblHead from '../../../components/Table/TableHead';
import MyTooltip from '../../../components/ui/Tooltip/MyTooltip';
import MyToolbar from '../../../components/Toolbar/MyToolbar';
import MyLink from '../../../components/ui/Link/MyLink';
import MyButton from '../../../components/ui/Button/MyButton';
import EmptyListAlertText from '../../../components/ui/Alert/EmptyListAlertText';
import TextButton from '../../../components/ui/Button/TextButton';

import { ReactComponent as SendMessageIcon } from '../../../assets/icons/send-message.svg';
import { ReactComponent as SadSmileIcon } from '../../../assets/icons/sad-smile.svg';

import { setVisitorsOnlineTableHeadings as setHeadCells } from '../../../data/tableHeadingsData';

import { setStatusLabel, setCurrentTime } from '../../../helpers/functions';

import { PageContext } from '../../Page';
import { PageContainerContext } from '../../PageContainer';

const VisitorsOnline = () => {
  const {
    language,
    navigate,
    dictionary,
    visitorsOnlineRefetchingInterval,
    setVisitorsOnlineRefetchingInterval,
  } = useContext(PageContext);
  const {
    createSlug,
    content,
    skip,
    hash,
    changeContentHandler,
    queryParams,
    setSelectedDialogueWithRedirection,
  } = useContext(PageContainerContext);

  const [page, setPage] = useState(1);

  const visitorsAreFiltered =
    !!queryParams.get('q') ||
    !!queryParams.get('sites') ||
    !!queryParams.get('status');

  useLayoutEffect(() => {
    if (!hash && content !== 'visitorsOnline') {
      changeContentHandler('visitorsOnline', '', false, 'visitors');
    }
  }, [hash]);

  const {
    data: visitorsOnline,
    isFetching: visitorsOnlineFetching,
    isLoading: visitorsOnlineLoading,
    isSuccess: visitorsOnlineSuccess,
    isError: visitorsOnlineFetchingError,
    error: visitorsOnlineError,
    refetch: refetchVisitorsOnline,
  } = useGetVisitorsQuery(
    {
      online: '/online',
      limit: '?limit=100',
      page: `&page=${page}`,
      sortBy: createSlug('sort_by') || '&sort_by=online_at',
      sortOrder: createSlug('sort_order') || '&sort_order=asc',
      search: createSlug('q'),
      sites: createSlug('sites', 'array'),
      status: createSlug('status'),
    },
    { skip, refetchOnMountOrArgChange: true }
  );

  const [connectToVisitor] = useConnectToVisitorMutation();

  const visitorsOnlineList = visitorsOnline?.data?.visitors?.data || [];
  const numOfFilterdVisitors = visitorsOnline?.data?.visitors?.total || '';
  const allVisitors = visitorsOnline?.data?.visitors?.total_all || 0;

/*
  console.log('====================================');
  console.log(visitorsOnlineList);
  console.log('====================================');
*/

  const connectToVisitorHandler = (id, chatStatus) => {
    connectToVisitor(id)
      .unwrap()
      .then((res) => {
        setSelectedDialogueWithRedirection({
          isClicked: true,
          status: chatStatus === 'chatting' ? 'unresolved' : chatStatus,
          redirectedFromActiveOrWaitingDialoguesSection: false,
        });
        navigate(`/dialogues/active#dialogue_${res.id}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (visitorsOnline && !visitorsOnlineRefetchingInterval) {
      if (visitorsOnlineFetchingError) {
        return;
      }
      setVisitorsOnlineRefetchingInterval(
        setInterval(() => {
          refetchVisitorsOnline();
        }, 15000)
      );
    }
  }, [visitorsOnline, language, visitorsOnlineFetchingError]);

  return (
    <Box
      className="page__body"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}
    >
      <Section
        isLoading={visitorsOnlineLoading}
        isFetching={visitorsOnlineFetching}
        isError={visitorsOnlineFetchingError}
        // error={visitorsOnlineError}
        error={
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: 'auto',
                }}
            >
                <Typography
                    variant="h3"
                    sx={{
                        color: 'basicColor.color800',
                    }}
                >
                    {dictionary?.functional_disabled}
                    <Box
                        component={SadSmileIcon}
                        sx={{
                            verticalAlign: 'sub',
                            marginLeft: '0.5rem',
                        }}
                    />
                </Typography>
                <Typography
                    variant="h4"
                    sx={{
                        color: 'basicColor.color800',
                    }}
                >
                    {dictionary?.functional_online_disabled_text}
                </Typography>
            </Box>
        }
        title={dictionary?.visitors_online}
        sx={{
          pointerEvents: !visitorsOnlineList.length ? 'none' : 'all',
          display: 'flex',
          flexDirection: 'column',
          flexBasis: '100%',
          flexGrow: 1,
        }}
        content={content}
        shouldShowErrorText={
          visitorsOnlineFetchingError && !visitorsOnlineFetching
        }
      >
              <MyToolbar
                  sx={{display: visitorsOnlineFetchingError && 'none !important'}}
                  filter={true}
                  search={dictionary?.visitors_online_search_placeholder}
                  changeContentButton={false}
                  disabled={!visitorsOnlineList.length}
                  infobar={
                      <MyButton
                          title={`${dictionary?.online}: ${allVisitors}`}
                          sx={{ pointerEvents: 'none' }}
                          buttonClasses={'btn--small'}
                      />
                  }
              />
          <TblContainer
              tableClass={'table-main--big'}
              minHeight={
                  visitorsOnlineList.length === 0 && visitorsOnlineSuccess ? 322 : 0
              }
          >
              <TblHead setHeadCells={setHeadCells} />
              <TableBody>
                  {visitorsOnlineList &&
                      visitorsOnlineList.length === 0 &&
                      visitorsOnlineSuccess && <EmptyListAlertText />}
                  {visitorsOnlineList &&
                      visitorsOnlineList.map(
                          ({
                               id,
                               fullname,
                               site,
                               current_page,
                               online_time,
                               chat_status,
                               can_connect,
                           }) => {
                              return (
                                  <TableRow className="table-main__row" key={id}>
                                      <TableCell className="ellipsis">
                                          <MyTooltip
                                              position={'top-start'}
                                              title={id}
                                              arrow={false}
                                              interactive={false}
                                          >
                                              <Typography>{id}</Typography>
                                          </MyTooltip>
                                      </TableCell>
                                      <TableCell>{fullname}</TableCell>
                                      <TableCell>
                                          <MyLink
                                              link={`${site?.protocol}://${site?.domain}`}
                                              text={site?.domain}
                                          />
                                      </TableCell>
                                      <TableCell>
                                          <MyLink
                                              link={current_page?.href}
                                              text={current_page?.name}
                                          />
                                      </TableCell>
                                      <TableCell>{setCurrentTime(online_time)}</TableCell>
                                      <TableCell className="status">
                                          <Typography className={chat_status}>
                                              {setStatusLabel(chat_status, dictionary)}
                                          </Typography>
                                      </TableCell>
                                      <TableCell
                                          className="action"
                                          sx={{
                                              '& svg': {
                                                  opacity: can_connect ? 1 : 0.5,
                                                  pointerEvents: can_connect ? 'all' : 'none',
                                              },
                                          }}
                                      >
                                          <MyTooltip
                                              position={'top'}
                                              title={dictionary?.start_dialogue}
                                          >
                                              <SendMessageIcon
                                                  onClick={() =>
                                                      connectToVisitorHandler(id, chat_status)
                                                  }
                                              />
                                          </MyTooltip>
                                      </TableCell>
                                  </TableRow>
                              );
                          }
                      )}
              </TableBody>
          </TblContainer>
        {(visitorsAreFiltered ? numOfFilterdVisitors : allVisitors) >
          visitorsOnlineList.length && (
          <Box
            sx={{
              marginTop: 3.75,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <TextButton
              title={dictionary?.show_more_visitors}
              buttonHandler={() => setPage((page) => page + 1)}
            />
          </Box>
        )}
      </Section>
    </Box>
  );
};

export default VisitorsOnline;
