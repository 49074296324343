import { useContext } from 'react';
import { TableBody, TableRow, TableCell, Typography, Box } from '@mui/material';

import TblContainer from '../../../components/Table/TableContainer';
import TblHead from '../../../components/Table/TableHead';
import TblPagination from '../../../components/Table/TablePagination';
import MyToolbar from '../../../components/Toolbar/MyToolbar';
import MyTooltip from '../../../components/ui/Tooltip/MyTooltip';
import EmptyListAlertText from '../../../components/ui/Alert/EmptyListAlertText';

import { ReactComponent as IsAnsweredIcon } from '../../../assets/icons/is-answered.svg';
import { ReactComponent as DidNotAnsweredIcon } from '../../../assets/icons/did-not-answered.svg';
import { ReactComponent as ViewingIcon } from '../../../assets/icons/hide.svg';

import { dateConverter, setStatusLabel } from '../../../helpers/functions';

import {
    setAdminPartAppealsTableHeadings as setAdminHeadCells,
    setClientPartAppealsTableHeadings as setClientHeadCells,
} from '../../../data/tableHeadingsData';

import { PageContext } from '../../Page';

const AppealsList = ({
                         appealsList,
                         appealsSuccess,
                         changeContentHandler,
                         paginationData,
                     }) => {
    const { role, clientCheck, navigate, dictionary, language } = useContext(PageContext);

    const typographyStyles = { fontWeight: '800 !important' };

    const getTypographyStyles = (isAnswered, status) =>
        !isAnswered && status !== 'processed' && status !== 'rejected' && !clientCheck  ? typographyStyles : {};

    const handleViewAppeal = (id) => {
        changeContentHandler('viewAppeal', id, true, 'support');
        if (role === 'mng') {
            navigate(`/admin/support#appeal_${id}`);
        } else if (clientCheck) {
            navigate(`/support#appeal_${id}`);
        } else {
            navigate(`/admin/support/appeals#appeal_${id}`);
        }
    };

    const renderTableCell = (content, condition = true, additionalProps = {}) =>
        condition && <TableCell {...additionalProps}>{content}</TableCell>;

    return (
        <>
            <MyToolbar
                filter
                moveToLeft={!!clientCheck}
                changeContentButton={!!clientCheck}
                search={!clientCheck ? dictionary?.appeals_search_placeholder : null}
                buttonSx={{ position: 'absolute', left: 0, top: 0, zIndex: 1 }}
                buttonClass="btn--small"
                paginationData={paginationData}
                buttonHandler={() => {
                    changeContentHandler('createAppeal');
                    navigate('/support#create');
                }}
            />
            <TblContainer tableClass="table-main--big">
                <TblHead setHeadCells={clientCheck ? setClientHeadCells : setAdminHeadCells} />
                <TableBody>
                    {appealsList.length === 0 && appealsSuccess && <EmptyListAlertText />}
                    {appealsList.map((appeal) => {
                        const {
                            id,
                            is_answered: isAnswered,
                            subject,
                            status,
                            client,
                            created_at: createdAt,
                            last_message_at: lastMessageAt,
                            notification,
                        } = appeal;

                        return (
                            <TableRow className="table-main__row" key={id}>
                                {renderTableCell(
                                    <MyTooltip position="top" title={id}>
                                        <Typography sx={getTypographyStyles(isAnswered, status)}>{id}</Typography>
                                    </MyTooltip>,
                                    !clientCheck,
                                    { className: 'ellipsis' }
                                )}
                                {renderTableCell(
                                    <Box sx={{ display: 'inline-flex', gap: 1.25, alignItems: 'center' }}>
                                        <Box>{isAnswered ? <IsAnsweredIcon /> : <DidNotAnsweredIcon />}</Box>
                                        <Typography sx={getTypographyStyles(isAnswered, status)}>{subject}</Typography>
                                    </Box>,
                                    !clientCheck
                                )}
                                {renderTableCell(
                                    <Typography sx={getTypographyStyles(isAnswered, status)}>{subject}</Typography>,
                                    clientCheck
                                )}
                                {renderTableCell(
                                    <Typography sx={getTypographyStyles(isAnswered, status)}>
                                        {client?.fullname}
                                    </Typography>,
                                    !clientCheck
                                )}
                                {renderTableCell(
                                    <Typography
                                        sx={getTypographyStyles(isAnswered, status)}
                                        className={status}
                                    >
                                        {setStatusLabel(status, dictionary)}
                                    </Typography>,
                                    true,
                                    { className: 'status' }
                                )}
                                {renderTableCell(
                                    notification?.name?.[language] || '-',
                                    !clientCheck
                                )}
                                {renderTableCell(
                                    <>
                                        <Typography sx={getTypographyStyles(isAnswered, status)}>
                                            {dictionary?.created}: {dateConverter(createdAt)}
                                        </Typography>
                                        <Typography sx={getTypographyStyles(isAnswered, status)}>
                                            {dictionary?.last_message_abbr}: {dateConverter(lastMessageAt)}
                                        </Typography>
                                    </>
                                )}
                                <TableCell className="action">
                                    <MyTooltip position="top" title={dictionary?.viewing}>
                                        <ViewingIcon onClick={() => handleViewAppeal(id)} />
                                    </MyTooltip>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </TblContainer>
            <TblPagination data={paginationData} invalidateTag="Appeals" />
        </>
    );
};

export default AppealsList;
