import React, {
    createContext,
    useContext,
    useState
} from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import App from './App';
import store from './store';

import './assets/index.css';

export const RerenderContext = createContext();

export const RerenderProvider = ({ children }) => {
    const [rerender, setRerender] = useState(false);

    const triggerRerender = () => {
        setRerender((prev) => !prev);
    };

    return (
        <RerenderContext.Provider value={{ rerender, triggerRerender }}>
            {children}
        </RerenderContext.Provider>
    );
};

export const useRerender = () => useContext(RerenderContext);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <RerenderProvider>
            <App />
        </RerenderProvider>
    </Provider>
);