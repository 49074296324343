// Файл: notificationFavicon.js

const link = document.querySelector("link[rel*='icon']");
let notificationCounter = 0;

const updateFavicon = (notificationCount) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.height = 34;
    canvas.width = 34;

    const img = document.createElement('img');
    img.src = `${window.location.origin}/favicon.png`;

    img.addEventListener('load', () => {
        context.drawImage(img, 0, 0, canvas.width, canvas.height);

        if (notificationCount > 0) {
            context.fillStyle = 'red';
            context.beginPath();
            context.arc(canvas.width - 10, 24, 10, 0, 2 * Math.PI);
            context.fill();

            context.fillStyle = 'white';
            context.textAlign = 'center';
            context.font = 'bold 17px sans-serif';
            context.fillText(
                notificationCount > 99 ? '99+' : notificationCount.toString(),
                canvas.width - 10,
                31
            );
        }

        link.href = canvas.toDataURL('image/png');
    });
};

export const increaseDialogsNotificationCounter = () => {
    notificationCounter++;
    updateFavicon(notificationCounter);
};

export const resetDialogsNotificationCounter = () => {
    notificationCounter = 0;
    link.href = `${window.location.origin}/favicon.png`;
    updateFavicon(notificationCounter);
};
