import React, {useEffect, useContext, useState, forwardRef} from 'react';
import {Controller, useFormContext} from 'react-hook-form';

import {TextField, InputAdornment, Typography} from '@mui/material';

import {PageContext} from '../../../pages/Page';
import {TextEditor} from "../../TextEditor/TextEditor";
import {Box} from '@mui/material';

const GeneralInput = forwardRef(
    (
        {
            fieldName,
            label,
            receivedValue = '',
            type = 'text',
            multiline = false,
            units = '',
            sx = {},
            disabled = false,
            placeholder = '',
            getCurrentValue,
            setCurrentValue,
            required = true,
            maxNumOfCharacters = '',
            helperText = true,
            clearReceivedValue = false,
            ...rest
        },
        ref
    ) => {
        const {serverValidationError, setServerValidationError, screenSize} =
            useContext(PageContext);

        const {
            control,
            formState: {errors},
            clearErrors,
            getValues,
            setValue,
            watch,
        } = useFormContext();

        const [isAutoFill, setIsAutoFill] = useState(false);

        useEffect(() => {
            if (disabled || required) {
                clearErrors(fieldName);
            }
        }, [disabled, required]);

        useEffect(() => {
            if (getCurrentValue) {
                const subscription = watch((fieldName) => {
                    setCurrentValue(fieldName);
                });

                return () => subscription.unsubscribe();
            }
        }, [watch]);

        useEffect(() => {
            if (receivedValue) {
                setValue(fieldName, receivedValue);
            } else if (!receivedValue || clearReceivedValue) {
                setValue(fieldName, '');
            }
        }, [receivedValue, clearReceivedValue]);

        const removeError = () => {
            if (errors[fieldName]) {
                clearErrors(fieldName);
            }

            if (serverValidationError?.length) {
                setServerValidationError([]);
            }
        };

        return (
            <Box
            >
                <Controller
                    name={fieldName}
                    control={control}
                    defaultValue={''}
                    rules={
                        {
                            // required,
                        }
                    }
                    render={({field}) => (
                        fieldName.includes('non_work_greeting') || fieldName.includes('work_result') ? (
                            <Box sx={{
                                border: '1px solid #ccc !important',
                                display: 'flex',
                                position: 'relative',
                                '.ql-container': {
                                    border: 'none',
                                    borderRadius: '5px 5px 0px 0px'
                                },
                            }}>
                                <Box sx={{display: 'flex', position: 'absolute', color: '#9197a5', top: '-15px', left: '10px', backgroundColor: 'white', padding: '4px'}}>
                                    <Typography sx={{ fontSize: '12px', backgroundColor: 'white' }}>
                                        {label}
                                    </Typography>
                                </Box>
                                <TextEditor
                                    withToolbar={true}
                                    {...field}
                                    sx={{...sx}}
                                    specialSX={{
                                        display: 'flex',
                                        flexDirection: 'column-reverse'
                                    }}
                                    fieldName={fieldName}
                                    type={type}
                                    placeholder={placeholder}
                                    label={label}
                                    toolbarSx={{
                                        display: screenSize === 'xs' ? 'none !important' : undefined,
                                        '&.MuiBox-root': {
                                            width: '100%',
                                        },
                                        '&.ql-snow': {
                                            borderRadius: '0',
                                            border: '0px solid #ccc !important',
                                            borderTop: '1px solid #ccc !important',
                                        },
                                    }}
                                    editorSx={{
                                        '.ql-editor': {
                                            overflowY: 'auto',
                                            wordBreak: 'break-all',
                                        },
                                        '.ql-container.ql-snow': {
                                            border: 'none',
                                            minHeight: '0px',
                                            paddingRight: '92px',
                                            '.ql-container': {},
                                        },
                                        height: '100%',
                                        minHeight: '134px',
                                    }}
                                />
                            </Box>
                        ) : (
                            <TextField
                                {...field}
                                sx={{...sx}}
                                type={type}
                                label={label}
                                error={
                                    !!errors[fieldName] || !!serverValidationError?.includes(fieldName)
                                }
                                inputRef={ref}
                                helperText={
                                    errors[fieldName] &&
                                    required &&
                                    type === 'text' &&
                                    (!multiline || (multiline && maxNumOfCharacters)) &&
                                    helperText
                                        ? `${getValues(fieldName)?.length}/${maxNumOfCharacters || '100'}`
                                        : null
                                }
                                onFocus={removeError}
                                multiline={multiline}
                                disabled={disabled}
                                placeholder={placeholder}
                                InputProps={{
                                    endAdornment: units ? (
                                        <InputAdornment className="units" sx={{display: 'flex'}} position="end">
                                            {units}
                                        </InputAdornment>
                                    ) : null,
                                }}
                                inputProps={{
                                    onAnimationStart: (e) => {
                                        if (e.animationName === 'mui-auto-fill') setIsAutoFill(true);
                                    },
                                    onAnimationEnd: (e) => {
                                        if (e.animationName === 'mui-auto-fill-cancel') setIsAutoFill(false);
                                    },
                                }}
                                onKeyDown={(e) => {
                                    if (type === 'number' && ['e', 'E', '+', '-', '.', ','].includes(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                InputLabelProps={{
                                    shrink: isAutoFill || undefined,
                                }}
                                {...rest}
                            />
                        )
                    )}
                />
            </Box>

        );
    }
);

export default GeneralInput;
