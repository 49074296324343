import { Table, Box } from '@mui/material';

const TblContainer = ({
  children,
  tableClass,
  tableSx = {},
  minHeight = 322,
}) => {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        minHeight,
        ...tableSx,
      }}
    >
      <Table
        className={tableClass ? `table-main ${tableClass}` : 'table-main'}
        aria-label="table"
      >
        {children}
      </Table>
    </Box>
  );
};

export default TblContainer;
