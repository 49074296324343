import {
  useCallback,
  useState,
  useContext,
  useLayoutEffect,
  useRef,
} from 'react';
import {
  useChooseDefaultLanguageMutation,
  useCheckDomainMutation,
  useResetDomainMutation,
  useVerifyDomainMutation,
  useCreateSiteMutation,
  useEditSiteMutation,
  useCheckWidjetIntegrationMutation,
} from '../../../../api/clientPartApi/apiSitesSlice';
import { useCopyToClipboard } from '../../../../hooks/useCopyToClipboard';

import classNames from 'classnames';

import { Box } from '@mui/system';

import FormContainer from '../../../../components/Form/FormContainer';
import Title from '../../../../components/ui/Title/Title';
import MyButton from '../../../../components/ui/Button/MyButton';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';
import { FourthStep } from './FourthStep';
import { FifthStep } from './FifthStep';
import SixthStep from './SixthStep';
import SeventhStep from './SeventhStep';
import Congratulations from '../Congratulations';

import {
  createSiteFirstStepSchema as firstSchema,
  createSiteSecondStepSchema as secondSchema,
  createSiteThirdStepSchema as thirdSchema,
  createSiteFourthStepSchema as fourthSchema,
  createSiteFifthStepSchema as fifthSchema,
  createSiteSixthStepSchema as sixthSchema,
  emptySchema,
} from '../../../../settings/formSchema';

import {
  saveToLocalStorage,
  getFromLocalStorage,
  createScheduleObj,
} from '../../../../helpers/functions';

import { PageContext } from '../../../Page';
import { PageContainerContext } from '../../../PageContainer';

const FIRST_STEP = 1;
const SECOND_STEP = 2;
const THIRD_STEP = 3;
const FOURTH_STEP = 4;
const FIFTH_STEP = 5;
const SIXTH_STEP = 6;
const SEVENTH_STEP = 7;
const EIGHTH_STEP = 8;

const SitesForm = ({ content, site }) => {
  const { setAlertMessage, alertMessageContent, navigate, dictionary } =
    useContext(PageContext);
  const { hash, setSiteFormAlert } = useContext(PageContainerContext);

  const [copiedText, copy] = useCopyToClipboard();
  const savedSite = getFromLocalStorage('site');

  const [stepTitle, setStepTitle] = useState(dictionary?.site_step_1);
  const [schema, setSchema] = useState(firstSchema);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [defaultLanguage, setDefaultLanguage] = useState('');
  const [domain, setDomain] = useState('');
  const [logo, setLogo] = useState([]);
  const [verificationCode, setVerificationCode] = useState('');
  const [integrationCode, setIntegrationCode] = useState('');
  const [resetSiteDomain, setResetSiteDomain] = useState(false);
  const [blockSubmitButton, setBlockSubmitButton] = useState(false);
  const [activeLanguageButtons, setActiveLanguageButtons] = useState({
    uk: '',
    en: '',
    ru: '',
  });
  const [clickOnSubmitButton, setClickOnSubmitButton] = useState(false);

  const [step, setStep] = useState('');
  const clickedOut = useRef(false);

  useLayoutEffect(() => {
    // this is needed to check if we clicked the change domain button

    const clickHandler = (e) => {
      if (e.target.id === 'steps-submit-button') {
        clickedOut.current = true;
      } else {
        clickedOut.current = false;
      }
    };

    document.addEventListener('click', clickHandler);

    if (content === 'editSite' && site) {
      saveToLocalStorage('site', {
        ...site,
      });
    }

    return () => {
      document.removeEventListener('click', clickHandler);
    };
  }, []);

  useLayoutEffect(() => {
    // if we don`t clicked the change domain button and refreshed the page
    const siteVerificationCode = getFromLocalStorage('site')?.verification_code;
    const siteIntegrationCode = getFromLocalStorage('site')?.integration_code;
    const siteResetDomain = getFromLocalStorage('site')?.reset_domain || false;

    setVerificationCode(siteVerificationCode);
    setIntegrationCode(siteIntegrationCode);
    setResetSiteDomain(siteResetDomain);
  }, []);

  useLayoutEffect(() => {
    switch (hash) {
      case '#step-1':
        setStep(FIRST_STEP);
        setStepTitle(dictionary?.site_step_1);
        setSchema(firstSchema);
        break;
      case '#step-2':
        setStep(SECOND_STEP);
        setStepTitle(dictionary?.site_step_2);
        setSchema(secondSchema);
        break;
      case '#step-3':
        setStep(THIRD_STEP);
        setStepTitle(dictionary?.site_step_3);
        setSchema(thirdSchema);
        break;
      case '#step-4':
        setStep(FOURTH_STEP);
        setStepTitle(dictionary?.site_step_4);
        setSchema(fourthSchema);
        setClickOnSubmitButton(false);
        break;
      case '#step-5':
        setStep(FIFTH_STEP);
        setStepTitle(dictionary?.site_step_5);
        setSchema(fifthSchema);
        setClickOnSubmitButton(false);
        break;
      case '#step-6':
        setStep(SIXTH_STEP);
        setStepTitle(dictionary?.site_step_6);
        setSchema(sixthSchema);
        break;
      case '#step-7':
        setStep(SEVENTH_STEP);
        setStepTitle(dictionary?.site_step_7);
        setSchema(emptySchema);
        break;
      case '#step-8':
        setStep(EIGHTH_STEP);
        setStepTitle('');
        break;
    }
  }, [hash, dictionary]);

  const [
    chooseDefaultLanguage,
    {
      isLoading: choosedDefaultLanguageLoading,
      isFetching: choosedDefaultLanguageFetching,
    },
  ] = useChooseDefaultLanguageMutation();

  useLayoutEffect(() => {
    if (
      defaultLanguage &&
      selectedLanguages.length !== 0 &&
      selectedLanguages[0] !== defaultLanguage
    ) {
      let newDefaultLanguage = '';
      let newSelectedLanguagesArray = [];

      if (selectedLanguages.includes(defaultLanguage)) {
        newDefaultLanguage = defaultLanguage;
        newSelectedLanguagesArray = [
          defaultLanguage,
          ...selectedLanguages.filter((lang) => lang !== defaultLanguage),
        ];

        setSelectedLanguages(newSelectedLanguagesArray);
      } else {
        setDefaultLanguage(selectedLanguages[0]);
        newDefaultLanguage = selectedLanguages[0];
        newSelectedLanguagesArray = [...selectedLanguages];
      }

      if (savedSite?.id) {
        chooseDefaultLanguage({
          id: savedSite?.id,
          body: {
            default_language: newDefaultLanguage,
            languages: newSelectedLanguagesArray,
          },
        })
          .unwrap()
          .then((res) => {
            saveToLocalStorage('site', {
              ...savedSite,
              default_language: newDefaultLanguage,
              languages: newSelectedLanguagesArray,
            });

            alertMessageContent(
              dictionary?.language_has_been_changed,
              'success'
            );
          })
          .catch((error) => {
            alertMessageContent(dictionary?.something_go_wrong, 'error');
          });
      }
    } else if (!defaultLanguage && selectedLanguages.length !== 0) {
      setDefaultLanguage(selectedLanguages[0]);
    }
  }, [defaultLanguage, selectedLanguages]);

  const [
    checkDomain,
    {
      isLoading: checkedDomainLoading,
      isFetching: checkedDomainFetching,
      isSuccess: checkedDomainSuccess,
    },
  ] = useCheckDomainMutation();

  const [
    resetDomain,
    { isLoading: resetedDomainLoading, isFetching: resetedDomainFetching },
  ] = useResetDomainMutation();

  const [
    verifyDomain,
    { isLoading: verifiedDomainLoading, isFetching: verifiedDomainFetching },
  ] = useVerifyDomainMutation();

  const [
    checkWidjetIntegration,
    {
      isLoading: checkWidjetIntegrationLoading,
      isFetching: checkWidjetIntegrationFetching,
      isSuccess: checkWidjetIntegrationSuccess,
      isError: checkWidjetIntegrationFetchingError,
    },
  ] = useCheckWidjetIntegrationMutation();

  const [
    createSite,
    { isLoading: createdSiteLoading, isFetching: createdSiteFetching },
  ] = useCreateSiteMutation();

  const [
    editSite,
    { isLoading: editedSiteLoading, isFetching: editedSiteFetching },
  ] = useEditSiteMutation();

  let params = new URLSearchParams({
    domain: domain?.domain ? `https://${domain?.domain}` : savedSite?.domain,
  });
  let paramsToString = params.toString();

  const checkDomainHandler = () => {
    setAlertMessage({});

    const siteId = getFromLocalStorage('site')?.id;

    checkDomain({
      domain: paramsToString,
      id: content === 'editSite' ? `/&id=${siteId}` : '',
    })
      .unwrap()
      .then((res) => {
        setVerificationCode(res?.verification_code);
        alertMessageContent(dictionary?.domain_is_free, 'success');
      })
      .catch((error) => {
        if (
          error?.data?.errors?.domain[0] ===
          'The domain has already been taken.'
        ) {
          alertMessageContent(dictionary?.domain_exists, 'error');
        } else if (
          error?.data?.errors?.domain[0] === 'Is not a valid domain.'
        ) {
          alertMessageContent(dictionary?.domain_not_valid, 'error');
        } else {
          alertMessageContent(dictionary?.something_go_wrong, 'error');
        }
      });
  };

  const resetDomainHandler = () => {
    setAlertMessage({});

    setIntegrationCode('');
    setVerificationCode('');

    resetDomain(savedSite?.id)
      .unwrap()
      .then((res) => {
        alertMessageContent(dictionary?.domain_reset, 'success');
        saveToLocalStorage('site', {
          ...savedSite,
          verification_code: '',
          integration_code: '',
          reset_domain: true,
        });
        setResetSiteDomain(true);
        setIntegrationCode('');
        setVerificationCode('');
      })
      .catch((error) => {
        alertMessageContent(dictionary?.domain_not_reset, 'error');
      });
  };

  const confirmVerificationButtonHandler = () => {
    setAlertMessage({});

    verifyDomain({ domain: paramsToString, code: verificationCode })
      .unwrap()
      .then((res) => {
        setIntegrationCode(res?.integration_code);
        alertMessageContent(dictionary?.code_success, 'success');
      })
      .catch((error) => {
        alertMessageContent(dictionary?.code_invalide, 'error');
      });
  };

  const confirmIntegrationButtonHandler = () => {
    setAlertMessage({});

    checkWidjetIntegration({
      domain: paramsToString,
      code: integrationCode,
    })
      .unwrap()
      .then((res) => {
        alertMessageContent(dictionary?.integration_success, 'success');
      })
      .catch((error) => {
        alertMessageContent(dictionary?.integration_error, 'error');
      });
  };

  const leaveForm = () => {
    navigate('/channels');
    setSelectedLanguages([]);
  };

  const formSubmitHandler = useCallback(
    (data) => {
      const incrementStep = () => {
        navigate(`/channels/site-form#step-${step + 1}`);
      };

      const checkExistingLocalizations = () => {
        const selectedLanguages = getFromLocalStorage('site').languages;
        let existingLocalization;
        let localizationInStepFour;

        if (
          savedSite?.default_messages &&
          !Array.isArray(savedSite?.default_messages)
        ) {
          existingLocalization = Object.keys(
            savedSite?.default_messages?.non_work?.greeting
          );
          localizationInStepFour = selectedLanguages.every((lang) =>
            existingLocalization.includes(lang)
          );
        }

        const localizationInStepFive =
          savedSite?.visitor_messages &&
          (savedSite?.visitor_messages?.length === 0 ||
            savedSite?.visitor_messages.every((mes) => !mes.active));

        if (clickedOut.current) {
          return;
        }

        if (
          !localizationInStepFour &&
          !localizationInStepFive &&
          step < FOURTH_STEP
        ) {
          if (step === FIRST_STEP) {
            alertMessageContent(
              `${dictionary?.description_step_4} ${dictionary?.description_step_5}`,
              'error'
            );
            setSiteFormAlert(
              `${dictionary?.description_step_4}. ${dictionary?.description_step_5}.`
            );
          } else if (step > FIRST_STEP) {
            setSiteFormAlert(
              `${dictionary?.description_step_4}. ${dictionary?.description_step_5}.`
            );
          }
        } else if (!localizationInStepFour && step < FOURTH_STEP) {
          if (step === FIRST_STEP) {
            alertMessageContent(dictionary?.description_step_4, 'error');
          } else if (step > FIRST_STEP) {
            setSiteFormAlert(dictionary?.description_step_4);
          }
        } else if (!localizationInStepFive && step === FOURTH_STEP) {
          setSiteFormAlert(dictionary?.description_step_5);
        } else {
          setSiteFormAlert('');
        }
      };

      const passData = (data) => {
        if (content === 'createSite' && step === FIRST_STEP && !savedSite?.id) {
          createSite(data)
            .unwrap()
            .then((res) => {
              delete data.step;

              saveToLocalStorage('site', {
                ...data,
                id: res.id,
              });

              incrementStep();
            })
            .catch((error) => {
              console.log(error);
              alertMessageContent(dictionary?.next_step_failed, 'error');
            });
        } else {
          editSite({
            body: data,
            id: getFromLocalStorage('site').id,
          })
            .unwrap()
            .then((res) => {
              checkExistingLocalizations();

              if (
                step !== SEVENTH_STEP ||
                (content === 'createSite' && step === SEVENTH_STEP)
              ) {
                incrementStep();
              } else {
                leaveForm();
              }
            })
            .catch((error) => {
              console.log(error);
              alertMessageContent(dictionary?.next_step_failed, 'error');
            });
        }
      };

      switch (step) {
        case FIRST_STEP:
          const firstStepData = {
            domain: `https://${data?.domain}`,
            languages: selectedLanguages,
            verification_code: verificationCode,
            integration_code: integrationCode,
            default_language: defaultLanguage,
          };

          saveToLocalStorage('site', {
            ...savedSite,
            ...firstStepData,
          });

          passData({ ...firstStepData, step: 1 });
          break;
        case SECOND_STEP:
          const formData = new FormData();
          const secondStepData = {
            name: data?.name,
            font: data?.font,
            colors: {
              button: {
                background: data?.button_bg,
                icon: data?.button_icon,
              },
              window: {
                background: data?.window_bg,
                background_message: data?.windiw_bg_message,
                content: data?.window_content,
              },
              header_and_copyright: {
                background: data?.header_and_copyright_bg,
                content: data?.header_and_copyright_content,
              },
            },
            position: data?.position,
            margins: {
              bottom: data?.margin_first,
              [data?.position === 'bottom-right' ? 'right' : 'left']:
                data?.margin_second,
            },
            rounding: {
              button: data?.button_rounding,
              window: data?.window_rounding,
            },
            timeout: data?.timeout_switcher ? data?.timeout : 0,
          };

          setLogo(data?.logotype);

          // if we don't add a new file, when editing the site, we don't pass file
          if (Array.isArray(data?.logotype)) {
            if (data?.logotype[0]?.name) {
              formData.append('file', data.logotype[0]);
              formData.append('fileName', data.logotype[0].name);
            }
          }

          formData.append('step', 2);

          for (const [key1, value] of Object.entries(secondStepData)) {
            if (
              key1 === 'colors' ||
              key1 === 'margins' ||
              key1 === 'rounding'
            ) {
              formData.append(key1, JSON.stringify(value));
            } else {
              formData.append(key1, value);
            }
          }

          saveToLocalStorage('site', {
            ...savedSite,
            ...secondStepData,
          });

          passData(formData);
          break;
        case THIRD_STEP:
          const thirdStepData = {
            timezone: data?.timezone,
            schedule: createScheduleObj(data),
          };

          saveToLocalStorage('site', {
            ...savedSite,
            ...thirdStepData,
          });

          passData({ ...thirdStepData, step: 3 });
          break;
        case FOURTH_STEP:
          const fourthStepData = {
            default_messages: {
              work: {
                greeting: {
                  uk: data?.work_greeting_uk,
                  en: data?.work_greeting_en,
                  ru: data?.work_greeting_ru,
                },
                result: {
                  uk: data?.work_result_uk,
                  en: data?.work_result_en,
                  ru: data?.work_result_ru,
                },
              },
              non_work: {
                greeting: {
                  uk: data?.non_work_greeting_uk,
                  en: data?.non_work_greeting_en,
                  ru: data?.non_work_greeting_ru,
                },
              },
            },
          };

          saveToLocalStorage('site', {
            ...savedSite,
            ...fourthStepData,
          });

          passData({ ...fourthStepData, step: 4 });
          break;
        case FIFTH_STEP:
          const fifthStepData = {
            visitor_messages: [
              {
                active: data?.first_switcher,
                button: !data?.first_switcher
                  ? null
                  : {
                      uk: data?.first_button_text_uk,
                      en: data?.first_button_text_en,
                      ru: data?.first_button_text_ru,
                    },
                message: !data?.first_switcher
                  ? null
                  : {
                      uk: data?.first_user_message_uk,
                      en: data?.first_user_message_en,
                      ru: data?.first_user_message_ru,
                    },
              },
              {
                active: data?.second_switcher,
                button: !data?.second_switcher
                  ? null
                  : {
                      uk: data?.second_button_text_uk,
                      en: data?.second_button_text_en,
                      ru: data?.second_button_text_ru,
                    },
                message: !data?.second_switcher
                  ? null
                  : {
                      uk: data?.second_user_message_uk,
                      en: data?.second_user_message_en,
                      ru: data?.second_user_message_ru,
                    },
              },
              {
                active: data?.third_switcher,
                button: !data?.third_switcher
                  ? null
                  : {
                      uk: data?.third_button_text_uk,
                      en: data?.third_button_text_en,
                      ru: data?.third_button_text_ru,
                    },
                message: !data?.third_switcher
                  ? null
                  : {
                      uk: data?.third_user_message_uk,
                      en: data?.third_user_message_en,
                      ru: data?.third_user_message_ru,
                    },
              },
            ],
          };

          saveToLocalStorage('site', {
            ...savedSite,
            ...fifthStepData,
          });

          passData({ ...fifthStepData, step: 5 });
          break;
        case SIXTH_STEP:
          const sixthStepData = {
            visitor_data_request: [
              {
                field: 'surname',
                active: data?.surname_switcher,
              },
              {
                field: 'name',
                active: data?.name_switcher,
              },
              {
                field: 'patronymic',
                active: data?.patronymic_switcher,
              },
              {
                field: 'email',
                active: data?.email_switcher,
              },
              {
                field: 'phone',
                active: data?.phone_switcher,
              },
            ],
          };

          saveToLocalStorage('site', {
            ...savedSite,
            ...sixthStepData,
          });

          passData({ ...sixthStepData, step: 6 });
          break;
        case SEVENTH_STEP:
          passData({ step: 7 });
      }
    },
    [
      selectedLanguages,
      verificationCode,
      integrationCode,
      step,
      savedSite,
      logo,
      defaultLanguage,
    ]
  );

  const decrementStepHandler = () => {
    setAlertMessage({});

    if (step > FIRST_STEP) {
      navigate(`/channels/site-form#step-${step - 1}`);
    } else {
      leaveForm();
    }
  };

  const buttonClass = classNames({
    'btn--disabled':
      (step === FIRST_STEP && (!integrationCode || !verificationCode)) ||
      (step === FIRST_STEP &&
        !activeLanguageButtons.uk &&
        !activeLanguageButtons.en &&
        !activeLanguageButtons.ru) ||
      (step === SEVENTH_STEP && !checkWidjetIntegrationSuccess) ||
      (step === THIRD_STEP && blockSubmitButton),
    'btn--none': step === EIGHTH_STEP,
  });

  return (
    <>
      <FormContainer
        schema={schema}
        submitButtonTitle={
          step === SEVENTH_STEP ? dictionary?.finish : dictionary?.next_step
        }
        submitButtonClass={`btn--primary btn--steps ${buttonClass}`}
        submitButtonHandler={() => {
          if (step === FOURTH_STEP || step === FIFTH_STEP) {
            setClickOnSubmitButton(true);
          }
        }}
        useButtonHandler={true}
        formSubmitHandler={formSubmitHandler}
        isLoading={
          choosedDefaultLanguageLoading ||
          checkedDomainLoading ||
          resetedDomainLoading ||
          verifiedDomainLoading ||
          createdSiteLoading ||
          editedSiteLoading ||
          checkWidjetIntegrationLoading
        }
        isFetching={
          choosedDefaultLanguageFetching ||
          checkedDomainFetching ||
          resetedDomainFetching ||
          verifiedDomainFetching ||
          createdSiteFetching ||
          editedSiteFetching ||
          checkWidjetIntegrationFetching
        }
        useValidationErrors={true}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: step !== EIGHTH_STEP ? 3.75 : 2.5,
            marginBottom: step !== EIGHTH_STEP ? 1.25 : 0,
            alignItems: step !== EIGHTH_STEP ? 'normal' : 'center',
            justifyContent: step !== EIGHTH_STEP ? 'normal' : 'center',
            minHeight: step !== EIGHTH_STEP ? 'initial' : 478,
          }}
        >
          {step !== EIGHTH_STEP && (
            <>
              <Title heading="h3" title={stepTitle} />
              {step === FIRST_STEP && (
                <FirstStep
                  selectedLanguages={selectedLanguages}
                  setSelectedLanguages={setSelectedLanguages}
                  defaultLanguage={defaultLanguage}
                  setDefaultLanguage={setDefaultLanguage}
                  checkDomainHandler={checkDomainHandler}
                  isSuccess={checkedDomainSuccess}
                  domain={domain}
                  setDomain={setDomain}
                  confirmButtonHandler={confirmVerificationButtonHandler}
                  activeLanguageButtons={activeLanguageButtons}
                  setActiveLanguageButtons={setActiveLanguageButtons}
                  savedSite={savedSite}
                  copiedText={copiedText}
                  copy={copy}
                  verificationCode={verificationCode}
                  site={site}
                  content={content}
                  resetDomainHandler={resetDomainHandler}
                  resetSiteDomain={resetSiteDomain}
                />
              )}
              {step === SECOND_STEP && (
                <SecondStep
                  savedSite={savedSite}
                  savedLogo={site.logotype}
                  logo={logo}
                  setLogo={setLogo}
                />
              )}
              {step === THIRD_STEP && (
                <ThirdStep
                  savedSite={savedSite}
                  setBlockSubmitButton={setBlockSubmitButton}
                />
              )}
              {step === FOURTH_STEP && (
                <FourthStep
                  savedSite={savedSite}
                  clickOnSubmitButton={clickOnSubmitButton}
                  setClickOnSubmitButton={setClickOnSubmitButton}
                />
              )}
              {step === FIFTH_STEP && (
                <FifthStep
                  savedSite={savedSite}
                  clickOnSubmitButton={clickOnSubmitButton}
                  setClickOnSubmitButton={setClickOnSubmitButton}
                />
              )}
              {step === SIXTH_STEP && <SixthStep savedSite={savedSite} />}
              {step === SEVENTH_STEP && (
                <SeventhStep
                  copy={copy}
                  copiedText={copiedText}
                  isSuccess={checkWidjetIntegrationSuccess}
                  verificationCode={
                    verificationCode || savedSite?.verification_code
                  }
                  integrationCode={integrationCode}
                  confirmButtonHandler={confirmIntegrationButtonHandler}
                />
              )}
              <MyButton
                title={
                  step === FIRST_STEP
                    ? dictionary?.cancel_form
                    : dictionary?.back
                }
                buttonClasses={'btn--primary btn--cancel'}
                buttonHandler={() => decrementStepHandler()}
                sx={{ position: 'absolute', bottom: 0, left: 0 }}
              />
            </>
          )}
          {step === EIGHTH_STEP && content === 'createSite' && (
            <Congratulations leaveForm={leaveForm} />
          )}
        </Box>
      </FormContainer>
    </>
  );
};

export default SitesForm;
