import {
    useState,
    useEffect,
    useLayoutEffect,
    createContext,
    lazy,
    Suspense,
} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import io from 'socket.io-client';
import {apiSlice} from '../api/apiSlice';
import {useCheckClientResetCodeMutation} from '../api/clientPartApi/apiResetPasswordSlice';
import {
    useGetDictionaryQuery,
    useGetInitialDictionaryQuery,
    useChangeLanguageMutation,
} from '../api/apiLanguageSlice';
import {
    useVerifyEmailMutation,
    useCheckRegistrationCodeMutation,
} from '../api/clientPartApi/apiPersonalInformationSlice';
import {useGetPersonalInformationQuery} from '../api/clientPartApi/apiPersonalInformationSlice';

import {
    setToken,
    setLogin,
    setRole,
    setClientId,
    setIndexPath,
    setUserOnlineSection,
    setStatus,
    setTakeDialogues,
    setWorkTime,
    setLanguage,
    setSounds,
    setSoundStatus,
} from '../reducers/authorizationSlice';

import {setContent} from '../reducers/sectionSlice';

import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';

import {Box, ThemeProvider, useMediaQuery} from '@mui/material';
import {styled} from '@mui/material/styles';

import MainLayout from '../layouts/MainLayout/MainLayout';
import HomePage from './HomePage';
import AdminPartAuthForm from '../components/Form/AdminPartAuthForm';
import ClientPartAuthRegForm from '../components/Form/ClientPartForm/ClientPartAuthRegForm';
import NotFound from './NotFound';
import Spinner from '../components/ui/Spinner/Spinner';
import MyAlert from '../components/ui/Alert/MyAlert';

import {saveToLocalStorage, getFromLocalStorage} from '../helpers/functions';

import {users} from '../routes/usersData';
import {theme} from '../mui/theme';
import {theme2} from '../mui/theme2';
import {createMuiComponent} from '../mui/functions';

export const PageContext = createContext(null);

const PageContainer = lazy(() => import('./PageContainer'));

export let socket;

const MyPage = styled(Box)({
    minHeight: '100vh',
});

const MyPageAuthorization = createMuiComponent('div', 'MuiPageAuthorization');

export const ADMIN_PART_ROLES = ['sadmin', 'admin', 'mng'];
export const CLIENT_PART_ROLES = ['adm', 'sv', 'op'];

const Page = () => {
    const [expandedAccordionOfNav, setExpandedAccordionOfNav] = useState('');
    const [serverValidationError, setServerValidationError] = useState([]);
    const [inDialogue, setInDialogue] = useState(false);
    const [alertMessage, setAlertMessage] = useState({});
    const [resetCode, setResetCode] = useState('');

    const [active, setActive] = useState(false);

    const [showNotifications, setShowNotifications] = useState(false);
    const [userStatusCounter, setUserStatusCounter] = useState('');
    const [skipNotifications, setSkipNotifications] = useState(true);

    const navigate = useNavigate();
    const {pathname} = useLocation();

    const dispatch = useDispatch();
    const token = useSelector((state) => state.authorizationSlice?.token);
    const login = useSelector((state) => state.authorizationSlice?.login);
    const role = useSelector((state) => state.authorizationSlice?.role);
    const clientId = useSelector((state) => state.authorizationSlice?.clientId);
    const indexPath = useSelector((state) => state.authorizationSlice?.indexPath);
    const userOnlineSection = useSelector(
        (state) => state.authorizationSlice?.userOnlineSection
    );
    const status = useSelector((state) => state.authorizationSlice?.status);
    const takeDialogues = useSelector(
        (state) => state.authorizationSlice?.takeDialogues
    );
    const workTime = useSelector((state) => state.authorizationSlice?.workTime);
    const soundStatus = useSelector(
        (state) => state.authorizationSlice?.soundStatus
    );
    const sounds = useSelector((state) => state.authorizationSlice?.sounds);
    const language = useSelector((state) => state.authorizationSlice?.language);
    const content = useSelector((state) => state.sectionSlice.content);
    const [
        visitorsOnlineRefetchingInterval,
        setVisitorsOnlineRefetchingInterval,
    ] = useState('');


    const isLargeScreen = useMediaQuery('(min-width:1600px)');
    const isMediumScreen = useMediaQuery('(min-width:1000px) and (max-width:1599px)');
    const isSmallScreen = useMediaQuery('(min-width:550px) and (max-width:999px)');

    const screenSize = role === 'sadmin' || isLargeScreen
        ? 'lg'
        : isMediumScreen
            ? 'md'
            : isSmallScreen
                ? 'sm'
                : 'xs';


    const getUser = () => {
        if (pathname.startsWith('/admin')) {
            return getFromLocalStorage('admin');
        } else {
            return getFromLocalStorage('client');
        }
    };

    const [
        checkResetCode,
        {isSuccess: resetCodeSuccess, isError: resetCodeFetchingError},
    ] = useCheckClientResetCodeMutation();

    const [
        checkVerificationCode,
        {isError: verificationCodeFetchingError, error: verificationCodeError},
    ] = useVerifyEmailMutation();

    const [checkRegistrationCode] = useCheckRegistrationCodeMutation();

    const [
        changeLanguage,
        {isLoading: changedLanguageLoading, isFetching: changedLanguageFatching},
    ] = useChangeLanguageMutation();

    const {
        data: mainDictionary,
        isLoading: mainDictionaryLoading,
        isFetching: mainDictionaryFetching,
        isSuccess: mainDictionarySuccess,
    } = useGetDictionaryQuery(
        {
            path: pathname.startsWith('/admin') ? 'admin' : 'client',
            language: language ? `/${language}` : '',
        },
        {skip: getUser()?.role && language ? false : true}
    );

    const {data: authDictionary, isSuccess: initialDictionarySuccess} =
        useGetInitialDictionaryQuery(
            {
                language: language ? `/${language}` : '',
            },
            {skip: getUser()?.role || !language ? true : false}
        );

    const initialDictionary = authDictionary?.data[language] || '';
    const dictionary = mainDictionary?.data[language] || '';

    const {data: information} = useGetPersonalInformationQuery(undefined, {
        skip: pathname.startsWith('/admin') || !role,
    });

    useEffect(() => {
        if (information && login === information?.data?.email) {
            dispatch(
                setLogin(`${information?.data?.name} ${information?.data?.surname}`)
            );
        }
        if (information) {
            localStorage.setItem('size_percent', information?.data?.size_percent);
        }
    }, [information]);

    const checkCode = (path, fnc, type, role) => {
        const code = pathname.replace(path, '');

        if (type === 'reset') {
            setResetCode(code);
        }

        fnc(code)
            .unwrap()
            .then((res) => {
                if (type === 'verification' && role) {
                    navigate('/settings');
                } else {
                    navigate('/');
                }
                alertMessageContent(initialDictionary?.email_confirmed, 'success');
            })
            .catch((error) => {
                if (type === 'verification') {
                    alertMessageContent(initialDictionary?.email_not_confirmed, 'error');
                } else {
                    alertMessageContent(initialDictionary?.link_not_relevant, 'error');
                }
                navigate('/');
                window.location.reload();
            });
    };

    useLayoutEffect(() => {
        if (token) {
            socket = io(process.env.REACT_APP_SOCKET_URL, {
                query: {
                    token,
                },
            });
        }
        return () => {
            socket?.disconnect();
        };
    }, [token]);

    useLayoutEffect(() => {
        // multi-tab logout
        const checkLocalStorage = (event) => {
            const checkToken = (event) => {
                const localStorageValue = JSON.parse(event.newValue);
                const token = localStorageValue?.token;

                if (!token) {
                    clearUserData();
                }
            };

            if (event.key === 'client' && !pathname.startsWith('/admin')) {
                checkToken(event);
            } else if (event.key === 'admin' && pathname.startsWith('/admin')) {
                checkToken(event);
            }
        };

        window.addEventListener('storage', checkLocalStorage);
        return () => window.addEventListener('storage', checkLocalStorage);
    });

    useLayoutEffect(() => {
        const user = getUser();

        dispatch(setToken(user?.token));
        dispatch(setLogin(user?.login));
        dispatch(setRole(user?.role));
        dispatch(setClientId(user?.clientId));
        dispatch(setIndexPath(user?.indexPath));
        dispatch(setUserOnlineSection(user?.userOnlineSection));
        dispatch(setStatus(user?.status));
        dispatch(setTakeDialogues(user?.takeDialogues));
        dispatch(setWorkTime(user?.workTime));
        dispatch(setLanguage(user?.language));
        dispatch(setSounds(user?.sounds));
        dispatch(setSoundStatus(user?.soundStatus));
        document.documentElement.lang = user?.language;

        if (user?.role) {
            if (
                ADMIN_PART_ROLES.includes(user?.role) &&
                pathname.startsWith('/admin/support') &&
                pathname.endsWith('support') &&
                user?.role !== 'mng'
            ) {
                navigate('/admin/support/categories');
                setExpandedAccordionOfNav('support/categories');
            } else if (
                CLIENT_PART_ROLES.includes(user?.role) &&
                pathname.startsWith('/visitors') &&
                pathname.endsWith('visitors') &&
                userOnlineSection
            ) {
                navigate('/visitors/online');
                setExpandedAccordionOfNav('visitors/online');
            } else if (
                CLIENT_PART_ROLES.includes(user?.role) &&
                pathname.startsWith('/dialogues') &&
                pathname.endsWith('dialogues')
            ) {
                navigate('/dialogues/all');
                setExpandedAccordionOfNav('dialogues/all');
            } else if (
                CLIENT_PART_ROLES.includes(user?.role) &&
                pathname.startsWith('/templates') &&
                pathname.endsWith('templates')
            ) {
                navigate('/templates/commands');
                setExpandedAccordionOfNav('templates/commands');
            } else if (pathname.startsWith('/client/verification')) {
                checkCode(
                    '/client/verification/',
                    checkVerificationCode,
                    'verification',
                    user?.role
                );
            }
        } else if (!user?.role) {
            if (pathname.startsWith('/client/verification')) {
                checkCode(
                    '/client/verification/',
                    checkVerificationCode,
                    'verification'
                );
            } else if (pathname.startsWith('/client/reset')) {
                checkCode('/client/reset/', checkResetCode, 'reset');
            } else if (pathname.startsWith('/client/registration')) {
                checkCode(
                    '/client/registration/',
                    checkRegistrationCode,
                    'registration'
                );
            } else if (!pathname.includes('admin')) {
                navigate('/');
            } else {
                navigate('/admin');
            }
        }

        if (!user?.language) {
            const browserLanguage = navigator.language;

            const setPageLanguage = (lang) => {
                dispatch(setLanguage(lang));
                document.documentElement.lang = lang;
            };

            if (browserLanguage) {
                // for Firefox
                if (browserLanguage.includes('uk')) {
                    setPageLanguage('uk');
                } else if (browserLanguage.includes('en')) {
                    setPageLanguage('en');
                } else if (browserLanguage.includes('ru')) {
                    setPageLanguage('ru');
                }
            } else {
                setPageLanguage('uk');
            }
        }
    }, []);

    useEffect(() => {
        const redirectToAuthorizationPage = () => {
            const user = getUser();

            if (!user?.role) {
                if (!pathname.includes('admin')) {
                    navigate('/');
                } else {
                    navigate('/admin');
                }
            }
        };

        window.addEventListener('popstate', redirectToAuthorizationPage);

        return () =>
            window.addEventListener('popstate', redirectToAuthorizationPage);
    }, []);

    useEffect(() => {
        if (ADMIN_PART_ROLES.includes(role)) {
            saveToLocalStorage('admin', {
                token,
                login,
                role,
                indexPath,
                language,
            });
        } else if (CLIENT_PART_ROLES.includes(role)) {
            saveToLocalStorage('client', {
                token,
                login,
                role,
                clientId,
                indexPath,
                userOnlineSection,
                status,
                takeDialogues,
                workTime,
                language,
                sounds,
                soundStatus,
            });
        }
    }, [
        login,
        role,
        clientId,
        indexPath,
        userOnlineSection,
        status,
        takeDialogues,
        workTime,
        token,
        language,
        sounds,
        soundStatus,
    ]);

    useEffect(() => {
        if (
            visitorsOnlineRefetchingInterval &&
            (pathname !== '/visitors/online' ||
                changedLanguageLoading ||
                changedLanguageFatching)
        ) {
            setVisitorsOnlineRefetchingInterval(
                clearInterval(visitorsOnlineRefetchingInterval)
            );
        }
    }, [
        pathname,
        visitorsOnlineRefetchingInterval,
        changedLanguageLoading,
        changedLanguageFatching,
    ]);

    const clientCheck = CLIENT_PART_ROLES.includes(role) ? true : false;

    const renderRoutes = (path, Component) => (
        <Route key={path} path={path} element={Component}/>
    );

    const renderRouteList = () => {
        if (role) {
            let currentUser = users.find((user) => role === user.role);
            let newArr = [];

            if (clientCheck) {
                newArr = currentUser.routeList.filter(
                    ({visitorsOnline}) =>
                        visitorsOnline === userOnlineSection || visitorsOnline === undefined
                );
            }

            const visibleComponents = (
                newArr?.length !== 0 ? newArr : currentUser.routeList
            ).map(({path, Component, visitorsOnline}) => {
                return renderRoutes(path, Component, visitorsOnline);
            });

            const hiddenComponents = (
                newArr?.length !== 0 ? newArr : currentUser.routeList
            ).map(({Component, hiddenRoutes, visitorsOnline}) => {
                if (Component && hiddenRoutes) {
                    return hiddenRoutes.map(({path, Component}) => {
                        return renderRoutes(path, Component, visitorsOnline);
                    });
                }
            });

            const filteredHiddenComponents = hiddenComponents.filter((item) => item);

            return [...visibleComponents, ...filteredHiddenComponents];
        }
    };

    const renderInitialForm = () => {
        if (pathname === '/admin') {
            return <AdminPartAuthForm dictionary={initialDictionary}/>;
        } else if (pathname === '/') {
            return <ClientPartAuthRegForm dictionary={initialDictionary}/>;
        }
    };

    const routeList = renderRouteList();
    const initialForm = renderInitialForm();

    const alertMessageContent = (message, type) => {
        setAlertMessage({
            message,
            type,
        });
    };

    const clearUserData = () => {
        dispatch(apiSlice.util.resetApiState());

        dispatch(setToken(''));
        dispatch(setLogin(''));
        dispatch(setRole(''));
        dispatch(setClientId(''));
        dispatch(setIndexPath(''));
        dispatch(setTakeDialogues(''));
        dispatch(setContent(''));
        dispatch(setSounds(''));
        dispatch(setSoundStatus(''));
        setExpandedAccordionOfNav('');
        setAlertMessage({});

        navigate(`/${indexPath}`);
    };

    return (
        <PageContext.Provider
            value={{
                role,
                login,
                clientId,
                indexPath,
                clientCheck,
                navigate,
                pathname,
                expandedAccordionOfNav,
                setExpandedAccordionOfNav,
                resetCode,
                resetCodeSuccess,
                resetCodeFetchingError,
                verificationCodeFetchingError,
                verificationCodeError,
                userOnlineSection,
                setAlertMessage,
                alertMessageContent,
                serverValidationError,
                setServerValidationError,
                takeDialogues,
                status,
                screenSize,
                active,
                setActive,
                workTime,
                inDialogue,
                setInDialogue,
                content,
                clearUserData,
                initialDictionary,
                dictionary,
                dispatch,
                language,
                changeLanguage,
                soundStatus,
                sounds,
                visitorsOnlineRefetchingInterval,
                setVisitorsOnlineRefetchingInterval,
                userStatusCounter,
                setUserStatusCounter,
                skipNotifications,
                setSkipNotifications,
                showNotifications,
                setShowNotifications
            }}
        >
            <ThemeProvider theme={role === 'sadmin' ? theme2 : theme}>
                <MyPage>
                    {!role && (
                        <MyPageAuthorization>
                            {/* {(initialDictionaryLoading || initialDictionaryFetching) && (
                                <Spinner lazy={true} />
                            )} */}
                            {!initialDictionarySuccess && <Spinner lazy={true}/>}
                            {initialDictionarySuccess && initialForm}
                        </MyPageAuthorization>
                    )}
                    {role && (
                        <>
                            {(mainDictionaryLoading || mainDictionaryFetching) &&
                                (changedLanguageLoading || changedLanguageFatching) && (
                                    <Spinner lazy={true}/>
                                )}
                            {mainDictionarySuccess && (
                                <Suspense fallback={<Spinner lazy={true}/>}>
                                    <PageContainer>
                                        <Routes>
                                            <Route path={`${indexPath}/`} element={<MainLayout/>}>
                                                <Route index element={<HomePage/>}/>
                                                {routeList}
                                                <Route path="*" element={<NotFound/>}/>
                                            </Route>
                                        </Routes>
                                    </PageContainer>
                                </Suspense>
                            )}
                        </>
                    )}
                    {alertMessage?.message && (
                        <MyAlert message={alertMessage.message} type={alertMessage.type}/>
                    )}
                </MyPage>
            </ThemeProvider>
        </PageContext.Provider>
    );
};

export default Page;
