import { useContext, useState, useEffect, createContext } from 'react';

import { useGetNewAppealsCountQuery } from '../../api/apiAppealsSlice';
import { useGetNewAppealsNotAnsweredQuery } from '../../api/apiAppealsSlice';
import { useGetDialoguesQuery } from '../../api/clientPartApi/apiDialoguesSlice';
// import { useGetMessagesQuery } from '../../api/clientPartApi/apiCommonDialogs';

import { socket } from '../../pages/Page';

import { PageContext } from '../../pages/Page';
import { PageContainerContext } from '../../pages/PageContainer';

import { getFromLocalStorage } from '../../helpers/functions';
import {useSocketListener} from "../../hooks/useSocketListener";

import { increaseDialogsNotificationCounter, resetDialogsNotificationCounter } from '../../helpers/dialogsNotification';
import { increaseNotificationCounter, resetNotificationCounter } from '../../helpers/faviconUtils';


export const MainLayoutInnerContext = createContext(null);


const MainLayoutInner = ({ children }) => {
  const {
    dialogueMessagesArray,
    setDialogueMessagesArray,
    messageId,
    setMessageId,
  } = useContext(PageContainerContext);
  const { clientId, clientCheck, soundStatus } = useContext(PageContext);

  const [dialogId, setDialogId] = useState('');
  const [listOfSoundsToPlay, setListOfSoundsToPlay] = useState([]);
  const [playSounds, setPlaySounds] = useState(false);
  const [prevPlayedSoundId, setPrevPlayedSoundId] = useState('');
  const [isSoundPlayed, setIsSoundPlayed] = useState(false);
  const [lastPlayedSoundIndex, setLastPlayedSoundIndex] = useState(null);


  const [isPageVisible, setIsPageVisible] = useState(document.visibilityState);


  useSocketListener('dialog.message.send', (data) => {
    if (data && isPageVisible === 'hidden') {
      increaseDialogsNotificationCounter();
    }
  });

  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'hidden') {
      setIsPageVisible('hidden')
    } else if (document.visibilityState === 'visible') {
      setIsPageVisible('visible')
      resetDialogsNotificationCounter();
    }
  });




  const {
    data: appealsCounter,
    isSuccess: appealsCounterSuccess,
    refetch: fetchAppeals,
  } = useGetNewAppealsCountQuery(undefined, { skip: clientCheck });
  const {
    data: appealsNotAnsweredCounter,
    isSuccess: appealsNotAnsweredCounterSuccess,
    refetch: fetchAppealsNotAnsweredCounter,
  } = useGetNewAppealsNotAnsweredQuery(undefined, { skip: clientCheck });
  const {
    data: activeDialoguesCounter,
    isSuccess: activeDialoguesSuccess,
    refetch: fetchActiveDialoues,
  } = useGetDialoguesQuery({ status: 'active' }, { skip: !clientCheck });
  const {
    data: waitingDialoguesCounter,
    isSuccess: waitingDialoguesSuccess,
    refetch: fetchWaitingDialoues,
  } = useGetDialoguesQuery({ status: 'waiting' }, { skip: !clientCheck });

  // const { refetch: fetchMessages, isError: messageFetchingError } =
  //   useGetMessagesQuery(
  //     {
  //       id: dialogId,
  //       role: 'client',
  //       type: 'dialogs',
  //     },
  //     {
  //       skip: !dialogId,
  //     }
  //   );




  useSocketListener('support.message.send', (data) => {
    if (!clientCheck && appealsNotAnsweredCounterSuccess) {
      if (data.can_update) {
        fetchAppealsNotAnsweredCounter()
      }
    }
  });

  const newAppeals = appealsCounter?.data?.count || 0;
  const newAppealsNot = appealsNotAnsweredCounter?.data?.count || 0;

  const activeDialogues =
    activeDialoguesCounter?.data?.dialogs?.data?.length || '';
  const newMessagesInActiveDialogues =
    activeDialoguesCounter?.data?.dialogs?.data?.some(
      ({ new_messages_count }) => new_messages_count
    );
  const waitingDialogues =
    waitingDialoguesCounter?.data?.dialogs?.data?.length || '';

  useEffect(() => {
    const appealCount = appealsNotAnsweredCounter?.data?.count || 0;

    if (appealCount > 0) {
      increaseNotificationCounter(appealCount);
    } else {
      resetNotificationCounter();
    }
  }, [appealsNotAnsweredCounter]);

  useEffect(() => {
    if (!playSounds) {
      window.addEventListener('click', () => setPlaySounds(true));
      window.addEventListener('scroll', () => setPlaySounds(true));

      return () => {
        window.removeEventListener('click', () => setPlaySounds(true));
        window.addEventListener('scroll', () => setPlaySounds(true));
      };
    }
  }, [playSounds]);

  const updateListOfSoundsToPlay = ({
    prevListOfSoundsToPlay,
    newSound,
    newSoundId,
    prevPlayedSoundId,
  }) => {
    if (
      prevListOfSoundsToPlay.length === 0 ||
      prevPlayedSoundId !== newSoundId
    ) {
      setListOfSoundsToPlay([
        ...prevListOfSoundsToPlay,
        { audio: new Audio(newSound) },
      ]);
      setPrevPlayedSoundId(newSoundId);
    }
  };

  useEffect(() => {
    if (
      listOfSoundsToPlay.length !== 0 &&
      lastPlayedSoundIndex !== listOfSoundsToPlay.length - 1 &&
      soundStatus &&
      !isSoundPlayed
    ) {
      setIsSoundPlayed(true);

      listOfSoundsToPlay.forEach((sound, index) => {
        if (
          lastPlayedSoundIndex === null ||
          lastPlayedSoundIndex === index - 1
        ) {
          sound.audio.play();

          sound.audio.addEventListener(
            'ended',
            (event) => {
              setLastPlayedSoundIndex(index);
              setPrevPlayedSoundId('');
              setIsSoundPlayed(false);
            },
            { once: true }
          );
        }
      });
    } else if (
      listOfSoundsToPlay.length !== 0 &&
      (!soundStatus || lastPlayedSoundIndex === listOfSoundsToPlay.length - 1)
    ) {
      setLastPlayedSoundIndex(null);
      setListOfSoundsToPlay([]);
    }
  }, [listOfSoundsToPlay, soundStatus, isSoundPlayed, lastPlayedSoundIndex]);

  useEffect(() => {
    const statusHandler = (data) => {
      if (data.status === 'new' && !clientCheck) {
        fetchAppeals();
      }

      if (
        data.status !== 'new' &&
        clientCheck &&
        soundStatus &&
        playSounds &&
        data.author === 'client'
      ) {
        const statusChangeSound =
          getFromLocalStorage('client').sounds.sound6.url;
        const statusChangeSoundId =
          getFromLocalStorage('client').sounds.sound6.id;

        if (statusChangeSound) {
          updateListOfSoundsToPlay({
            prevListOfSoundsToPlay: listOfSoundsToPlay,
            newSound: statusChangeSound,
            newSoundId: statusChangeSoundId,
            prevPlayedSoundId,
          });
        }
      }
    };

    const messageHandler = (data) => {
      // console.log(data.id);
      if (data.author === 'user' && clientCheck && soundStatus && playSounds) {
        const supportMessageSound =
          getFromLocalStorage('client').sounds.sound6.url;
        const supportMessageSoundId =
          getFromLocalStorage('client').sounds.sound6.id;

        if (supportMessageSound) {
          updateListOfSoundsToPlay({
            prevListOfSoundsToPlay: listOfSoundsToPlay,
            newSound: supportMessageSound,
            newSoundId: supportMessageSoundId,
            prevPlayedSoundId,
          });
        }
      }
    };

    socket.on('support.status', statusHandler);
    socket.on('support.message.send', messageHandler);

    return () => {
      socket.off('support.status', statusHandler);
      socket.off('support.message.send', messageHandler);
    };
  }, [listOfSoundsToPlay, soundStatus, playSounds, prevPlayedSoundId]);

  useEffect(() => {
    const newVisitorHandler = (data) => {
      /*console.log('new visitor', data);*/
      if (
        data.status === 'active' &&
        data.online === 'online' &&
        soundStatus &&
        playSounds
      ) {
        // console.log('new visitor sound', data);
        const newVisitorSound = getFromLocalStorage('client').sounds.sound4.url;
        const newVisitorSoundId =
          getFromLocalStorage('client').sounds.sound4.id;

        if (newVisitorSound) {
          updateListOfSoundsToPlay({
            prevListOfSoundsToPlay: listOfSoundsToPlay,
            newSound: newVisitorSound,
            newSoundId: newVisitorSoundId,
            prevPlayedSoundId,
          });
        }
      }
    };

    socket.on('visitor.status', newVisitorHandler);

    return () => {
      socket.off('visitor.status', newVisitorHandler);
    };
  }, [listOfSoundsToPlay, soundStatus, playSounds, prevPlayedSoundId]);

  useEffect(() => {
    const changeStatusHandler = (data) => {
      // console.log('status', data);
      if (data.status === 'resolved') {
        if (data.author === 'visitor' && soundStatus && playSounds) {
          const dialogueIsResolvedSound =
            getFromLocalStorage('client').sounds.sound5.url;
          const dialogueIsResolvedSoundId =
            getFromLocalStorage('client').sounds.sound5.id;

          if (dialogueIsResolvedSound) {
            updateListOfSoundsToPlay({
              prevListOfSoundsToPlay: listOfSoundsToPlay,
              newSound: dialogueIsResolvedSound,
              newSoundId: dialogueIsResolvedSoundId,
              prevPlayedSoundId,
            });
          }
        }

        setDialogueMessagesArray([
          ...dialogueMessagesArray.filter((item) => item.dialog.id !== data.id),
        ]);
      }

      setDialogId(data?.id);
      // if (dialogId) {
      //   fetchMessages();
      // }
      fetchWaitingDialoues();
      fetchActiveDialoues();
    };

    socket.on('dialog.status', changeStatusHandler);

    return () => {
      socket.off('dialog.status', changeStatusHandler);
    };
  }, [
    dialogId,
    dialogueMessagesArray,
    listOfSoundsToPlay,
    soundStatus,
    playSounds,
    prevPlayedSoundId,
  ]);

  useEffect(() => {
    const connectToDialogueHandler = (data) => {
      // console.log('connect', data.client);
      if (data.client !== clientId) {
        // console.log('connect sound', data);
        if (soundStatus && playSounds) {
          const connectToDialogueSound =
            getFromLocalStorage('client').sounds.sound2.url;
          const connectToDialogueSoundId =
            getFromLocalStorage('client').sounds.sound2.id;

          if (connectToDialogueSound) {
            updateListOfSoundsToPlay({
              prevListOfSoundsToPlay: listOfSoundsToPlay,
              newSound: connectToDialogueSound,
              newSoundId: connectToDialogueSoundId,
              prevPlayedSoundId,
            });
          }
        }

        setDialogueMessagesArray([
          ...dialogueMessagesArray.filter((item) => item.dialog.id !== data.id),
        ]);
      }

      // if (dialogId && dialogId === data.id) {
      //   fetchMessages();
      // }
      fetchActiveDialoues();
      fetchWaitingDialoues();
    };

    socket.on('dialog.connect', connectToDialogueHandler);

    return () => {
      socket.off('dialog.connect', connectToDialogueHandler);
    };
  }, [
    dialogId,
    dialogueMessagesArray,
    listOfSoundsToPlay,
    soundStatus,
    playSounds,
    prevPlayedSoundId,
    clientId,
  ]);

  useEffect(() => {
    const newMessageHandler = (data) => {
      if (messageId !== data.id && data.type === 'visitor') {
        // console.log('new message', data);
        setMessageId(data.id);

        fetchActiveDialoues();
        fetchWaitingDialoues();
        if (soundStatus && playSounds) {
          if (data.status === 'waiting') {
            // console.log('new message sound 1', data.status);
            const newWaitingMessageSound =
              getFromLocalStorage('client').sounds.sound1.url;
            const newWaitingMessageSoundId =
              getFromLocalStorage('client').sounds.sound1.id;
            updateListOfSoundsToPlay({
              prevListOfSoundsToPlay: listOfSoundsToPlay,
              newSound: newWaitingMessageSound,
              newSoundId: newWaitingMessageSoundId,
              prevPlayedSoundId,
            });
          } else {
            // console.log('new message sound 3', data.status);
            const newMessageSound =
              getFromLocalStorage('client').sounds.sound3.url;
            const newMessageSoundId =
              getFromLocalStorage('client').sounds.sound3.id;

            if (newMessageSound) {
              updateListOfSoundsToPlay({
                prevListOfSoundsToPlay: listOfSoundsToPlay,
                newSound: newMessageSound,
                newSoundId: newMessageSoundId,
                prevPlayedSoundId,
              });
            }
          }
        }
      }
    };

    socket.on('dialog.message.send', newMessageHandler);

    return () => {
      socket.off('dialog.message.send', newMessageHandler);
    };
  }, [
    messageId,
    soundStatus,
    playSounds,
    prevPlayedSoundId,
    listOfSoundsToPlay,
  ]);

  useEffect(() => {
    const updateDialoguesList = (data) => {
      fetchActiveDialoues();
    };

    socket.on('dialog.pass', updateDialoguesList);

    return () => {
      socket.off('dialog.pass', updateDialoguesList);
    };
  }, []);

  return (
    <MainLayoutInnerContext.Provider
      value={{
        newAppeals,
        newAppealsNot,
        activeDialogues,
        waitingDialogues,
        appealsCounter,
        activeDialoguesCounter,
        waitingDialoguesCounter,
        appealsCounterSuccess,
        activeDialoguesSuccess,
        waitingDialoguesSuccess,
        newMessagesInActiveDialogues,
      }}
    >
      <>{children}</>
    </MainLayoutInnerContext.Provider>
  );
};

export default MainLayoutInner;
