import { useContext } from 'react';

import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { PageContext } from '../../pages/Page';

const NavAccordion = ({
  children,
  path,
  linkName,
  expandedAccordionOfNav,
  setExpandedAccordionOfNav,
  setInitialProps,
  accordion,
}) => {
  const { indexPath, setAlertMessage, navigate } = useContext(PageContext);

  const accordionStyles = {
    '.MuiButtonBase-root': {
      borderRadius: 3,
      borderBottomLeftRadius: 12,
      borderBottomRightRadius: 12,

      transition: `
                min-height 50ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                background-color 50ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                border-bottom-left-radius 50ms 100ms,
                border-bottom-right-radius 50ms 100ms
                `,
      '&.Mui-expanded': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        backgroundColor: 'secondaryColor.main',
        color: 'basicColor.colorWhite',

        pointerEvents: 'none',
        transition: `min-height 50ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                    background-color 50ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                    border-bottom-left-radius 100ms,
                    border-bottom-right-radius 100ms`,
        '& .MuiSvgIcon-root': {
          color: 'basicColor.colorWhite',
        },
      },
    },
  };

  const handleChange = (accordion) => (event, isExpanded) => {
    setExpandedAccordionOfNav(isExpanded ? accordion : false);
  };

  return (
    <Accordion
      onChange={handleChange(accordion)}
      sx={{ ...accordionStyles }}
      expanded={expandedAccordionOfNav === accordion}
    >
      <AccordionSummary
        onClick={() => {
          navigate(indexPath ? `/${indexPath}/` + path : '/' + path);
          setInitialProps('');
          setAlertMessage({});
        }}
        expandIcon={<ExpandMoreIcon />}
        sx={{ padding: '13px 16px' }}
      >
        {linkName}
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default NavAccordion;
