import { useEffect, useContext, useState, forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { PhoneNumberUtil } from 'google-libphonenumber';

import { FormControl } from '@mui/material';

import { PageContext } from '../../../../pages/Page';
import PhoneSelect from './PhoneSelect';

import 'react-international-phone/style.css';
import './phoneSelect.scss';

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const SinglePhoneSelect = forwardRef(
  (
    {
      fieldName,
      dropdownWidth,
      receivedValue = '',
      phoneValidationError,
      setPhoneValidationError,
      isSubmited,
    },
    ref
  ) => {
    const { serverValidationError, setServerValidationError } =
      useContext(PageContext);

    const [phone, setPhone] = useState('');
    const [show, setShow] = useState(false);

    // const isValid = isPhoneValid(phone);

    const {
      control,
      formState: { errors },
      setValue,
      clearErrors,
    } = useFormContext();

    // useEffect(() => {
    //     if (isValid) {
    //         setPhoneValidationError(false);
    //     } else {
    //         setPhoneValidationError(true);
    //     }
    // }, [isValid]);

    useEffect(() => {
      if (receivedValue) {
        setPhone(receivedValue);
      }
    }, [receivedValue]);

    const removeError = () => {
      if (errors[fieldName]) {
        clearErrors(fieldName);
      }

      if (serverValidationError?.length) {
        setServerValidationError([]);
      }
    };

    return (
      <FormControl
        sx={{
          '& .react-international-phone-country-selector-dropdown': {
            width: dropdownWidth,
          },
          '& .react-international-phone-input': {
            borderColor:
              !!errors[fieldName] ||
              (phoneValidationError && isSubmited) ||
              !!serverValidationError?.includes(fieldName)
                ? 'colorAccent.second'
                : 'basicColor.color700',
          },
        }}
        onClick={(e) => {
          const elementValue = e.target?.value;
          if (elementValue === undefined) {
            setShow(!show);
          } else {
            setShow(false);
          }
        }}
      >
        <Controller
          name={fieldName}
          control={control}
          render={() => (
            <>
              <PhoneSelect
                fieldName={fieldName}
                ref={ref}
                receivedValue={phone}
                onChange={(phone, meta) => {
                  setPhone(phone);

                  if (isPhoneValid(phone)) {
                    setPhoneValidationError(false);
                  } else {
                    setPhoneValidationError(true);
                  }

                  setValue(`${fieldName}_data`, {
                    number: `${phone.replace(`+${meta.country.dialCode}`, '')}`,
                    country: {
                      iso2: `${meta.country.iso2.toUpperCase()}`,
                      name: meta.country.name,
                      dialCode: `+${meta.country.dialCode}`,
                    },
                  });

                  setValue(fieldName, phone);
                }}
                error={!!errors[fieldName]}
                removeError={removeError}
                show={show}
                setShow={setShow}
              />
            </>
          )}
        />
      </FormControl>
    );
  }
);

export default SinglePhoneSelect;
