import { useEffect } from 'react';

import { socket } from '../pages/Page';

export const useSocketListener = (eventName, handler) => {
  useEffect(() => {
    socket.on(eventName, handler);
    return () => {
      socket.off(eventName, handler);
    };
  }, [eventName, handler]);
};
