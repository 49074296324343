import {useContext, useCallback} from 'react';

import {Grid} from '@mui/material';

import FilterFormContainer from '../../../components/Filter/FilterFormContainer';
import SelectWithFormContext from '../../../components/Form/FormSelect/Select/SelectWithFormContext';
import MyDatePicker from '../../../components/DatePicker/DatePicker';
import RangeInput from '../../../components/Form/FormInputs/RangeInput';

import {setAdminPartNotificationsStatusSelect as setStatusOptions} from '../../../data/formSelectData';

import {setFilterParams} from '../../../helpers/functions';

import {PageContext} from '../../Page';
import {PageContainerContext} from '../../PageContainer';

const currentQueryParams = ['status', 'range_start', 'range_end', 'count'];

const NotificationsFilter = ({clearFilter}) => {
    const {dictionary} = useContext(PageContext);
    const {queryParams, setQueryParams} = useContext(PageContainerContext);

    const formSubmitHandler = useCallback(
        (data) => {
            let newCountValue;

            if (
                data.count.includes(':') ||
                data.count.includes('-') ||
                data.count.includes('/')
            ) {
                newCountValue = data.count.replace(/[\:\-\/]/, ',');
            } else {
                newCountValue = data.count.trim().replace(/\s+/g, ',');
            }

            setQueryParams(
                (params) => {
                    if (queryParams.get('page')) {
                        params.delete('page');
                    }

                    setFilterParams(data.status, 'status', params);
                    setFilterParams(data.date && data.date[0] ? data.date[0].format('YYYY/MM/DD HH:mm') : '', 'range_start', params);
                    setFilterParams(data.date && data.date[1] ? data.date[1].format('YYYY/MM/DD HH:mm') : data.date && data.date[0] ? data.date[0].format('YYYY/MM/DD HH:mm') : '', 'range_end', params);
                    setFilterParams(newCountValue, 'count', params);

                    return params;
                }
                // { replace: true }
            );
        },
        [queryParams]
    );

    return (
        <FilterFormContainer
            formSubmitHandler={formSubmitHandler}
            currentQueryParams={currentQueryParams}
        >
            <Grid item>
                <SelectWithFormContext
                    fieldName={'status'}
                    label={dictionary?.status}
                    labelId={'statusSelect'}
                    options={setStatusOptions(dictionary)}
                    emtyOption={true}
                    receivedValue={queryParams.get('status')}
                />
            </Grid>
            <Grid item>
                <MyDatePicker
                    fieldName={'date'}
                    clearFilter={clearFilter}
                    placeholder={dictionary?.date_time}
                    firstPlaceholder={dictionary?.dateTimeFrom}
                    secondPlaceholder={dictionary?.dateTimeTon}
                    receivedValue={
                        queryParams.get('range_start')
                            ? [queryParams.get('range_start'), queryParams.get('range_end')]
                            : ''
                    }
                    queryParams={queryParams}
                />
            </Grid>
            <Grid item>
                <RangeInput
                    fieldName={'count'}
                    label={dictionary?.num_of_views}
                    receivedValue={queryParams.get('count')}
                />
                {/* <GeneralInput
                    fieldName={'count'}
                    label={dictionary?.num_of_views}
                    receivedValue={queryParams.get('count')}
                    type={'text'}
                    value={countValue}
                    getCurrentValue={true}
                    // setCurrentValue={setCountValue}
                    onChange={(e) => {
                        const arrOfNums = e.target.value.split('');
                        let counter = 0;

                        for (let i = 0; arrOfNums.length > i; i++) {
                            if (dividers.includes(arrOfNums[i])) {
                                counter++;
                            }
                        }

                        if (
                            e.target.value === '' ||
                            (numbers.includes(e.target.value[0]) && counter <= 1)
                        ) {
                            setCountValue(e.target.value);
                        } else {
                            return;
                        }
                    }}
                    onKeyDown={(e) => {
                        // console.log(e.key);
                        // console.log(e.target.value);
                        // console.log(e);
                        // console.log(startsWithNumber);
                        // console.log(numbers.includes(e.target.value[0]));
                        if (
                            ![
                                ...numbers,
                                ',',
                                ':',
                                '/',
                                '-',
                                'Delete',
                                'Backspace',
                                'ArrowLeft',
                                'ArrowRight',
                                ' ',
                            ].includes(e.key)
                        ) {
                            e.preventDefault();
                        }
                    }}
                /> */}
            </Grid>
        </FilterFormContainer>
    );
};

export default NotificationsFilter;
