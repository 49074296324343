import React, {useContext} from 'react';
import {
    useChangeSiteStatusMutation,
    useDeleteSiteMutation,
} from '../../../api/clientPartApi/apiSitesSlice';

import {
    TableBody,
    TableRow,
    TableCell,
    Typography,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';

import TblContainer from '../../../components/Table/TableContainer';
import TblHead from '../../../components/Table/TableHead';
import TblPagination from '../../../components/Table/TablePagination';
import MyTooltip from '../../../components/ui/Tooltip/MyTooltip';
import MyPopper from '../../../components/ui/Popper/MyPopper';
import MyLink from '../../../components/ui/Link/MyLink';
import EmptyListAlertText from '../../../components/ui/Alert/EmptyListAlertText';

import {ReactComponent as TurnOffIcon} from '../../../assets/icons/turn-on.svg';
import {ReactComponent as TurnOnIcon} from '../../../assets/icons/turn-off.svg';
import {ReactComponent as TurnDisabledIcon} from '../../../assets/icons/turn-disabled.svg';
import {ReactComponent as CopyIcon} from '../../../assets/icons/copy.svg';
import {ReactComponent as EditIcon} from '../../../assets/icons/edit.svg';
import {ReactComponent as ErrorIcon} from '../../../assets/icons/error.svg';

import {
    setStatusLabel,
    timer,
    dictionary,
    widgetTemplate,
} from '../../../helpers/functions';

import {PageContext} from '../../Page';
import {PageContainerContext} from '../../PageContainer';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


const boxStyled = (({}) => ({
    display: 'flex',
    flexDirection: 'column',
}));


const boxIconStyled = (({}) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '24px',
}));



const typographyStyled = (({}) => ({
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    maxWidth: '280px',
}));



const ChannelsListTemplate = ({
                                  list,
                                  listContent,
                                  activeSites,
                                  maxActiveSites,
                                  setHeadCells,
                                  isSuccess,
                                  paginationData,
                                  copyTextHandler,
                                  content,
                                  path,
                              }) => {
    const {navigate, dictionary, screenSize} = useContext(PageContext);
    const {changeContentHandler} = useContext(PageContainerContext);

    const [changeSiteStatus] = useChangeSiteStatusMutation();
    const [deleteSite] = useDeleteSiteMutation();

    const changeStatusHandler = (id, action) => {
        changeSiteStatus({id, action}).unwrap();
    };

    const deleteChannelHandler = (id) => {
        deleteSite(id).unwrap();
    };



    const myTimer = (day) => {
        const currentTimer = timer(day);

        return currentTimer.map(({hours, minutes, seconds}, index) => {
            return (
                <Box key={index} sx={{marginTop: 1.25}}>
                    <Typography fontWeight={600}>
                        {dictionary?.time_to_delete}&nbsp;
                    </Typography>
                    <Typography>
                        {hours}:{minutes}:{seconds}
                    </Typography>
                </Box>
            );
        });
    };

    return (
        <>
            {screenSize === 'xs' && <Box>
                {list.length === 0 && isSuccess && (
                    <EmptyListAlertText sx={{minHeight: 59}}/>
                )}
                {list.length > 0 &&
                    list.map(
                        ({
                             id,
                             domain,
                             protocol,
                             status,
                             expiration,
                             hash,
                             error,
                             name,
                         }) => {
                            return  (
                                <Accordion
                                    key={id}
                                    sx={{
                                        position: 'inherit',
                                    }}
                                    className='accordion__item'
                                >
                                    <AccordionSummary
                                        sx={{
                                            minHeight: '50px',
                                            fontSize: 'var(--fz-400)',
                                            padding: '13px 16px',
                                            lineHeight: 1.5,
                                            borderRadius: 0,
                                            display: 'flex',
                                            width: '100%',
                                            fontWeight: '600 !important',
                                            textAlign: 'left',
                                            border: 'none',
                                            color: 'var(--basic-color)',
                                            transition: 'background-color 50ms',
                                        }}
                                        className='accordion__header'
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography
                                            sx={{
                                                whiteSpace: 'normal',
                                                wordWrap: 'break-word',
                                                overflowWrap: 'break-word',
                                                maxWidth: '50vw',
                                                color: '#196CA7',
                                            }}>
                                            {domain || name}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            paddingX: '16px !important',
                                            gap: '12px'
                                        }}
                                    >
                                        <Box sx={boxStyled}>
                                            <Typography variant="caption" color="text.secondary">{domain ? dictionary.domain : dictionary.channel}</Typography>
                                            <Typography
                                                sx={{
                                                    whiteSpace: 'normal',
                                                    wordWrap: 'break-word',
                                                    overflowWrap: 'break-word',
                                                    maxWidth: '280px',
                                                }}>
                                                {domain || name}
                                            </Typography>
                                        </Box>
                                        <Box sx={boxStyled}>
                                            <Typography variant="caption" color="text.secondary">{dictionary.status}</Typography>
                                            <Typography component="div" sx={{color: status === 'active' ? 'var(--color-accent-first)' : 'var(--color-accent-second)'}}>

                                                <Typography className={status}>
                                                    {setStatusLabel(status, dictionary)}
                                                </Typography>
                                            </Typography>
                                        </Box>
                                        <Box sx={boxIconStyled}>
                                            {listContent === 'sites' && status !== 'nopublic' && (
                                                <MyTooltip
                                                    position={'top'}
                                                    title={dictionary?.copy_widjet_code}
                                                >
                                                    <CopyIcon
                                                        className="copy-icon"
                                                        onClick={() =>
                                                            copyTextHandler(widgetTemplate(hash))
                                                        }
                                                    />
                                                </MyTooltip>
                                            )}
                                            <MyTooltip position={'top'} title={dictionary?.edit}>
                                                <EditIcon
                                                    onClick={() => {
                                                        changeContentHandler(content, id, true);
                                                        navigate(
                                                            listContent === 'channels'
                                                                ? path + `_${id}#step-1`
                                                                : path + `${id}#step-1`
                                                        );
                                                    }}
                                                />
                                            </MyTooltip>
                                            {status !== 'nopublic' && listContent === 'sites' && (
                                                <MyTooltip
                                                    position={'top'}
                                                    title={
                                                        status === 'active'
                                                            ? dictionary?.turn_off
                                                            : dictionary?.turn_on
                                                    }
                                                >
                                                    {status === 'active' ? (
                                                        <TurnOffIcon
                                                            onClick={() => changeStatusHandler(id, 'disable')}
                                                        />
                                                    ) : (
                                                        (activeSites === maxActiveSites || activeSites > maxActiveSites ? (
                                                            <TurnDisabledIcon style={{cursor: 'default'}}/>
                                                        ) : (

                                                            <TurnOnIcon
                                                                onClick={() => {
                                                                    if (status === 'disabled') {
                                                                        changeStatusHandler(id, 'enable');
                                                                    } else {
                                                                        changeStatusHandler(id, 'nopublic');
                                                                    }
                                                                }}
                                                            />
                                                        ))
                                                    )}
                                                </MyTooltip>
                                            )}
                                            {status !== 'nopublic' && listContent === 'channels' && (
                                                <MyTooltip
                                                    position={'top'}
                                                    title={
                                                        status === 'active'
                                                            ? dictionary?.turn_off
                                                            : dictionary?.turn_on
                                                    }
                                                >
                                                    {status === 'active' ? (
                                                        <TurnOffIcon
                                                            onClick={() => changeStatusHandler(id, 'disable')}
                                                        />
                                                    ) : (
                                                        <TurnOnIcon
                                                            onClick={() => {
                                                                if (status === 'disabled') {
                                                                    changeStatusHandler(id, 'enable');
                                                                } else {
                                                                    changeStatusHandler(id, 'nopublic');
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                </MyTooltip>
                                            )}
                                            <MyPopper
                                                tooltipTitle={dictionary?.delete}
                                                status={'deleting'}
                                                currentId={id}
                                                buttonTitle={dictionary?.delete}
                                                submitButtonHandler={deleteChannelHandler}
                                            >
                                                <Typography variant="body2">
                                                    {dictionary?.channel_is_deleted}
                                                </Typography>
                                            </MyPopper>
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            );
                        }
                    )}
            </Box>}
            {screenSize !== 'xs' && <TblContainer tableSx={{minHeight: 114}}>
                <TblHead
                    setHeadCells={setHeadCells}
                    sortByParametr={
                        listContent === 'channels' ? 'second_sort_by' : 'sort_by'
                    }
                    sortOrderParametr={
                        listContent === 'channels' ? 'second_sort_order' : 'sort_order'
                    }
                />
                <TableBody>
                    {list.length === 0 && isSuccess && (
                        <EmptyListAlertText sx={{minHeight: 59}}/>
                    )}
                    {list.length > 0 &&
                        list.map(
                            ({
                                 id,
                                 domain,
                                 protocol,
                                 status,
                                 expiration,
                                 hash,
                                 error,
                                 name,
                             }) => {
                                return (
                                    <TableRow className="table-main__row" key={id}>
                                        <TableCell
                                            sx={{
                                                '& svg path': {
                                                    fill: '#fd4d5c',
                                                },
                                            }}
                                        >
                                            <Box sx={{display: 'inline-flex', gap: 2}}>
                                                {name && <Typography>{name}</Typography>}
                                                {domain && (
                                                    <MyLink
                                                        link={`${protocol}://${domain}`}
                                                        text={domain}
                                                    />
                                                )}
                                                {listContent === 'sites' &&
                                                    status !== 'nopublic' &&
                                                    (error?.step4 || error?.step5) && (
                                                        <MyTooltip
                                                            sx={{
                                                                color: 'colorAccent.secondLight',
                                                                width: 162,
                                                            }}
                                                            position={'top'}
                                                            title={`${
                                                                error?.step4
                                                                    ? dictionary?.site_step_4_warning
                                                                    : ''
                                                            }  ${
                                                                error?.step5
                                                                    ? dictionary?.site_step_5_warning
                                                                    : ''
                                                            }`}
                                                        >
                                                            <ErrorIcon/>
                                                        </MyTooltip>
                                                    )}
                                            </Box>
                                        </TableCell>
                                        <TableCell className="status">
                                            <Typography className={status}>
                                                {setStatusLabel(status, dictionary)}
                                            </Typography>
                                            {expiration && myTimer(expiration)}
                                        </TableCell>
                                        <TableCell
                                            className="action"
                                            sx={{
                                                '& svg:last-of-type': {
                                                    opacity: status === 'deleting' ? 0.5 : 1,
                                                    pointerEvents: status === 'deleting' ? 'none' : 'all',
                                                },
                                            }}
                                        >
                                            {listContent === 'sites' && status !== 'nopublic' && (
                                                <MyTooltip
                                                    position={'top'}
                                                    title={dictionary?.copy_widjet_code}
                                                >
                                                    <CopyIcon
                                                        className="copy-icon"
                                                        onClick={() =>
                                                            copyTextHandler(widgetTemplate(hash))
                                                        }
                                                    />
                                                </MyTooltip>
                                            )}
                                            <MyTooltip position={'top'} title={dictionary?.edit}>
                                                <EditIcon
                                                    onClick={() => {
                                                        changeContentHandler(content, id, true);
                                                        navigate(
                                                            listContent === 'channels'
                                                                ? path + `_${id}#step-1`
                                                                : path + `${id}#step-1`
                                                        );
                                                    }}
                                                />
                                            </MyTooltip>
                                            {status !== 'nopublic' && listContent === 'sites' && (
                                                <MyTooltip
                                                    position={'top'}
                                                    title={
                                                        status === 'active'
                                                            ? dictionary?.turn_off
                                                            : dictionary?.turn_on
                                                    }
                                                >
                                                    {status === 'active' ? (
                                                        <TurnOffIcon
                                                            onClick={() => changeStatusHandler(id, 'disable')}
                                                        />
                                                    ) : (
                                                        (activeSites === maxActiveSites || activeSites > maxActiveSites ? (
                                                            <TurnDisabledIcon style={{cursor: 'default'}}/>
                                                        ) : (

                                                            <TurnOnIcon
                                                                onClick={() => {
                                                                    if (status === 'disabled') {
                                                                        changeStatusHandler(id, 'enable');
                                                                    } else {
                                                                        changeStatusHandler(id, 'nopublic');
                                                                    }
                                                                }}
                                                            />
                                                        ))
                                                    )}
                                                </MyTooltip>
                                            )}
                                            {status !== 'nopublic' && listContent === 'channels' && (
                                                <MyTooltip
                                                    position={'top'}
                                                    title={
                                                        status === 'active'
                                                            ? dictionary?.turn_off
                                                            : dictionary?.turn_on
                                                    }
                                                >
                                                    {status === 'active' ? (
                                                        <TurnOffIcon
                                                            onClick={() => changeStatusHandler(id, 'disable')}
                                                        />
                                                    ) : (
                                                        <TurnOnIcon
                                                            onClick={() => {
                                                                if (status === 'disabled') {
                                                                    changeStatusHandler(id, 'enable');
                                                                } else {
                                                                    changeStatusHandler(id, 'nopublic');
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                </MyTooltip>
                                            )}
                                            <MyPopper
                                                tooltipTitle={dictionary?.delete}
                                                status={'deleting'}
                                                currentId={id}
                                                buttonTitle={dictionary?.delete}
                                                submitButtonHandler={deleteChannelHandler}
                                            >
                                                <Typography variant="body2">
                                                    {dictionary?.channel_is_deleted}
                                                </Typography>
                                            </MyPopper>
                                        </TableCell>
                                    </TableRow>
                                );
                            }
                        )}
                </TableBody>
            </TblContainer>}
            <TblPagination
                data={paginationData}
                invalidateTag={'Sites'}
                parametr={listContent === 'channels' ? 'second_page' : 'page'}
            />
        </>
    );
};

export default ChannelsListTemplate;
