import { useContext, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import { FlexBox } from '../../mui/theme';

import { ReactComponent as Logo } from '../../assets/logo-small.svg';
import { ReactComponent as CloseMenuIcon } from '../../assets/icons/close.svg';

import { useGetMessageDataQuery } from '../../api/clientPartApi/apiCommonDialogs';
import { useConnectToDialogueMutation } from '../../api/clientPartApi/apiDialoguesSlice';
import {clearMessages} from '../../reducers/messagesSlice';

import { PageContext } from '../../pages/Page';
import { PageContainerContext } from '../../pages/PageContainer';
import {useDispatch} from "react-redux";

const PopupMessage = ({
  dialogueMessagesArray,
  setDialogueMessagesArray,
  messageId,
  setMessageId,
  setSelectedDialogueWithRedirection,
}) => {
  const { navigate, pathname, dictionary } = useContext(PageContext);
  const { hash } = useContext(PageContainerContext);
  const dispatch = useDispatch();

  const { data: message } = useGetMessageDataQuery(
    { id: messageId },
    { skip: !messageId }
  );

  const [connectToDialog] = useConnectToDialogueMutation();

  useEffect(() => {
    if (message && message.data.id === messageId) {
      if (hash.includes('#dialogue')) {
        const id = hash.slice(hash.indexOf('_') + 1);

        if (message.data.dialog.id === id) {
          setDialogueMessagesArray([
            ...dialogueMessagesArray.filter((item) => item.dialog.id !== id),
          ]);
        } else {
          setDialogueMessagesArray([...dialogueMessagesArray, message.data]);
        }
      } else {
        setDialogueMessagesArray([...dialogueMessagesArray, message.data]);
      }
      setMessageId('');
    }
  }, [message, hash, messageId, dialogueMessagesArray]);

  const popupStyles = {
    position: 'fixed',
    bottom: 10,
    right: 30,
    zIndex: 2,
    width: 300,
    padding: '25px 12px',
    backgroundColor: 'basicColor.colorWhite',
    border: '1px solid',
    borderColor: 'basicColor.color500',
    '& .logo': {
      flexShrink: 0,
      border: '1px solid',
      borderColor: 'basicColor.color500',
    },
    '& .close-icon': {
      cursor: 'pointer',
      position: 'absolute',
      right: 12,
      top: 11,
      '& svg': {
        pointerEvents: 'none',
      },

      '& path': {
        fill: '#363f48',
      },
    },
    '& .MuiTypography-root': {
      lineHeight: 1.2,
      pointerEvents: 'none',

      '&.ellipsis': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        wordBreak: 'break-word',
      },
    },
  };

  const connectToDialogHandler = (id) => {
    connectToDialog(id)
      .unwrap()
      .then((res) => {
        navigate(`/dialogues/active#dialogue_${id}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const cleatHistory = () => {
    return dispatch(clearMessages())
  };


  return (
    <>
      {dialogueMessagesArray.length !== 0 && (
        <>
          {dialogueMessagesArray.map(({ id, dialog, visitor, text, site }) => {
            return (
              <FlexBox
                key={id}
                className="row-10"
                alignItems="center"
                sx={{ ...popupStyles }}
                onClick={(e) => {
                  if (e.target.id === 'popup-close-icon') {
                    setDialogueMessagesArray([
                      ...dialogueMessagesArray.filter((item) => item.id !== id),
                    ]);
                  } else {
                    setDialogueMessagesArray([
                      ...dialogueMessagesArray.filter(
                        (item) => item.dialog.id !== dialog.id
                      ),
                    ]);

                    if (dialog.status === 'waiting') {
                      cleatHistory()
                      setSelectedDialogueWithRedirection({
                        isClicked: true,
                        status: 'waiting',
                        redirectedFromActiveOrWaitingDialoguesSection:
                          pathname === '/dialogues/active' ||
                          pathname === '/dialogues/waiting'
                            ? true
                            : false,
                      });
                      connectToDialogHandler(dialog.id);
                    } else {
                      cleatHistory()
                      setSelectedDialogueWithRedirection({
                        isClicked: true,
                        status: 'unresolved',
                        redirectedFromActiveOrWaitingDialoguesSection:
                          pathname === '/dialogues/active' ||
                          pathname === '/dialogues/waiting'
                            ? true
                            : false,
                      });
                      navigate(`/dialogues/active#dialogue_${dialog.id}`);
                    }
                  }
                }}
              >
                <Logo className="logo" />
                <Box className="close-icon" id="popup-close-icon">
                  <CloseMenuIcon />
                </Box>
                <FlexBox className="col-8" sx={{ minHeight: 81 }}>
                  <Typography
                    className="ellipsis"
                    sx={{
                      fontWeight: 600,
                      marginRight: 2.75,
                      WebkitLineClamp: '1',
                    }}
                  >
                    {visitor.fullname}
                  </Typography>
                  <Typography
                    className="ellipsis"
                    sx={{
                      minHeight: 34,
                      WebkitLineClamp: '2',
                      color: 'basicColor.color700',
                    }}
                  >
                    {text ? text : dictionary?.file}
                  </Typography>
                  <Typography
                    sx={{ color: 'basicColor.color700', fontSize: 12 }}
                  >
                    {site.domain}
                  </Typography>
                </FlexBox>
              </FlexBox>
            );
          })}
        </>
      )}
    </>
  );
};

export default PopupMessage;
