import { useEffect, useContext } from 'react';

import { IconButton, Alert, Collapse } from '@mui/material';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/icons/error.svg';
import { ReactComponent as ConfirmedIcon } from '../../../assets/icons/confirmed-alert.svg';
import { ReactComponent as InfoIcon } from '../../../assets/icons/info.svg';

import { PageContext } from '../../../pages/Page';

const MyAlert = ({ message, type }) => {
  const { setAlertMessage } = useContext(PageContext);

  useEffect(() => {
    if (message) {
      const timeout = setTimeout(() => {
        setAlertMessage({});
      }, 10000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [message]);

  const alertStyles = {
    '&.MuiCollapse-root': {
      position: 'fixed',
      left: '50%',
      bottom: 40,
      transform: 'translate(-50%, 0)',
      zIndex: 2,

      maxWidth: 360,
      width: '100%',
    },
    '& .MuiAlert-root': {
      alignItems: 'center',
      whiteSpace: 'pre-wrap',
    },
    '&.success .MuiAlert-root': {
      backgroundColor: 'colorAccent.first',
    },
    '&.error .MuiAlert-root': {
      backgroundColor: 'colorAccent.second',
    },
    '&.information .MuiAlert-root': {
      backgroundColor: 'secondaryColor.main',
    },
    '& .MuiAlert-icon svg path ': {
      fill: 'white',
    },
    '& .MuiIconButton-root': {
      scale: '1',
      transition: 'scale 0.2s',
      '&:hover': {
        scale: '1.3',
      },
    },
    '& .MuiIconButton-root:hover svg path': {
      fill: 'white',
    },
  };

  return (
    <>
      {message && (
        <Collapse className={type} sx={{ ...alertStyles }} in={!!message}>
          <Alert
            icon={
              type === 'success' ? (
                <ConfirmedIcon />
              ) : type === 'information' ? (
                <InfoIcon />
              ) : (
                <ErrorIcon />
              )
            }
            action={
              <IconButton onClick={() => setAlertMessage({})}>
                <CloseIcon />
              </IconButton>
            }
          >
            {message}
          </Alert>
        </Collapse>
      )}
    </>
  );
};

export default MyAlert;
