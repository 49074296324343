import { apiSlice } from '../apiSlice';

const apiNotificationsSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getNotifications: builder.query({
            query: (query) =>
                `admin/notifications${query.limit}${query.page || ''}${query.sortBy || ''}${query.sortOrder || ''}${query.status || ''}${query.range_start || ''}${query.range_end || ''}${query.count || ''}`,
            providesTags: ['Notifications'],
        }),
        changeNotificationStatus: builder.mutation({
            query: (notification) => ({
                url: `admin/notifications/${notification.id}/${notification.action}`,
                method: 'POST',
            }),
            invalidatesTags: ['Notifications'],
        }),
        createNotification: builder.mutation({
            query: (notification) => {
                const formData = new FormData();

                formData.append("name[uk]", notification.name.uk);
                formData.append("name[en]", notification.name.en);
                formData.append("name[ru]", notification.name.ru);
                formData.append("content[uk]", notification.content.uk);
                formData.append("content[en]", notification.content.en);
                formData.append("content[ru]", notification.content.ru);
                formData.append("role", notification.role);
                formData.append("evaluation", notification.evaluation);
                formData.append("feedback", notification.feedback);

                if (notification.image?.uk[0]) {
                    formData.append("image[uk]", notification.image.uk[0]);
                }
                if (notification.image?.en[0]) {
                    formData.append("image[en]", notification.image.en[0]);
                }
                if (notification.image?.ru[0]) {
                    formData.append("image[ru]", notification.image.ru[0]);
                }

                return {
                    url: "admin/notifications",
                    method: "POST",
                    body: formData,
                    headers: {
                        "cache-control": "no-cache"
                    },
                };
            },
            invalidatesTags: ["Notifications"],
        }),
        getNotification: builder.query({
            query: (id) => ({
                url: `admin/notifications/${id}`,
            }),
            providesTags: ['Notifications'],
        }),
        editNotification: builder.mutation({
            query: (notification) => {
                const formData = new FormData();

                formData.append("name[uk]", notification.body.name.uk);
                formData.append("name[en]", notification.body.name.en);
                formData.append("name[ru]", notification.body.name.ru);
                formData.append("content[uk]", notification.body.content.uk);
                formData.append("content[en]", notification.body.content.en);
                formData.append("content[ru]", notification.body.content.ru);
                formData.append("role", notification.body.role);
                formData.append("evaluation", notification.body.evaluation);
                formData.append("feedback", notification.body.feedback);

                if (notification.body.image?.uk[0]) {
                    formData.append("image[uk]", notification.body.image.uk[0]);
                }
                if (notification.body.image?.en[0]) {
                    formData.append("image[en]", notification.body.image.en[0]);
                }
                if (notification.body.image?.ru[0]) {
                    formData.append("image[ru]", notification.body.image.ru[0]);
                }

                return {
                    url: `admin/notifications/${notification.id}`,
                    method: "POST",
                    body: formData,
                    headers: {
                        "cache-control": "no-cache"
                    },
                };
            },
            invalidatesTags: ["Notifications"],
        }),

        saveNotificationImage: builder.mutation({
            query: (file) => {
                const formData = new FormData();
                formData.append("image", file);

                return {
                    url: '/admin/notifications/upload',
                    method: 'POST',
                    body: formData,
                };
            },
            invalidatesTags: ['Notifications'],
        }),

        deleteNotification: builder.mutation({
            query: (id) => ({
                url: `admin/notifications/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Notifications'],
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetNotificationsQuery,
    useChangeNotificationStatusMutation,
    useCreateNotificationMutation,
    useGetNotificationQuery,
    useEditNotificationMutation,
    useSaveNotificationImageMutation,
    useDeleteNotificationMutation,
} = apiNotificationsSlice;
