import {createSlice} from '@reduxjs/toolkit';

import {apiSlice} from '../api/apiSlice';

const messagesSlice = createSlice({
    name: 'chatMessages',
    initialState:
    {
        dialogId: null,
        income: [],
        outcome: [],
        dialogsHistory: [],
    },
    reducers: {
        clearMessages: (state) => {
            state.income = [];
            state.outcome = [];
            state.dialogsHistory = [];
        },
        setDialogHistory: (state, action) => {
            const dialog = state.dialogsHistory.find((dialog) => dialog.id === action.payload);
            if (dialog) {
                state.income = dialog.income;
                state.outcome = dialog.outcome;
            }
        },
        excludeDialogHistory: (state, action) => {
            state.dialogsHistory = action.payload.dialogsHistory;
        }

    },

    extraReducers: (builder) => {
        builder.addMatcher(
            apiSlice.endpoints.getMessages.matchFulfilled,
            (state, action) => {
                const page = action?.meta?.arg?.originalArgs?.page;
                if (page > 1) {
                    const income = action.payload.data?.chat?.messages?.income
                        ? action.payload.data.chat.messages.income
                        : action.payload.data.client.chat.messages.income;

                    const outcome = action.payload.data?.chat?.messages?.outcome
                        ? action.payload.data.chat.messages.outcome
                        : action.payload.data.client.chat.messages.outcome;

                    state.income = state.income.concat(
                        income.filter(
                            (newMessage) =>
                                !state.income.some(
                                    (existingMessage) =>
                                        existingMessage.text === newMessage.text &&
                                        existingMessage.created_at === newMessage.created_at
                                )
                        )
                    );

                    state.outcome = state.outcome.concat(
                        outcome.filter(
                            (newMessage) =>
                                !state.outcome.some(
                                    (existingMessage) =>
                                        existingMessage.text === newMessage.text &&
                                        existingMessage.created_at === newMessage.created_at
                                )
                        )
                    );


                    state.dialogsHistory = state.dialogsHistory.filter((dialog) => dialog.parentId !== action.payload.data.parentId);
                    state.dialogsHistory.push({
                        id: action.meta.arg.originalArgs.id,
                        income: state.income,
                        outcome: state.outcome,
                        parentId: action.payload.data.parentId,
                        page
                    });
                } else {
                    if (action.payload.data?.id !== state.dialogId) {
                        state.dialogId = action.payload.data?.id
                        state.income = action.payload.data?.chat?.messages?.income ? action.payload.data.chat.messages.income : action.payload.data.client.chat.messages.income;
                        state.outcome = action.payload.data?.chat?.messages?.outcome ? action.payload.data.chat.messages.outcome : action.payload.data.client.chat.messages.outcome;
                    } else  {
                        const income = action.payload.data?.chat?.messages?.income
                            ? action.payload.data.chat.messages.income
                            : action.payload.data.client.chat.messages.income;

                        const outcome = action.payload.data?.chat?.messages?.outcome
                            ? action.payload.data.chat.messages.outcome
                            : action.payload.data.client.chat.messages.outcome;


                        const outcomeDB =
                            outcome.filter(
                                (newMessage) =>
                                    !state.outcome.some(
                                        (existingMessage) =>
                                            existingMessage.text === newMessage.text &&
                                            existingMessage.created_at === newMessage.created_at
                                    )

                            );

                        state.income = state.income.concat(
                            income.filter(
                                (newMessage) =>
                                    !state.income.some(
                                        (existingMessage) =>
                                            existingMessage.text === newMessage.text &&
                                            existingMessage.created_at === newMessage.created_at
                                    )
                            )
                        );

                        state.outcome = state.outcome.concat(
                            outcome.filter(
                                (newMessage) =>
                                    !state.outcome.some(
                                        (existingMessage) =>
                                            existingMessage.text === newMessage.text &&
                                            existingMessage.created_at === newMessage.created_at
                                    )
                            )
                        );
                    }
                }
            }
        );
    },
});

export const {clearMessages, setDialogHistory, excludeDialogHistory} = messagesSlice.actions;

export default messagesSlice.reducer;