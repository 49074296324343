import { useContext, useLayoutEffect } from 'react';
import { useGetSitesQuery } from '../../../api/clientPartApi/apiSitesSlice';
import { useGetChannelsQuery } from '../../../api/clientPartApi/apiChannelSlice';
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard';

import Section from '../../../layouts/Section/Section';
import ChannelsList from './ChannelsList';
import AddChannel from './AddChannel';

import { PageContainerContext } from '../../PageContainer';
import { PageContext } from '../../Page';

const Channels = () => {
  const { navigate, alertMessageContent, dictionary } = useContext(PageContext);
  const { createSlug, content, changeContentHandler, skip, queryParams, hash } =
    useContext(PageContainerContext);

  const [copiedText, copy] = useCopyToClipboard();

  useLayoutEffect(() => {
    if (hash === '#add' && content !== 'addChannel') {
      changeContentHandler('addChannel');
    } else if (!hash && content !== 'channelsList') {
      changeContentHandler('channelsList');
    }
  }, [hash]);

  const {
    data: sites,
    isFetching: sitesFetching,
    isLoading: sitesLoading,
    isSuccess: sitesSuccess,
    isError: sitesFetchingError,
    error: sitesError,
  } = useGetSitesQuery(
    {
      limit: createSlug('limit'),
      page: createSlug('page'),
      sortBy: createSlug('sort_by'),
      sortOrder: createSlug('sort_order'),
    },
    { skip }
  );

  const {
    data: channels,
    isLoading: channelsLoading,
    isFetching: channelsFetching,
    isSuccess: channelsSuccess,
    isError: channelsFetchingError,
    error: channelsError,
  } = useGetChannelsQuery(
    {
      limit: queryParams.get('second_limit')
        ? `?limit=${queryParams.get('second_limit')}`
        : '?limit=15',
      page: queryParams.get('second_page')
        ? `&page=${queryParams.get('second_page')}`
        : '',
      sortBy: queryParams.get('second_sort_by')
        ? `&sort_by=${queryParams.get('second_sort_by')}`
        : '',
      sortOrder: queryParams.get('second_sort_order')
        ? `&sort_order=${queryParams.get('second_sort_order')}`
        : '',
    },
    { skip }
  );

  const activeSites = sites ? sites?.data?.sites?.active : [];
  const maxActiveSites = sites ? sites?.data?.sites?.max : [];
  const sitesList = sites ? sites?.data?.sites?.data : [];
  const sitesListPaginationData = sites?.data?.sites || {};
  const maxNumOfSites = Number(sites?.data?.sites?.max) || '';
  const currentNumOfSites = sites?.data?.sites?.total || '';
  const channelsList = channels?.data?.channels?.data || [];
  const channelsListPaginationData = channels?.data?.channels || {};

  return (
    <>
      <Section
        isLoading={
          (sitesLoading && channelsLoading) ||
          (sitesLoading && queryParams) ||
          (channelsLoading && queryParams)
        }
        isFetching={
          (sitesFetching && channelsFetching) ||
          (sitesFetching && queryParams) ||
          (channelsFetching && queryParams)
        }
        isError={sitesFetchingError || channelsFetchingError}
        error={sitesError || channelsError}
        title={
          content === 'channelsList'
            ? dictionary?.channels_list
            : dictionary?.choose_channel
        }
        sx={{ maxWidth: content === 'channelsList' ? 920 : 730 }}
        content={content}
      >
        {(!content || content === 'channelsList') && (
          <ChannelsList
            sitesList={sitesList}
            activeSites={activeSites}
            maxActiveSites={maxActiveSites}
            sitesPaginationData={sitesListPaginationData}
            sitesSuccess={sitesSuccess}
            channelsList={channelsList}
            channelsSuccess={channelsSuccess}
            channelsPaginationData={channelsListPaginationData}
            copy={copy}
            copiedText={copiedText}
            changeContentHandler={changeContentHandler}
            navigate={navigate}
            alertMessageContent={alertMessageContent}
          />
        )}
        {content === 'addChannel' && (
          <AddChannel
            sitesList={sitesList}
            maxNumOfSites={maxNumOfSites}
            currentNumOfSites={currentNumOfSites}
            changeContentHandler={changeContentHandler}
            navigate={navigate}
            alertMessageContent={alertMessageContent}
          />
        )}
      </Section>
    </>
  );
};

export default Channels;
