import { useState, useContext, useEffect } from 'react';
import { useDeleteLogoMutation } from '../../../../api/clientPartApi/apiSitesSlice';

import { Box, Typography } from '@mui/material';

import GeneralInput from '../../../../components/Form/FormInputs/GeneralInput';
import SelectWithFormContext from '../../../../components/Form/FormSelect/Select/SelectWithFormContext';
import ColorInput from '../../../../components/Form/FormInputs/ColorInput';
import ExpandedSwitcher from '../../../../components/ui/Switcher/ExpandedSwitcher';
import UploadFile from '../../../../components/Form/UploadFile/UploadFile';

import {
  clientPartFontSelect as fontOptions,
  setClientPartPositionSelect as setPositionOptions,
} from '../../../../data/formSelectData';

import {
  setMarginLabels,
  saveToLocalStorage,
} from '../../../../helpers/functions';

import { PageContext } from '../../../Page';
import { FormContainerContext } from '../../../../components/Form/FormContainer';

const SecondStep = ({ savedSite, savedLogo, logo, setLogo }) => {
  const { dictionary, alertMessageContent } = useContext(PageContext);
  const { usedFormValidationErrors } = useContext(FormContainerContext);

  const [positionSelectValue, setPositionSelectValue] = useState('');
  const [timeoutSwitcherValue, setTimeoutSwitcherValue] = useState(false);

  const [deleteLogo] = useDeleteLogoMutation();

  const deleteLogoHandler = (id) => {
    deleteLogo(savedSite.id)
      .unwrap()
      .then((res) => {
        saveToLocalStorage('site', {
          ...savedSite,
          logotype: '',
        });
        setLogo([]);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (JSON.stringify(usedFormValidationErrors) !== '{}') {
      if (usedFormValidationErrors?.logotype) {
        alertMessageContent(dictionary?.logo_type_error, 'error');
      } else {
        alertMessageContent(dictionary?.next_step_failed, 'error');
      }
      console.log(usedFormValidationErrors);
    }
  }, [usedFormValidationErrors]);


  return (
    <>
      <UploadFile
        fieldName={'logotype'}
        title={dictionary?.logo}
        file={logo}
        setFile={setLogo}
        receivedValue={savedLogo?.file || savedSite?.logotype?.file}
        deleteImage={deleteLogoHandler}
      />
      <GeneralInput
        fieldName={'name'}
        label={dictionary?.site_name}
        receivedValue={savedSite?.name}
      />
      <SelectWithFormContext
        fieldName={'font'}
        label={dictionary?.font}
        labelId={'fontSelect'}
        options={fontOptions}
        receivedValue={savedSite?.font}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.25 }}>
        <Typography variant="body3">{dictionary?.colors}</Typography>
        <ColorInput
          fieldName={'button_bg'}
          label={dictionary?.button_bg}
          defaultValue={savedSite?.colors?.button?.background || '#196CA7'}
        />
        <ColorInput
          fieldName={'button_icon'}
          label={dictionary?.button_icon_bg}
          defaultValue={savedSite?.colors?.button?.icon || '#FFFFFF'}
        />
        <ColorInput
          fieldName={'window_bg'}
          label={dictionary?.window_bg}
          defaultValue={savedSite?.colors?.window?.background || '#FFFFFF'}
        />
        <ColorInput
          fieldName={'window_content'}
          label={dictionary?.window_content}
          defaultValue={savedSite?.colors?.window?.content || '#363F48'}
        />
        <ColorInput
          fieldName={'windiw_bg_message'}
          label={dictionary?.message_bg}
          defaultValue={
            savedSite?.colors?.window?.background_message || '#9dcef0'
          }
        />
        <ColorInput
          fieldName={'header_and_copyright_bg'}
          label={dictionary?.header_and_copyright_bg}
          defaultValue={
            savedSite?.colors?.header_and_copyright?.background || '#196CA7'
          }
        />
        <ColorInput
          fieldName={'header_and_copyright_content'}
          label={dictionary?.header_and_copyright_content}
          defaultValue={
            savedSite?.colors?.header_and_copyright?.content || '#FFFFFF'
          }
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.25 }}>
        <Typography variant="body3">
          {dictionary?.position_and_margins}
        </Typography>
        <SelectWithFormContext
          fieldName={'position'}
          label={dictionary?.position}
          options={setPositionOptions(dictionary)}
          setSelectedValue={setPositionSelectValue}
          useSelectedValueImmediatly={true}
          receivedValue={savedSite?.position}
        />
        <Box sx={{ display: 'flex', gap: 2.25, maxWidth: 480 }}>
          <GeneralInput
            fieldName={'margin_first'}
            label={setMarginLabels(
              positionSelectValue,
              'margin_first',
              dictionary
            )}
            units={'px'}
            receivedValue={
              savedSite?.margins?.bottom || savedSite?.margins?.top
            }
            type={'number'}
          />
          <GeneralInput
            fieldName={'margin_second'}
            label={setMarginLabels(
              positionSelectValue,
              'margin_second',
              dictionary
            )}
            units={'px'}
            receivedValue={
              savedSite?.margins?.right || savedSite?.margins?.left
            }
            type={'number'}
          />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.25 }}>
        <Typography variant="body3">{dictionary?.rounding}</Typography>
        <GeneralInput
          fieldName={'button_rounding'}
          label={dictionary?.button_rounding}
          units={'px'}
          receivedValue={savedSite?.rounding?.button}
          type={'number'}
        />
        <GeneralInput
          fieldName={'window_rounding'}
          label={dictionary?.window_rounding}
          units={'px'}
          receivedValue={savedSite?.rounding?.window}
          type={'number'}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2.25,
          marginBottom: 1.25,
        }}
      >
        <ExpandedSwitcher
          fieldName={'timeout_switcher'}
          label={dictionary?.delayed_display}
          setChecked={setTimeoutSwitcherValue}
          checked={timeoutSwitcherValue}
          receivedValue={savedSite?.timeout ? true : false}
        />
        <GeneralInput
          fieldName={'timeout'}
          label={dictionary?.time}
          units={dictionary?.seconds}
          disabled={!timeoutSwitcherValue}
          receivedValue={!timeoutSwitcherValue ? '' : savedSite?.timeout}
          clearReceivedValue={!timeoutSwitcherValue}
          type={'number'}
        />
      </Box>
    </>
  );
};

export default SecondStep;
