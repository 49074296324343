import { useState, useEffect, useContext } from 'react';
import { useGetDialogueTagsQuery } from '../../../../api/clientPartApi/apiDialoguesSlice';

import { TextField, Chip } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';

import MyPopper from '../../../../components/ui/Popper/MyPopper';
import BaseAutocompleteSelect from '../../../../components/Form/FormSelect/AutocompleteSelect/BaseAutocompleteSelect';

import { PageContext } from '../../../Page';

const AddTagPopper = ({
    dialogueId,
    dialogueStatus,
    changeTags,
    setAlertMessage,
    alertMessageContent,
    tagsList,
}) => {
    const { dictionary } = useContext(PageContext);

    const [newTags, setNewTags] = useState([]);

    const filter = createFilterOptions({ matchFrom: 'start' });

    const {
        data: allTags,
        isSuccess: allTagsSuccess,
        isError: allTagsFetchingError,
    } = useGetDialogueTagsQuery(undefined, {
        skip: dialogueStatus !== 'unresolved',
    });

    const allTagsList = allTagsSuccess ? allTags?.data?.tags?.data.map(({ id, name }) => name) : [];

    useEffect(() => {
        if (dialogueId) {
            setNewTags(tagsList);
        }
    }, [dialogueId, tagsList]);

    const changeTagsHandler = () => {
        const paramses = new URLSearchParams();

        newTags.forEach((item, index) => {
            paramses.append(`tags[${index}]`, item);
        });

        const tags = paramses.toString();

        setAlertMessage({});

        changeTags({ id: dialogueId, tags })
            .unwrap()
            .then((res) => {
                alertMessageContent(dictionary?.tags_changed, 'success');
                setNewTags([]);
            })
            .catch((error) => {
                alertMessageContent(dictionary?.add_tag_error, 'error');
            });
    };

    const cleareFields = () => {
        setTimeout(() => {
            setNewTags([]);
        }, 200);
    };

    return (
        <MyPopper
            tooltipTitle={dictionary?.add_tag}
            status={'add_tag'}
            buttonTitle={dictionary?.save}
            currentId={dialogueId}
            submitButtonHandler={changeTagsHandler}
            submitButtonClass={tagsList.length === 0 && newTags.length === 0 ? 'btn--disabled' : ''}
            cleareFields={true}
            cleareFieldsHandler={() => cleareFields()}
        >
            <BaseAutocompleteSelect
                multiple
                options={allTagsList.map((option) => option)}
                getOptionLabel={(option) => option}
                defaultValue={tagsList}
                freeSolo
                filterSelectedOptions
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip label={option} {...getTagProps({ index })} />
                    ))
                }
                onChange={(event, value) => {
                    setNewTags(value);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={allTagsFetchingError ? 'Error' : dictionary?.tag}
                        placeholder={dictionary?.enter_tag}
                    />
                )}
                noOptionsText={`${dictionary?.search}...`}
                disablePortal={true}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option);
                    if (inputValue !== '' && !isExisting) {
                        // filtered.unshift(inputValue);
                    }

                    return filtered;
                }}
            />
        </MyPopper>
    );
};

export default AddTagPopper;
