import { useContext } from 'react';

import { Box, Typography } from '@mui/material';

import Title from '../../components/ui/Title/Title';
import Spinner from '../../components/ui/Spinner/Spinner';

import { PageContext } from '../../pages/Page';

const Section = ({
  sx,
  title,
  content = true,
  isError,
  children,
  isLoading,
  isFetching,
  shouldShowErrorText,
  error = 'ERROR',
}) => {
  const { clientCheck } = useContext(PageContext);

  const renderSectionContent = () => {
    if (!shouldShowErrorText) {
      if (isError) {
        return <Typography variant="h3">{error}</Typography>;
      } else {
        return children;
      }
    } else {
      return (
        <>
          {children}
          {error}
        </>
      );
    }
  };

  const sectionContent = renderSectionContent();

  return (
    <Box
      component="section"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}
    >
      <Title
        heading={'h2'}
        title={title}
        sx={{
          marginTop: clientCheck ? 0 : 1.875,
          display: title ? '' : 'none',
        }}
      />
      <Box
        sx={{
          position: isLoading || isFetching ? 'relative' : 'static',
          padding: 3.75,

          boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.04)',
          borderRadius: 2.5,
          backgroundColor: 'basicColor.colorWhite',
          ...sx,
        }}
      >
        {(isLoading || isFetching || !content) && <Spinner content={content} />}
        {sectionContent}
      </Box>
    </Box>
  );
};

export default Section;
