import React, { useContext, useLayoutEffect } from 'react';
import {
    useGetAnswersQuery,
    useDeleteAnswerMutation,
} from '../../../api/clientPartApi/apiTemplatesSlice';

import {
    TableBody,
    TableRow,
    TableCell,
    Box,
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails
} from '@mui/material';

import Section from '../../../layouts/Section/Section';
import TblPagination from '../../../components/Table/TablePagination';
import TblContainer from '../../../components/Table/TableContainer';
import TblHead from '../../../components/Table/TableHead';
import MyTooltip from '../../../components/ui/Tooltip/MyTooltip';
import MyToolbar from '../../../components/Toolbar/MyToolbar';
import EmptyListAlertText from '../../../components/ui/Alert/EmptyListAlertText';

import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';

import { setAnswersTableHeadings as setHeadCells } from '../../../data/tableHeadingsData';

import { dateConverter } from '../../../helpers/functions';

import { PageContext } from '../../Page';
import { PageContainerContext } from '../../PageContainer';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


const boxStyled = (({}) => ({
    display: 'flex',
    flexDirection: 'column',
}));


const boxIconStyled = (({}) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '24px',
}));


const typographyStyled = (({}) => ({
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    maxWidth: '280px',
}));


const Answers = () => {
    const { dictionary, screenSize } = useContext(PageContext);
    const { createSlug, content, hash, changeContentHandler } = useContext(PageContainerContext);

    useLayoutEffect(() => {
        if (!hash && content !== 'answersList') {
            changeContentHandler('answersList', '', '', 'templates');
        }
    }, [hash]);

    const {
        data: answers,
        isFetching: answersFetching,
        isLoading: answersLoading,
        isSuccess: answersSuccess,
        isError: answersFetchingError,
        error: answersError,
    } = useGetAnswersQuery({
        limit: createSlug('limit'),
        page: createSlug('page'),
        sortBy: createSlug('sort_by'),
        sortOrder: createSlug('sort_order'),
    });

    const [deleteAnswer] = useDeleteAnswerMutation();

    const answersList = answers?.data?.answers?.data || [];
    const answersListPaginationData = answers?.data?.answers || {};

    const deleteAnswerHandler = (id) => {
        deleteAnswer(id).unwrap();
    };

    return (
            <Section
                isLoading={answersLoading}
                isFetching={answersFetching}
                isError={answersFetchingError}
                error={answersError}
                title={dictionary?.answers_list}
                sx={{ maxWidth: 920}}
            >
                { screenSize !== 'xs' && <MyToolbar paginationData={answersListPaginationData} changeContentButton={false} />}
                {screenSize === 'xs' && <Box>
                    {answersList.length === 0 && answersSuccess && <EmptyListAlertText />}
                    {answersList.length > 0 &&
                        answersList.map(({ id, text, created_at }) => {
                            return (
                                    <Accordion
                                        key={id}
                                        sx={{
                                            position: 'inherit',
                                        }}
                                        className='accordion__item'
                                    >
                                        <AccordionSummary
                                            sx={{
                                                minHeight: '50px',
                                                fontSize: 'var(--fz-400)',
                                                padding: '13px 16px',
                                                lineHeight: 1.5,
                                                borderRadius: 0,
                                                display: 'flex',
                                                width: '100%',
                                                fontWeight: '600 !important',
                                                textAlign: 'left',
                                                border: 'none',
                                                color: 'var(--basic-color)',
                                                transition: 'background-color 50ms',
                                            }}
                                            className='accordion__header'
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography
                                                component="div"
                                                sx={{
                                                    whiteSpace: 'normal',
                                                    wordWrap: 'break-word',
                                                    overflowWrap: 'break-word',
                                                    maxWidth: '230px',
                                                }}>
                                                <Box
                                                    sx={{ '& p': { margin: 0 } }}
                                                    dangerouslySetInnerHTML={{ __html: text }}
                                                />
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                paddingX: '16px !important',
                                                gap: '12px'
                                            }}
                                        >
                                            <Box sx={boxStyled}>
                                                <Typography variant="caption" color="text.secondary">{dictionary.message}</Typography>
                                                <Typography
                                                    component="div"
                                                    sx={typographyStyled}>

                                                    <Box
                                                        sx={{ '& p': { margin: 0 } }}
                                                        dangerouslySetInnerHTML={{ __html: text }}
                                                    />
                                                </Typography>
                                            </Box>
                                            <Box sx={boxStyled}>
                                                <Typography variant="caption" color="text.secondary">{dictionary.created_at}</Typography>
                                                <Typography
                                                    sx={typographyStyled}>
                                                    {dateConverter(created_at)}
                                                </Typography>
                                            </Box>
                                            <Box sx={boxIconStyled}>
                                                <MyTooltip position={'top'} title={dictionary?.delete}>
                                                    <DeleteIcon
                                                        onClick={() => deleteAnswerHandler(id)}
                                                    />
                                                </MyTooltip>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                );
                            }
                        )}
                </Box>}
                { screenSize !== 'xs' && <TblContainer>
                    <TblHead setHeadCells={setHeadCells} />
                    <TableBody>
                        {answersList.length === 0 && answersSuccess && <EmptyListAlertText />}
                        {answersList.length > 0 &&
                            answersList.map(({ id, text, created_at }) => {
                                return (
                                    <TableRow className="table-main__row" key={id}>
                                        <TableCell>
                                            <Box
                                                sx={{ '& p': { margin: 0 } }}
                                                dangerouslySetInnerHTML={{ __html: text }}
                                            />
                                        </TableCell>
                                        <TableCell>{dateConverter(created_at)}</TableCell>
                                        <TableCell className="action">
                                            <MyTooltip position={'top'} title={dictionary?.delete}>
                                                <DeleteIcon
                                                    onClick={() => deleteAnswerHandler(id)}
                                                />
                                            </MyTooltip>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </TblContainer> }
                <TblPagination data={answersListPaginationData} invalidateTag={'Answers'} />
            </Section>
    );
};

export default Answers;
