import ReactDOMServer from 'react-dom/server';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {Box, Button, TextField, Typography} from '@mui/material';

import ReactQuill, {Quill} from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
import 'react-quill/dist/quill.snow.css';


import {ReactComponent as ItalicIcon} from '../../assets/icons/italic.svg';
import {ReactComponent as BoldIcon} from '../../assets/icons/bold.svg';
import {ReactComponent as UnderlineIcon} from '../../assets/icons/underline.svg';
import {ReactComponent as BulletListIcon} from '../../assets/icons/bullet-list.svg';
import {ReactComponent as OrderedListIcon} from '../../assets/icons/ordered-list.svg';
import {ReactComponent as AlignLeftIcon} from '../../assets/icons/align-left.svg';
import {ReactComponent as AlignCenterIcon} from '../../assets/icons/align-center.svg';
import {ReactComponent as AlignRightIcon} from '../../assets/icons/align-right.svg';
import {ReactComponent as SelectFileIcon} from '../../assets/icons/select-file.svg';
import {ReactComponent as AttachFileIcon} from '../../assets/icons/attach-file.svg';

import './text-editor.scss';
import {PageContext} from "../../pages/Page";
import {useSaveNotificationImageMutation} from "../../api/adminPartApi/apiNotificationsSlice";
import {Modal} from "@mui/material";

var icons = Quill.import('ui/icons');
var size = Quill.import('formats/size');

size.whitelist = ['12px', '14px', '16px', '18px', '20px', '22px'];

Quill.register(size, true);

const ImageFormat = Quill.import("formats/image");

class CustomImageBlot extends ImageFormat {
    static create(value) {
        let node = super.create(value);

        if (typeof value === "object") {
            node.setAttribute("src", value.src);
            node.setAttribute("style", value.style || "");
            node.setAttribute("width", value.width || "auto");
        }

        return node;
    }

    static value(node) {
        return {
            src: node.getAttribute("src"),
            style: node.getAttribute("style"),
            width: node.getAttribute("width"),
        };
    }
}

Quill.register("formats/image", CustomImageBlot);


const BlockEmbed = Quill.import('blots/block/embed');

class YouTubeBlot extends BlockEmbed {
    static blotName = "video";
    static tagName = "div";
    static className = "quill-video-wrapper";

    static create(value) {
        const container = super.create();
        container.setAttribute("contenteditable", "false");

        const uniqueId = `video-${Date.now()}-${Math.floor(Math.random() * 1000)}`;
        container.setAttribute("data-video-id", uniqueId);

        const iframe = document.createElement("iframe");
        iframe.setAttribute("frameborder", "0");
        iframe.setAttribute("allowfullscreen", "true");
        iframe.setAttribute("width", value.width || "340");
        iframe.setAttribute("height", value.height || "260");
        iframe.setAttribute("src", YouTubeBlot.sanitize(value.url));

        container.appendChild(iframe);

        return container;
    }

    static sanitize(url) {
        let match = url?.match(/(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})/);
        return match ? `https://www.youtube.com/embed/${match[1]}` : url;
    }

    static value(node) {
        const iframe = node.querySelector("iframe");
        return {
            url: iframe.getAttribute("src"),
            width: iframe.getAttribute("width"),
            height: iframe.getAttribute("height"),
            id: node.getAttribute("data-video-id"),
        };
    }
}

Quill.register(YouTubeBlot);

const boldIcon = ReactDOMServer.renderToStaticMarkup(<BoldIcon/>);
const italicIcon = ReactDOMServer.renderToStaticMarkup(<ItalicIcon/>);
const underlineIcon = ReactDOMServer.renderToStaticMarkup(<UnderlineIcon/>);
const bulletListIcon = ReactDOMServer.renderToStaticMarkup(<BulletListIcon/>);
const orderedListIcon = ReactDOMServer.renderToStaticMarkup(
    <OrderedListIcon/>
);
const alignLeftIcon = ReactDOMServer.renderToStaticMarkup(<AlignLeftIcon/>);
const alignCenterIcon = ReactDOMServer.renderToStaticMarkup(
    <AlignCenterIcon/>
);
const alignRightIcon = ReactDOMServer.renderToStaticMarkup(<AlignRightIcon/>);

icons['bold'] = boldIcon;
icons['italic'] = italicIcon;
icons['underline'] = underlineIcon;
icons.list['bullet'] = bulletListIcon;
icons.list['ordered'] = orderedListIcon;
icons.align[''] = alignLeftIcon;
icons.align['center'] = alignCenterIcon;
icons.align['right'] = alignRightIcon;

export const CustomToolbar = ({id = "toolbar", sx = {}, isVideo, isImage}) => {
    return (
        <Box sx={sx} id={id}>
            <button className="ql-bold"/>
            <button className="ql-italic"/>
            <button className="ql-underline"/>
            <button className="ql-list" value="bullet"/>
            <button className="ql-list" value="ordered"/>
            <button className="ql-align" value=""/>
            <button className="ql-align" value="center"/>
            <button className="ql-align" value="right"/>
            <select className="ql-size" defaultValue="14px">
                {size.whitelist.map((size) => (
                    <option key={size} value={size}>
                        {size}
                    </option>
                ))}
            </select>
            {isImage && <button style={{ zIndex: '-1', position: 'absolute' }} className={`ql-image ${id}`} />}
            {isVideo && <button style={{ zIndex: '-1', position: 'absolute' }} className={`ql-video ${id}`} />}
        </Box>
    );
};

Quill.register('modules/imageResize', ImageResize);


const formats = ['bold', 'italic', 'underline', 'list', 'align', 'size', 'image', 'video'];


export const TextEditor = ({
                               placeholder,
                               toolbarSx,
                               editorSx,
                               onChange = () => {
                               },
                               fieldName = 'toolbar',
                               withToolbar = false,
                               isFullHeight = false,
                               specialSX,
                               isVideo = false,
                               isImage = false,
                               value,
                               ...rest
                           }) => {
    const {screenSize, dictionary} = useContext(PageContext);
    const quillRef = useRef();
    const [saveNotificationImage] = useSaveNotificationImageMutation();
    const [videoUrl, setVideoUrl] = useState('');
    const [videoWidth, setVideoWidth] = useState('340');
    const [videoHeight, setVideoHeight] = useState('260');
    const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
    const [selectedVideoIndex, setSelectedVideoIndex] = useState(null);


    const handleImageUpload = useCallback(() => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        input.onchange = async () => {
            const file = input.files?.[0];
            if (!file) return;

            if (!quillRef.current || !quillRef.current.getEditor()) {
                return;
            }

            try {
                const response = await saveNotificationImage(file).unwrap();

                if (!response?.data?.image) {
                    return;
                }

                let imageUrl = response.data.image.replace(/\\/g, "").replace(/\"/g, "");

                const img = new Image();
                img.src = imageUrl;

                img.onload = () => {
                    if (!quillRef.current || !quillRef.current.getEditor()) {
                        return;
                    }
                    const quill = quillRef.current.getEditor();
                    const range = quill.getSelection() || {index: quill.getLength()};

                    quill.insertEmbed(range.index, "image", imageUrl);
                    quill.setSelection(range.index + 1);
                };
            } catch (error) {
                return;
            }
        };
    }, [quillRef, saveNotificationImage]);


    const openVideoModal = () => {
        setSelectedVideoIndex(null);
        setVideoUrl('');
        setIsVideoModalOpen(true);
    };

    const openEditVideoModal = (videoSrc, index) => {
        setVideoUrl(videoSrc);
        setSelectedVideoIndex(index);
        setIsVideoModalOpen(true);
    };

    const closeVideoModal = () => {
        setVideoUrl('');
        setSelectedVideoIndex(null);
        setIsVideoModalOpen(false);
    };
    const insertOrEditVideo = () => {
        if (!quillRef.current || !quillRef.current.getEditor()) return;

        let sanitizedUrl = YouTubeBlot.sanitize(videoUrl);
        if (!sanitizedUrl.includes("youtube.com")) {
            return;
        }

        const quill = quillRef.current.getEditor();
        const videoBlots = quill.root.querySelectorAll(".quill-video-wrapper");

        if (selectedVideoIndex !== null) {
            const existingBlock = [...videoBlots].find(
                (node) => node.getAttribute("data-video-id") === selectedVideoIndex
            );

            if (existingBlock) {
                const iframe = existingBlock.querySelector("iframe");
                iframe.setAttribute("src", sanitizedUrl);
                iframe.setAttribute("width", videoWidth || "340");
                iframe.setAttribute("height", videoHeight || "260");
                closeVideoModal();
                return;
            }
        }

        const range = quill.getSelection() || { index: quill.getLength() };

        quill.insertEmbed(range.index, "video", {
            url: sanitizedUrl,
            width: videoWidth || "340",
            height: videoHeight || "260",
        });

        quill.insertText(range.index + 1, '\n');

        quill.setSelection(range.index + 2);

        closeVideoModal();
    };


    useEffect(() => {
        if (!quillRef.current || !quillRef.current.getEditor()) return;

        const quill = quillRef.current.getEditor();
        const handleVideoClick = (e) => {
            const videoBlock = e.target.closest(".quill-video-wrapper");
            if (!videoBlock) return;

            const videoId = videoBlock.getAttribute("data-video-id");
            const iframe = videoBlock.querySelector("iframe");
            if (!iframe) return;

            setSelectedVideoIndex(videoId);
            setVideoUrl(iframe.getAttribute("src"));
            setVideoWidth(iframe.getAttribute("width"));
            setVideoHeight(iframe.getAttribute("height"));
            setIsVideoModalOpen(true);
        };

        quill.root.addEventListener("click", handleVideoClick);

        return () => {
            quill.root.removeEventListener("click", handleVideoClick);
        };
    }, []);


    const modules = useCallback(
        (toolbarId) => ({
            toolbar: {
                container: "#" + toolbarId,
                handlers: {
                    image: handleImageUpload,
                    video: openVideoModal,
                },
            },
            clipboard: {
                matchVisual: false,
            },
            imageResize: {
                displayStyles: {
                    backgroundColor: "black",
                    border: "none",
                    color: "white",
                },
                modules: ["Resize", "DisplaySize"],
            },
        }),
        [handleImageUpload]
    );


    useEffect(() => {
        if (quillRef?.current != null && withToolbar && screenSize) {
            if (screenSize !== 'xs') {
                let quill = quillRef.current.getEditor();
                let keyboard = quill.getModule('keyboard');

                delete keyboard.bindings[13];

                keyboard.addBinding({key: 13, ctrlKey: true}, (range) => {
                    quill.insertText(range.index, '\n');
                    quill.setSelection(range.index + 1);
                });

                keyboard.addBinding({key: 13, shiftKey: true}, (range) => {
                    quill.insertText(range.index, '\n');
                    quill.setSelection(range.index + 1);
                });
            }
        }
    }, [withToolbar, screenSize]);


    const handleCustomImageClick = () => {
        const imageButton = document.querySelector(`.ql-image.${fieldName}`);

        if (imageButton) {
            imageButton.click();
        }
    };

    const handleCustomVideoClick = () => {
        const videoButton = document.querySelector(`.ql-video.${fieldName}`);
        if (videoButton) {
            videoButton.click();
        }
    };



    return (
        <Box sx={{...specialSX, width: '100%', height: isFullHeight ? isFullHeight : 'initial'}}>
            {withToolbar &&
                <CustomToolbar id={fieldName || 'toolbar'} sx={toolbarSx} isVideo={isVideo} isImage={isImage}/>}
            <Box
                component={ReactQuill}
                value={value}
                ref={quillRef}
                modules={modules(fieldName)}
                formats={formats}
                onChange={onChange}
                theme="snow"
                placeholder={placeholder}
                sx={editorSx}
                {...rest}
            />

            {(isImage || isVideo) && (
                <Box
                    sx={{
                        display: "flex",
                        position: "relative",
                        justifyContent: "space-between",
                        marginY: "30px",
                        color: "var(--basic-color-900)",
                    }}
                >
                    {isImage && (
                        <Button
                            sx={{
                                width: "fit-content",
                                height: "36px",
                                minWidth: "200px",
                                justifyContent: "center",
                                borderRadius: "5px",
                                color: "white",
                                paddingTop: "8px",
                                paddingRight: "24px",
                                paddingBottom: "8px",
                                paddingLeft: "24px",
                                gap: "6px",

                                "&:hover": {
                                    backgroundColor: "var(--secondary-color-light)",
                                    path: {
                                        fill: "white",
                                    },
                                },

                                path: {
                                    fill: "white",
                                },
                            }}
                            onClick={handleCustomImageClick}
                        >
                            <AttachFileIcon/>
                            {dictionary.select_file}
                        </Button>
                    )}

                    {isVideo && (
                        <Button
                            sx={{
                                width: "fit-content",
                                height: "36px",
                                minWidth: "260px",
                                justifyContent: "center",
                                borderRadius: "5px",
                                color: "white",
                                paddingTop: "8px",
                                paddingRight: "24px",
                                paddingBottom: "8px",
                                paddingLeft: "24px",
                                gap: "6px",

                                "&:hover": {
                                    backgroundColor: "var(--secondary-color-light)",
                                    path: {
                                        fill: "white",
                                    },
                                },

                                path: {
                                    fill: "white",
                                },
                            }}
                            onClick={handleCustomVideoClick}
                        >
                            <SelectFileIcon/>
                            {dictionary.add_video} Youtube
                        </Button>
                    )}

                    {isImage && (
                        <Typography sx={{display: "flex", position: "absolute", top: "40px"}}>
                            JPG / PNG / GIF
                        </Typography>
                    )}
                </Box>
            )}


            <Modal open={isVideoModalOpen} onClose={closeVideoModal}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 3,
                    borderRadius: 2
                }}>
                    <Typography variant="h6">
                        {selectedVideoIndex !== null ? `${dictionary.edit_video}` : `${dictionary.add_video}`}
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder={`${dictionary.enter_link} YouTube`}
                        value={videoUrl}
                        onChange={(e) => setVideoUrl(e.target.value)}
                        sx={{mt: 2}}
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="*340"
                        value={videoWidth}
                        onChange={(e) => setVideoWidth(e.target.value)}
                        sx={{mt: 2}}
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="*260"
                        value={videoHeight}
                        onChange={(e) => setVideoHeight(e.target.value)}
                        sx={{mt: 2}}
                    />
                    <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 3, gap: '16px',}}>
                        <Button sx={{
                            background: 'var(--secondary-color)', color: 'white',
                            ':hover': {background: 'var(--secondary-color-light)', color: 'white'}
                        }}
                                onClick={insertOrEditVideo}
                                disabled={!videoUrl}>
                            {selectedVideoIndex !== null ? `${dictionary.update}` : `${dictionary.add}`}
                        </Button>
                        <Button sx={{
                            background: 'var(--secondary-color)',
                            color: 'white',
                            ':hover': {background: 'var(--secondary-color-light)', color: 'white'}
                        }}
                                onClick={closeVideoModal}>{dictionary.cancel}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    )
        ;
};
