import {FlexBox} from '../../mui/theme';
import {Box} from "@mui/system";
import {IconButton, InputAdornment, TextField, Typography} from "@mui/material";


import {ReactComponent as ViewingIcon} from '../../assets/icons/hide.svg';
import {ReactComponent as SadSmileIcon} from '../../assets/icons/sad-smile.svg';
import {ReactComponent as NeutralSmileIcon} from '../../assets/icons/neutral-smile.svg';
import {ReactComponent as HappySmileIcon} from '../../assets/icons/happy-smile.svg';
import {ReactComponent as SendMessageIcon} from '../../assets/icons/send-message.svg';
import {useContext, useEffect, useRef, useState} from "react";
import {PageContext} from "../../pages/Page";
import {
    useEvaluateNotificationMutation,
    useGetClientNotificationQuery,
    useSendFeedbackOnNotificationMutation
} from "../../api/clientPartApi/apiClientPartNotificationsSlice";

const PopupMessage = () => {

    const popupRef = useRef(null);

    const {
        alertMessageContent,
        notificationPopup,
        setNotificationPopup,
        language,
        dictionary,
    } =
        useContext(PageContext);

    const [messages, setMessages] = useState({});

    const [sendFeedback, { isLoading: sendFeedbackLoading, isFetching: sendFeedbackFetching }] =
        useSendFeedbackOnNotificationMutation();


    const {
        data: notification,
    } = useGetClientNotificationQuery({id: notificationPopup.id}, {skip: !notificationPopup.id});

    const [
        evaluateNotification,
        { isLoading: evaluatedNotificationLoading, isFetching: evaluatedNotificationFetching },
    ] = useEvaluateNotificationMutation();

    const notificationData = notification?.data
    // console.log(notificationData,'notificationData')


    const evaluateNotificationHandler = (id, evaluation) => {
        evaluateNotification({ id, evaluation })
            .unwrap()
            .then()
            .catch((error) => {
                alertMessageContent(dictionary?.something_go_wrong, 'error');
            });
    };

    const sendFeedbackHandler = (id, feedback) => {
        sendFeedback({ id, body: feedback })
            .unwrap()
            .then((res) => {
                alertMessageContent(dictionary?.message_send, 'success');
            })
            .catch((error) => {
                alertMessageContent(dictionary?.message_not_send, 'error');
            });
    };


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setNotificationPopup({show: false});
            }
        };

        if (notificationPopup.show) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [notificationPopup.show, setNotificationPopup]);

    const popupStyles = {
        position: 'fixed',
        width: '888px',
        height: '640px',
        minHeight: '640px',
        bottom: 'calc(50% - 320px)',
        left: 'calc(50% - 444px)',
        zIndex: 100,
        padding: '36px',
        backgroundColor: 'white',
        border: '1px solid',
        borderRadius: '10px',
        borderColor: 'basicColor.color500',
        boxShadow: '0px 0px 20px 0px #0000000A',
    };

    return (
        <>
            {notificationPopup.show &&
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        backgroundColor: 'rgba(217, 217, 217, 0.55)',
                        backdropFilter: 'blur(1px)',
                        zIndex: 99
                    }}
                ></Box>

            }
            {notificationPopup.show && notificationData &&
                <FlexBox
                    className="row-10"
                    alignItems="center"
                    sx={{...popupStyles}}
                    ref={popupRef}
                >
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        minHeight: '568px',
                        height: '100%',
                        padding: '14px',
                        border: '2px solid #D1E2ED',
                        borderRadius: '10px',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '30px',
                            alignItems: 'center',
                            width: '100%'
                        }}>
                            <Typography sx={{
                                fontWeight: 600,
                                fontSize: '16px',
                                lineHeight: '22px',
                                letterSpacing: '0%',
                            }}>{notificationData?.name[language]}</Typography>
                            {/*<Box sx={{display: 'flex', background: 'red', width: '260px', height: '260px',}}></Box>*/}
                            <Typography
                                component="div"
                                className="ql-snow"
                                sx={{width: '100%', overflowY: 'auto', maxHeight: '400px',}}
                                dangerouslySetInnerHTML={{__html: notificationData?.content[language]}}
                            />
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%'
                        }}>
                            <Box sx={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                                <ViewingIcon/>
                                <Typography>{notificationData?.views}</Typography>
                            </Box>
                            {notificationData.feedback && !notificationData.feedback_sended && (
                                <TextField
                                    onChange={(event) => {
                                        setMessages({
                                            ...messages,
                                            [notificationData.id]: event.target.value,
                                        });
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    edge="end"
                                                    sx={{
                                                        opacity: messages[notificationData.id] ? 1 : 0.2,
                                                        pointerEvents: messages[notificationData.id]
                                                            ? 'all'
                                                            : 'none',
                                                    }}
                                                    onClick={() => {
                                                        sendFeedbackHandler(notificationData.id, {
                                                            message: messages[notificationData.id],
                                                        });
                                                    }}
                                                >
                                                    <SendMessageIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                            <Box
                                sx={{
                                    display: 'flex',
                                    zIndex: 100,
                                    gap: '10px',
                                    '& svg': {
                                        cursor: 'pointer',
                                    },
                                    '& svg path': {
                                        fill: 'basicColor.fill900',
                                    },
                                    '& svg.negative path': {
                                        fill: '#FD4D5C',
                                    },
                                    '& svg.neutral path': {
                                        fill: '#196CA7',
                                    },
                                    '& svg.positive path': {
                                        fill: '#6CB351',
                                    },
                                }}
                            >
                                {notificationData.evaluation && (
                                    <>
                                        <SadSmileIcon
                                            className={
                                                notificationData.evaluation?.value === 'negative'
                                                    ? 'negative'
                                                    : ''
                                            }
                                            onClick={() => {
                                                if (!notificationData.evaluation?.value) {
                                                    evaluateNotificationHandler(notificationData.id, 'negative');
                                                }
                                            }}
                                        />
                                        <NeutralSmileIcon
                                            className={
                                                notificationData.evaluation?.value === 'neutral' ? 'neutral' : ''
                                            }
                                            onClick={() => {
                                                if (!notificationData.evaluation?.value) {
                                                    evaluateNotificationHandler(notificationData.id, 'neutral');
                                                }
                                            }}
                                        />
                                        <HappySmileIcon
                                            className={
                                                notificationData.evaluation?.value === 'positive'
                                                    ? 'positive'
                                                    : ''
                                            }
                                            onClick={() => {
                                                if (!notificationData.evaluation?.value) {
                                                    evaluateNotificationHandler(notificationData.id, 'positive');
                                                }
                                            }}
                                        />
                                    </>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </FlexBox>
            }
        </>
    )
        ;
};

export default PopupMessage;
