import { Button } from '@mui/material';

const MyButton = ({
  title,
  buttonHandler,
  buttonClasses,
  disabled = false,
  sx,
}) => {
/*  console.log('====================================');
  console.log(disabled);
  console.log('====================================');*/
  return (
    <Button
      className={`btn ${buttonClasses}`}
      sx={sx ? { ...sx } : null}
      variant="contained"
      onClick={buttonHandler}
      disabled={disabled}
    >
      {title}
    </Button>
  );
};

export default MyButton;
