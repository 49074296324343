import {createTheme, Box} from '@mui/material';

import {basic, secondary, accent} from '../helpers/variables.js';

import {createMuiComponent} from './functions';


export const FlexBox = createMuiComponent(Box, 'MuiFlexBox');

export let theme2 = createTheme({
    typography: {
        fontFamily: 'inter',
        fontSize: 14,
        h2: {
            fontSize: 24,
            fontWeight: 600,
            lineHeight: 1.458,
            textAlign: 'left',
            marginBottom: 30,
            color: secondary.main,
        },
        h3: {
            fontSize: 20,
            fontWeight: 600,
            lineHeight: 1.5,
            color: secondary.main,
        },
        h4: {
            marginBottom: 20,
            fontSize: 16,
            fontWeight: 600,
            lineHeight: 1.875,
            color: secondary.main,
        },
        body1: {
            fontSize: 14,
            lineHeight: 1.71,
        },
        body2: {
            fontSize: 14,
            lineHeight: 1.429,
        },
        body3: {
            fontSize: 16,
            fontWeight: 600,
            lineHeight: 1.5,
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 1200,
            lg: 1400,
            xl: 1600,
            xxl: 1920,
        },
    },
});

theme2 = createTheme(theme2, {
    components: {
        MuiPageAuthorization: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                },
            },
        },
        MuiPageContainer: {
            styleOverrides: {
                root: {
                    position: 'relative',

                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    marginInline: 'auto',
                    [theme2.breakpoints.down('xl')]: {
                        padding: '20px 30px 30px 30px',
                    },
                    [theme2.breakpoints.up('xl')]: {
                        padding: '20px 80px 30px 346px',
                    },

                    transition: 'padding 250ms',
                },
            },
        },
        MuiSidebar: {
            styleOverrides: {
                root: {
                    zIndex: 4,
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    [theme2.breakpoints.down('xl')]: {
                        left: '-100%',
                        '&.active': {
                            left: 0,
                        },
                    },

                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: 270,
                    height: '100%',

                    backgroundColor: basic.white,
                    transition: 'left 211ms',
                },
            },
        },
        MuiSidebarButton: {
            styleOverrides: {
                root: {
                    zIndex: 4,
                    position: 'absolute',
                    [theme2.breakpoints.down('xl')]: {
                        top: 32,
                    },
                    left: 30,
                    top: '-100%',

                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 40,
                    height: 40,

                    borderRadius: 5,
                    backgroundColor: secondary.main,
                    cursor: 'pointer',

                    transition: 'all 250ms',
                    '&.active': {
                        left: 266,
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,

                    },

                    '&:hover': {
                        backgroundColor: secondary.light,
                    },
                    '& svg': {
                        width: 20,
                        height: 20,
                    },
                    '& .MuiSvgIcon-root': {
                        width: 30,
                        height: 30,
                    },
                },
            },
        },
        MuiHeader: {
            styleOverrides: {
                root: {
                    zIndex: 3,
                    padding: 12,

                    backgroundColor: basic.white,
                    borderRadius: 8,

                    '&.header--admin': {
                        width: 'min-content',
                        marginLeft: 'auto',

                        [theme2.breakpoints.up('xl')]: {
                            position: 'absolute',
                            right: 80,
                            top: 20,
                        },
                    },
                    '&.header--md': {
                        width: 'calc(100% - 270px) !important',
                        borderRadius: '0 !important',
                        marginLeft: 'auto',
                    },
                    '&.header--client': {
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: 12,
                        flexWrap: 'wrap',

                        width: '100%',
                        marginBottom: 30,

                    },
                },
            },
        },
        MuiInformationBar: {
            styleOverrides: {
                root: {
                    position: 'fixed',
                    right: '-200%',
                    top: 0,
                    zIndex: 4,
                    overflow: 'auto',

                    height: '100vh',
                    paddingBlock: 30,

                    backgroundColor: basic.white,
                    boxShadow: '0px 4px 24px 0px #0000001A',

                    transition: 'right 250ms',

                    '&.show': {
                        right: 0,
                    },
                    '& .information-bar__header': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBottom: 30,
                        paddingInline: 30,
                    },
                    '& .information-bar__icon': {
                        cursor: 'pointer',
                        '& path': {
                            fill: secondary.main,
                            transition: 'fill 250ms',
                        },
                        '&:hover path': {
                            fill: secondary.light,
                        },
                    },
                    '& .information-bar__icon--close': {
                        position: 'absolute',
                        right: 30,
                        width: 18,
                        height: 18,
                    },
                    '& .information-bar__icon--edit': {
                        marginLeft: 10,
                    },
                    '& .information-bar__body': {
                        borderTop: '1px solid #CADDE5',
                        padding: '30px 30px 0 30px',
                    },
                    '& .information-bar__body--dialogues': {
                        height: 'calc(100% - 54px)',
                    },
                },
            },
        },
        MuiForm: {
            styleOverrides: {
                root: {
                    position: 'relative',

                    width: '100%',

                    '&.form--main': {
                        maxWidth: 360,
                        padding: 30,

                        borderRadius: 10,
                        backgroundColor: basic.white,
                        textAlign: 'center',

                    },
                    '&.form--dialogues': {
                        height: '100%',
                    },
                },
            },
        },
        MuiFormInner: {
            styleOverrides: {
                root: {
                    position: 'relative',

                    display: 'flex',
                    flexDirection: 'column',
                    gap: 20,

                    '& .logo': {
                        marginInline: 'auto',

                    },
                },
            },
        },
        MuiFlexBox: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    '&.col, &.col-40, &.col-20, &.col-17, &.col-10, &.col-8, &.col-6, &.col-4': {
                        flexDirection: 'column',
                    },
                    '&.row-4, &.col-4': {
                        gap: 4,
                    },
                    '&.col-6': {
                        gap: 4,
                    },
                    '&.row-8, &.col-8': {
                        gap: 8,
                    },
                    '&.row-10, &.col-10': {
                        gap: 10,
                    },
                    '&.row-14': {
                        gap: 14,
                    },
                    '&.col-17, &.row-17': {
                        gap: 17,
                    },
                    '&.col-18, &.row-18': {
                        gap: 18,
                    },
                    '&.row-20, &.col-20': {
                        gap: 20,
                    },
                    '&.row-30': {
                        gap: 30,
                    },
                    '&.col-40': {
                        gap: 40,
                    },
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    '& .MuiListItem-root': {
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 20,
                        padding: 14,

                        border: '1px solid #D1E2ED',
                        borderRadius: 5,
                        '&:not(:last-of-type)': {
                            marginBottom: 10,
                        },
                        '& > .MuiBox-root': {
                            width: '100%',
                        },
                        '& .MuiBox-root:not(:first-of-type)': {
                            display: 'flex',
                            justifyContent: 'space-between',
                        },
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    width: '100%',
                    padding: 10,

                    fontFamily: 'inherit',
                    fontWeight: 600,
                    fontSize: 14,
                    lineHeight: 1.4286,
                    textTransform: 'none',

                    boxShadow: 'none',
                    backgroundColor: secondary.main,
                    '&.btn:hover': {
                        backgroundColor: secondary.light,
                        boxShadow: 'none',
                    },
                    '&.Mui-disabled, &.btn--disabled': {
                        backgroundColor: secondary.main,
                        color: basic.white,
                        opacity: 0.2,
                        pointerEvents: 'none',
                    },
                    '&.btn--primary': {
                        maxWidth: '200px',
                    },
                    '&.btn--big': {
                        maxWidth: '100%',
                    },
                    '&.btn--small': {
                        maxWidth: 170,
                        minHeight: 40,
                    },
                    '&.btn--extra-small': {
                        maxWidth: 150,
                    },
                    '&.btn--notification': {
                        position: 'relative',

                        padding: 11,

                        fontWeight: 500,
                        lineHeight: 1.2143,

                        '&::before': {
                            position: 'absolute',
                            content: '""',
                            top: 6,
                            right: 6,

                            width: 8,
                            height: 8,

                            backgroundColor: accent.secondLight,
                            borderRadius: '50%',
                            animation: 'fade-in 1s infinite ease alternate',
                        },
                        '@keyframes fade-in': {
                            from: {
                                backgroundColor: accent.secondLight,
                            },
                            to: {
                                backgroundColor: accent.second,
                            },
                        },
                    },
                    '&.btn--light': {
                        backgroundColor: accent.third,
                        '&:hover': {
                            backgroundColor: secondary.light,
                        },
                    },
                    '&.btn--cancel': {
                        backgroundColor: basic[300],
                        color: basic.main,
                        '&:hover': {
                            color: basic.white,
                            backgroundColor: secondary.light,
                        },
                    },
                    '&.btn--none': {
                        display: 'none',
                    },
                    '&.btn--transparent': {
                        backgroundColor: basic.white,
                        color: basic.main,
                        borderRadius: 50,
                        border: '2px solid',
                        borderColor: basic[900],
                    },
                    '&.btn--transparent-big': {
                        maxWidth: 154,

                        '&:hover': {
                            color: basic.white,
                            borderColor: secondary.light,
                        },
                    },
                    '&.btn--transparent-small': {
                        maxWidth: 70,
                        fontWeight: 700,
                        marginRight: 10,

                        '&:hover': {
                            color: secondary.main,
                            borderColor: secondary.main,
                            backgroundColor: basic.white,
                        },
                    },
                    '&.btn--transparent-small.active': {
                        color: basic.white,
                        borderColor: secondary.main,
                        backgroundColor: secondary.main,
                    },
                    '&.btn--steps': {
                        marginLeft: 'auto',
                    },
                    '&.btn--commands': {
                        width: 120,

                        backgroundColor: '#E3E5F1',
                        color: basic.main,
                        borderRadius: 0,

                        '&:hover': {
                            color: basic.white,
                            backgroundColor: secondary.light,
                        },
                    },
                    '&.btn--dialogues-extraBig': {
                        maxWidth: 240,
                        height: 56,
                        fontSize: 16,
                    },
                    '&.btn--dialogues-big': {
                        maxWidth: 180,

                    },
                    '&.btn--dialogues-small': {
                        maxWidth: 140,
                    },
                },
                text: {
                    borderRadius: 5,
                    color: basic.main,
                },
            },
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    '&.MuiAccordionSummary-root.Mui-expanded': {
                        minHeight: 0,
                    },
                },
            },
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    gap: 2,
                },
                grouped: {
                    minWidth: 38,
                    width: 'auto',
                    height: 30,
                    padding: '5px 10px',

                    fontWeight: 400,
                    fontSize: 14,
                    lineHeight: 1.43,

                    color: basic.main,
                    border: 'none',
                    borderRadius: 5,
                    backgroundColor: basic[100],

                    transition: 'background-color 250ms',

                    '&:hover': {
                        backgroundColor: basic[200],
                    },

                    '&.Mui-selected': {
                        backgroundColor: secondary.main,
                        color: basic.white,
                        '&:hover': {
                            backgroundColor: secondary.main,
                        },
                    },
                    '&.Mui-disabled': {
                        border: 0,
                        color: '#C6CBD4',
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    padding: '8px 14px',
                    ':hover': {
                        backgroundColor: 'transparent',
                    },
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: secondary.main,
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    '&.Mui-expanded': {
                        margin: 0,
                    },
                    '&.header__accordion': {
                        zIndex: 3,
                        fontSize: 14,
                        backgroundColor: 'transparent',
                        '&::before': {
                            height: 0,
                        },
                    },
                    '&.toolbar__accordion': {
                        width: '100%',
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    minHeight: 0,
                    padding: '9px 14px',
                    fontWeight: 600,
                    // !
                    lineHeight: 1.5,

                    color: basic.main,
                    backgroundColor: basic[100],

                    borderRadius: 5,
                    '&.MuiButtonBase-root': {
                        '&:hover': {
                            backgroundColor: basic[200],
                        },
                    },
                    '&.header__accordion-button.Mui-expanded': {
                        backgroundColor: basic[500],
                    },
                    '&.header__accordion-button + .MuiCollapse-root': {
                        position: 'absolute',
                        left: -18,
                        top: 50,
                        width: 'calc(100% + 36px)',
                    },
                    '&.header__accordion-button--balance .MuiAccordionSummary-content': {
                        color: secondary.main,
                        marginRight: 46,
                    },
                    '&.header__accordion-button--user .MuiAccordionSummary-content': {
                        marginRight: 18,
                    },
                    '&.toolbar__accordion-button': {
                        pointerEvents: 'none',
                        padding: 0,

                        '& .MuiButtonBase-root': {
                            pointerEvents: 'all',
                        },
                    },
                    '&.toolbar__accordion-button .MuiAccordionSummary-content': {
                        backgroundColor: basic.white,
                    },
                },
                content: {
                    margin: 0,
                    '&.Mui-expanded': {
                        margin: 0,
                    },

                    '& .header__user-icon': {
                        marginRight: 10,
                    },
                },
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    '&.header__accordion-details': {
                        margin: '10px 6px',
                        padding: 12,

                        borderRadius: 5,
                        backgroundColor: basic.white,
                        boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.10)',
                    },
                    '&.toolbar__accordion-details': {
                        padding: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 3.75,
                    },
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    maxWidth: 480,
                    width: '100%',
                    '& input[type="time"]::-webkit-calendar-picker-indicator': {
                        background: 'none',
                        display: 'none',
                    },
                    '& .MuiInputLabel-root.Mui-error': {
                        color: accent.second,
                    },
                    '& .MuiInputLabel-root.Mui-disabled': {
                        color: basic.disabled,
                    },
                    '& .MuiInputBase-root.MuiOutlinedInput-root': {
                        padding: '10px 14px',
                    },
                    '& .MuiInputBase-multiline': {
                        padding: 0,

                        '& textarea': {
                            overflow: 'auto!important',
                            lineHeight: 1.429,
                        },
                    },
                    '& .MuiFormHelperText-root': {
                        position: 'absolute',
                        right: 10,
                        bottom: -8,

                        margin: 0,

                        fontSize: 12,
                        lineHeight: 1.21,

                        color: accent.second,

                        '&.Mui-error': {
                            paddingInline: 4,

                            color: accent.second,
                            backgroundColor: basic.white,
                        },
                    },
                    '& .MuiAutocomplete-input': {
                        padding: 0,
                        width: '100%!important',
                    },
                    // '& .MuiAutocomplete-endAdornment': {
                    //     top: 'calc(50% - 12px)',
                    // },
                    '& .MuiAutocomplete-popupIndicator': {
                        padding: 0,
                    },
                    '& .MuiAutocomplete-tag': {
                        marginInline: '0 4px!important',
                    },
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    justifyContent: 'space-between',
                    gap: 10,
                    marginInline: '0 auto',

                    borderRadius: 5,

                    '&.header__switcher': {
                        padding: '10px 14px',
                        marginInline: 0,

                        backgroundColor: basic[100],

                    },
                    '&.header__switcher:first-of-type': {
                        marginLeft: 'auto',
                    },
                    '.MuiSwitch-root': {
                        width: 36,
                        height: 20,
                        padding: 0,

                        overflow: 'visible',
                    },
                    '.MuiSwitch-switchBase': {
                        top: 2,
                        left: 2,

                        height: 16,
                        width: 16,
                        padding: 0.5,
                        '&+.MuiSwitch-track': {
                            backgroundColor: basic[700],
                            opacity: 1,
                        },
                        '&:hover': {
                            backgroundColor: 'transparent',
                            '&+.MuiSwitch-track': {
                                backgroundColor: basic[600],
                            },
                        },
                        '&.Mui-checked': {
                            // top: 1,
                            left: -4,
                            '& .MuiSwitch-thumb': {
                                backgroundColor: basic.white,
                            },
                            '&+.MuiSwitch-track': {
                                backgroundColor: secondary.main,
                                opacity: 1,
                            },
                            '&:hover+.MuiSwitch-track': {
                                backgroundColor: secondary.light,
                            },
                        },
                    },
                    '.MuiSwitch-thumb': {
                        height: 16,
                        width: 16,

                        boxShadow: 'none',
                    },
                    '.MuiSwitch-track': {
                        position: 'absolute',

                        width: 35,
                        height: 20,

                        backgroundColor: basic[600],
                        borderRadius: 50,
                    },
                    '.MuiFormControlLabel-label': {
                        fontSize: 14,
                        fontWeight: 600,
                        lineHeight: 1.43,
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    padding: '0!important',

                    fontSize: 14,
                    lineHeight: 'normal',

                    color: basic.main,
                    '&.Mui-disabled': {
                        WebkitTextFillColor: basic.disabled,
                    },
                },
                root: {
                    fontStyle: 'normal!important',
                    borderRadius: 5,
                    paddingRight: 0,
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: `1px solid ${basic[700]}`,
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: `1px solid ${basic[800]}`,
                    },
                    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                        borderColor: accent.second,
                    },
                    '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                        borderColor: basic.disabled,
                    },
                    '&.Mui-disabled+.MuiFormLabel-root': {
                        color: basic.disabled,
                    },
                    '&.Mui-disabled .MuiSvgIcon-root': {
                        color: `${basic.disabled}!important`,

                    },
                    '&.Mui-disabled:hover fieldset': {
                        borderColor: `${basic.disabled}!important`,
                    },
                    '& fieldset': {
                        transition: 'border-color 200ms',
                    },
                    '&:hover fieldset': {
                        borderColor: `${basic[800]}!important`,
                    },
                },
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    marginRight: 10,
                    '& + input': {
                        paddingLeft: 0,
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                shrink: {
                    transform: 'translate(10px, -2px) scale(0.85)',
                    paddingInline: 4,
                },
                root: {
                    fontSize: 14,
                    color: basic[700],
                    backgroundColor: basic.white,
                    top: '-6px',
                    '&.Mui-focused': {
                        color: basic.main,
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    height: 26,
                    margin: '4px 0 10px 0',

                    fontSize: 14,

                    color: basic.main,
                    backgroundColor: basic[400],
                    transition: 'background-color 250ms',

                    '&:hover': {
                        backgroundColor: basic[200],
                    },

                    '& .MuiSelect-select': {
                        lineHeight: 1.29,
                    },
                    '& .MuiChip-deleteIcon': {
                        width: 14,
                        height: 14,
                        color: secondary.main,
                        '&:hover': {
                            color: secondary.light,
                        },
                    },

                    '&.MuiChip-label': {
                        paddingLeft: 10,
                        paddingRight: 10,
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                popper: {
                    paddingBottom: 6,
                    zIndex: 2,
                },
                tooltip: {
                    backgroundColor: basic.white,
                    boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.10)',
                    color: basic.main,
                    fontSize: 14,
                    padding: '10px 14px',
                    borderRadius: 5,
                },
                arrow: {
                    color: basic.white,
                },
            },
        },
        MuiPopper: {
            styleOverrides: {
                root: {
                    [theme2.breakpoints.down('xxl')]: {
                        '&.popper.popper--user-status': {
                            '& .col-20::before': {
                                left: 'calc(50% + 150px)!important',
                            },
                        },
                    },
                    '&.popper': {
                        inset: '10px auto auto 0px!important',
                        maxWidth: 340,
                        width: '100%',
                        zIndex: 2,
                    },
                    '&.popper--user-status': {
                        inset: '10px auto auto -60px!important',
                        '& .col-20::before': {
                            left: 'calc(50% + 100px)',
                        },
                    },
                    '& .col-20': {
                        position: 'relative',

                        padding: 20,

                        boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.10)',
                        backgroundColor: basic.white,
                        borderRadius: 5,

                        '&::before': {
                            position: 'absolute',
                            content: '""',
                            top: -1,
                            left: '50%',

                            width: 0,
                            height: 0,
                            borderBottom: '12px solid',
                            borderBottomColor: basic.white,
                            borderRight: '11px solid transparent',
                            borderLeft: '11px solid transparent',
                            transform: 'translate(-50%, -50%)',

                        },
                    },
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    padding: '12px 20px',

                    color: basic.white,
                    borderRadius: 7,
                },
                standardError: {
                    backgroundColor: accent.second,
                },
                action: {
                    alignItems: 'center',
                    paddingTop: 0,
                },
            },
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    width: 120,
                    height: 10,
                    padding: 0,
                    marginInline: 15,
                    marginTop: 'auto',
                    '&.warning .MuiSlider-track, &.warning .MuiSlider-valueLabel': {
                        backgroundColor: accent.second,
                    },
                    '&.zero-balance .MuiSlider-track': {
                        borderTopRightRadius: 12,
                        borderBottomRightRadius: 12,
                    },
                },
                track: {
                    backgroundColor: secondary.main,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    border: 'none',
                },
                rail: {
                    borderRadius: 5,
                    backgroundColor: basic[100],
                    opacity: 1,
                },
                thumb: {
                    height: 10,
                    width: 0,
                    border: 'none',
                    '&::before': {
                        boxShadow: 'none',
                    },
                    '&::after': {
                        display: 'none',
                    },
                    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                        boxShadow: 'none',
                    },
                },
                valueLabel: {
                    padding: 4,

                    fontFamily: 'inherit',
                    fontWeight: 600,
                    fontSize: 12,
                    lineHeight: 1.17,

                    borderRadius: 3,
                    backgroundColor: secondary.main,
                    color: basic.white,

                    '&::before': {
                        width: 4,
                        bottom: 1,
                        left: '55%',
                    },
                    '&.MuiSlider-valueLabelOpen': {
                        transform: 'translate(3%, -100%) scale(1)',
                    },
                },
            },
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    '&.table-main': {
                        height: 'min-content',
                        borderCollapse: 'separate!important',
                        borderSpacing: 2,
                    },
                    '&.table-main--big': {
                        width: '100%',
                    },
                    '&.table-main--middle': {
                        width: 1050,
                    },
                    '&.table-main--small': {
                        maxWidth: 464,
                        width: '100%',
                    },
                    '&.table-main--no-pagination': {
                        marginBottom: 0,
                    },
                    '&:not(.table-main--small) .MuiTableCell-root': {
                        padding: 13,
                        lineHeight: 1.79,

                        [theme2.breakpoints.down('lg')]: {
                            fontSize: 13,
                        },
                        [theme2.breakpoints.down('md')]: {
                            padding: 10,
                            fontSize: 12,
                        },

                        '& .MuiTypography-root': {
                            lineHeight: 1.43,
                            [theme2.breakpoints.down('lg')]: {
                                fontSize: 13,
                            },
                            [theme2.breakpoints.down('md')]: {
                                fontSize: 12,
                            },
                        },
                    },
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&.table-main__row': {
                        '&:nth-of-type(odd)': {
                            backgroundColor: basic[100],
                        },
                        '&:nth-of-type(even)': {
                            backgroundColor: basic[500],
                        },
                        '& svg': {
                            [theme2.breakpoints.down('md')]: {
                                width: 20,
                                height: 20,
                            },
                        },
                    },
                    '&.table-main__row--flex': {
                        display: 'flex',
                        gap: 1.25,

                        lineHeight: 1.376,
                        '& .MuiTableCell-root': {
                            display: 'inline-flex',
                            columnGap: 1.25,
                            padding: '0 !important',
                        },
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    color: basic.main,
                    borderBottom: 'none',
                    '& .MuiTableSortLabel-root': {
                        width: '100%',
                        justifyContent: 'space-between',
                        '&:hover': {
                            color: 'inherit',
                        },
                    },
                    '& .MuiTableSortLabel-root.Mui-active': {
                        color: 'inherit',
                    },
                    '&.status .MuiTypography-root': {
                        maxWidth: 150,
                        marginInline: 'auto',
                        padding: '2px 11px',

                        textAlign: 'center',
                        color: basic.white,
                        borderRadius: 3,
                        [theme2.breakpoints.down('md')]: {
                            padding: '2px 10px',
                        },
                    },
                    '&.status .MuiBox-root': {
                        textAlign: 'center',
                    },
                    '&.status .MuiBox-root .MuiTypography-root': {
                        display: 'inline-block',

                        maxWidth: '100%',
                        marginInline: 0,
                        padding: 0,

                        fontSize: 12,
                        lineHeight: 1.6667,

                        color: basic.main,
                    },
                    '&.status .MuiBox-root .MuiLink-root': {
                        marginTop: 10,
                    },
                    '& .active, & .processed, & .enabled, & .free, & .resolved': {
                        backgroundColor: accent.firstLight,
                    },
                    '& .blocked, & .rejected, & .disabled, & .unresolved': {
                        backgroundColor: accent.secondLight,
                    },
                    '& .stopped, & .archived, & .new, & .deleting, & .chatting': {
                        backgroundColor: accent.thirdLight,
                    },
                    '& .processing': {
                        backgroundColor: secondary.main,
                    },
                    '& .waiting, & .invite': {
                        backgroundColor: accent.fourth,
                    },
                    '& .nopublic': {
                        backgroundColor: basic[700],
                    },
                    '&.action': {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 15,
                        height: '100%',
                    },
                    '&.action svg': {
                        cursor: 'pointer',
                    },
                    '&.ellipsis': {
                        maxWidth: 100,
                    },
                    '&.ellipsis--allDialogues': {
                        maxWidth: 130,
                    },
                    '&.ellipsis--dialogues': {
                        maxWidth: 240,
                        '& .MuiTypography-root': {
                            lineHeight: 1.8,
                        },
                        '& svg': {
                            flexShrink: 0,
                        },
                    },
                    '&.ellipsis .MuiTypography-root': {
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                    },
                },
                head: {
                    padding: '11px 15px',

                    lineHeight: 1.286,

                    color: basic.white,
                    backgroundColor: secondary.main,
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    gap: 30,
                    minHeight: '40px!important',
                    padding: '0!important',
                    marginBottom: 30,

                    '& .MuiBox-root': {
                        display: 'flex',
                        width: '100%',
                        rowGap: 30,
                    },
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {

                    '& .active, & .processed, & .enabled, & .free, & .resolved': {
                        color: accent.firstLight,
                    },
                    '& .blocked, & .rejected, & .disabled, & .unresolved': {
                        color: accent.secondLight,
                    },
                    '& .stopped, & .archived, & .new, & .deleting, & .chatting': {
                        color: accent.thirdLight,
                    },
                    '& .processing': {
                        color: secondary.main,
                    },
                    '& .waiting, & .invite': {
                        color: accent.fourth,
                    },
                    '& .nopublic': {
                        color: basic[700],
                    },
                },
            },
        },
        MuiPagination: {
            styleOverrides: {
                root: {
                    marginTop: 30,
                },
                ul: {
                    justifyContent: 'center',

                    rowGap: 6,
                    li: {
                        '& .MuiButtonBase-root': {
                            height: 40,
                            width: 40,


                            fontWeight: 600,
                            lineHeight: 1.43,

                            backgroundColor: basic[100],
                            borderRadius: 5,
                            border: 'none',
                            '&:hover': {
                                backgroundColor: basic[200],
                            },
                        },
                        '& .MuiButtonBase-root.Mui-selected': {
                            backgroundColor: secondary.main,
                            color: basic.white,
                        },
                        '& .MuiButtonBase-root.Mui-disabled': {
                            opacity: 1,
                        },
                        '& .MuiButtonBase-root.Mui-disabled svg': {
                            opacity: 0.4,
                        },
                        '& .MuiButtonBase-root.MuiPaginationItem-previousNext': {
                            color: secondary.main,
                        },
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    willChange: 'transform',
                    '&~.MuiSvgIcon-root': {
                        color: secondary.main,
                        transition: 'fill 200ms, cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 200ms',
                    },
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    color: secondary.main,
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    transitionDuration: '0s !important',
                    borderRadius: 5,
                    border: `1px solid ${basic[800]}`,
                    // borderTop: 'none',
                    // borderTopLeftRadius: 0,
                    // borderTopRightRadius: 0,
                    '& .MuiList-root': {
                        padding: 0,
                    },
                    '& .MuiMenuItem-root': {
                        backgroundColor: `${basic.white}!important`,
                    },
                    '& .MuiMenuItem-root:hover': {
                        backgroundColor: `${basic[100]}!important`,
                    },
                    '& .MuiMenuItem-root.Mui-selected': {
                        backgroundColor: `${basic[200]}!important`,
                    },
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                popper: {
                    top: '-1px!important',
                    '&.hidden': {
                        display: 'none',
                    },
                    '& .MuiPaper-root': {
                        borderBottomLeftRadius: 5,
                        borderBottomRightRadius: 5,
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        border: `1px solid ${basic[800]}`,
                    },
                    '& .MuiAutocomplete-listbox': {
                        padding: 0,
                        maxHeight: 220,
                    },
                    '& li:hover': {
                        backgroundColor: `${basic[100]}!important`,
                    },
                    '& .MuiAutocomplete-noOptions': {
                        padding: '8px 14px',
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    minHeight: 40,
                    '& .MuiTabs-indicator': {
                        backgroundColor: secondary.main,
                    },
                    '& .MuiButtonBase-root': {
                        minHeight: 40,
                        padding: 0,
                        fontSize: 14,
                        borderBottom: '2px solid ',
                        borderColor: basic[200],

                        '&.Mui-selected': {
                            color: secondary.main,
                        },
                    },
                    '&.tabs-language': {
                        '& .MuiButtonBase-root': {
                            minWidth: 40,

                            fontWeight: 700,
                            lineHeight: 1.286,

                            borderBottom: '2px solid ',
                            borderColor: basic[200],
                            color: basic.main,

                            transition: 'color 250ms',
                        },
                        '& .MuiButtonBase-root:hover': {
                            color: secondary.main,
                        },
                        '& .Mui-selected': {
                            color: secondary.main,
                        },
                    },
                    '&.tabs-language--sidebar': {
                        margin: '0 auto',
                    },
                    '&.tabs-language--sidebar--settings': {
                        margin: '0',
                    },
                    '&.tabs-clients': {
                        marginBottom: 30,
                        '& .MuiTabs-flexContainer': {
                            gap: 20,


                        },
                        '& .MuiButtonBase-root': {
                            maxWidth: 170,
                            width: '100%',

                            fontWeight: 600,
                            lineHeight: 1.4286,
                            textTransform: 'none',

                            backgroundColor: basic[100],
                            color: basic.main,
                            borderColor: basic[300],
                            borderTopLeftRadius: 5,
                            borderTopRightRadius: 5,

                            transition: 'background-color 250ms, border-color 250ms',
                            '&:hover': {
                                backgroundColor: basic[200],
                                borderColor: '#D2DEE7',
                            },
                        },
                    },
                },
            },
        },
    },
    palette: {
        secondaryColor: {
            main: secondary.main,
            light: secondary.light,
        },
        basicColor: {
            color100: basic[100],
            color200: basic[200],
            color300: basic[300],
            color400: basic[400],
            color500: basic[500],
            color600: basic[600],
            color700: basic[700],
            color800: basic[800],
            color900: basic[900],
            main: basic.main,
            colorWhite: basic.white,
            colorBlack: basic.black,
            disabled: basic.disabled,
        },
        colorAccent: {
            first: accent.first,
            firstLight: accent.firstLight,
            second: accent.second,
            secondLight: accent.secondLight,
            third: accent.third,
            thirdLight: accent.thirdLight,
            fourth: accent.fourth,
            fifth: accent.fifth,
        },
    },
});
