import { useState, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    useChangeInConversationStatusMutation,
    useChangeTakeDialogsStatusMutation,
} from '../../api/clientPartApi/apiConversationSlice';

import { setTakeDialogues, setStatus, setWorkTime } from '../../reducers/authorizationSlice';

import BaseSwitcher from '../../components/ui/Switcher/BaseSwitcher';

import { socket } from '../../pages/Page';

import { PageContext } from '../../pages/Page';

const COUNTER_STEP = 1000;

const HeaderSwitchers = ({ userStatusCounter, setUserStatusCounter }) => {
    const {
        role,
        takeDialogues,
        status,
        workTime,
        inDialogue,
        setAlertMessage,
        alertMessageContent,
        dictionary,
    } = useContext(PageContext);

    const dispatch = useDispatch();

    const [dialogueSwitcher, setDialogueSwitcher] = useState(false);
    const [statusSwitcher, setStatusSwitcher] = useState(false);
    const [statusLabel, setStatusLabel] = useState(dictionary?.away);
    const [startOfWorkTime, setStartOfWorkTime] = useState(workTime?.start);
    const [endOfWorkTime, setEndOfWorkTime] = useState(workTime?.end);
    const [currentTime, setCurrentTime] = useState(
        new Date().toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
        })
    );

    const [changeUserStatus] = useChangeInConversationStatusMutation();
    const [takeDialoguesRequest] = useChangeTakeDialogsStatusMutation();

    const sendTakeDialoguesRequest = () => {
        setAlertMessage({});

        takeDialoguesRequest(1)
            .unwrap()
            .then((res) => {
                console.log(res);
                dispatch(setTakeDialogues(true));
            })
            .catch((error) => {
                console.log(error);
                setDialogueSwitcher(false);
                alertMessageContent(dictionary?.can_not_accept_dialogues, 'error');
            });
    };

    const sendDoNotTakeDialoguesRequest = () => {
        takeDialoguesRequest(0)
            .unwrap()
            .then((res) => {
                console.log(res);
                dispatch(setTakeDialogues(false));
            })
            .catch((error) => console.log(error));
    };

    const sendOfflineRequest = () => {
        setStatusLabel(dictionary?.away);
        setStatusSwitcher(false);

        changeUserStatus('offline')
            .unwrap()
            .then((res) => {
                console.log(res);
                dispatch(setStatus('offline'));
            })
            .catch((error) => console.log(error));
    };

    const sendOnlineRequest = () => {
        setAlertMessage({});
        setStatusLabel(dictionary?.online);
        setStatusSwitcher(true);

        changeUserStatus('online')
            .unwrap()
            .then((res) => {
                dispatch(setStatus('online'));
            })
            .catch((error) => {
                console.log(error);
                setStatusLabel(dictionary?.away);
                setStatusSwitcher(false);
                alertMessageContent(dictionary?.can_not_accept_dialogues, 'error');
            });
    };

    const sendInDialogueRequest = () => {
        setStatusLabel(dictionary?.in_dialogue);

        changeUserStatus('dialog')
            .unwrap()
            .then((res) => {
                dispatch(setStatus('dialog'));
                setStatusSwitcher(true);
            })
            .catch((error) => console.log(error));
    };

    let timer = status === 'dialog' ? 300 : 180;
    const REQUEST_TIME = 180;

    function countDown() {
        timer--;

        if (timer === 0) {
            setUserStatusCounter(clearTimeout(userStatusCounter));
            sendOfflineRequest();
        } else if (timer === REQUEST_TIME && status === 'dialog') {
            sendOnlineRequest();
            setUserStatusCounter(setTimeout(countDown, COUNTER_STEP));
        } else {
            // console.log(timer);
            setUserStatusCounter(setTimeout(countDown, COUNTER_STEP));
        }
    }

    const startTheTimer = (e) => {
        if (!e.target.closest('label')?.classList?.contains('header__switcher--conversation')) {
            if (startOfWorkTime <= currentTime && endOfWorkTime >= currentTime) {
                setUserStatusCounter(clearTimeout(userStatusCounter));

                if (!statusSwitcher) {
                    sendOnlineRequest();
                }

                countDown();
            }
        }
    };

    useEffect(() => {
        const setNewWorkTime = (data) => {

            if (data.status === 'online') {
                dispatch(setStatus('online'))
            } else if (data.status === 'offline') {
                dispatch(setStatus('offline'))
            }

            if (data.workTime?.start !== startOfWorkTime || data.workTime?.end !== endOfWorkTime) {
                dispatch(setWorkTime(data.workTime));

                setStartOfWorkTime(data.workTime?.start);
                setEndOfWorkTime(data.workTime?.end);
            }
        };

        socket.on('client.takedialogs', (data) => {
            setNewWorkTime(data);
        });
        socket.on('client.conversation', (data) => {
            setNewWorkTime(data);
        });
    }, []);

    useEffect(() => {
        if (
            workTime &&
            startOfWorkTime !== '00:00' &&
            endOfWorkTime !== '23:59' &&
            role !== 'adm'
        ) {
            if (
                role === 'op' &&
                endOfWorkTime < currentTime &&
                (status === 'online' || status === 'dialog')
            ) {
                setUserStatusCounter(clearTimeout(userStatusCounter));
                dispatch(setStatus('offline'));
                setStatusSwitcher(false);
            }

            if (role === 'sv' && endOfWorkTime < currentTime && takeDialogues) {
                dispatch(setTakeDialogues(false));
                setDialogueSwitcher(false);
            }

            const interval = setInterval(
                () =>
                    setCurrentTime(
                        new Date().toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                        })
                    ),
                COUNTER_STEP
            );

            return () => {
                clearInterval(interval);
            };
        }
    }, [currentTime, timer, startOfWorkTime, endOfWorkTime]);

    useEffect(() => {
        if (role === 'op' && status !== 'dialog' && workTime) {
            window.addEventListener('click', startTheTimer);

            return () => window.removeEventListener('click', startTheTimer);
        }
    });

    useEffect(() => {
        if (startOfWorkTime <= currentTime && endOfWorkTime >= currentTime && role === 'op') {
            if (inDialogue && status !== 'dialog') {
                setUserStatusCounter(clearTimeout(userStatusCounter));
                sendInDialogueRequest();
            } else if (!inDialogue && status === 'dialog') {
                setUserStatusCounter(clearTimeout(userStatusCounter));
                countDown();
            }
        }
    }, [inDialogue]);

    useEffect(() => {
        if (takeDialogues && (role === 'adm' || (workTime && role === 'sv'))) {
            setDialogueSwitcher(true);
        } else {
            setDialogueSwitcher(false);
        }
        if (
            (status === 'online' || status === 'dialog') &&
            startOfWorkTime < currentTime &&
            endOfWorkTime > currentTime
        ) {
            if (status === 'online') {
                setStatusLabel(dictionary?.online);
            } else {
                setStatusLabel(dictionary?.in_dialogue);
            }

            setStatusSwitcher(true);
            countDown();
        } else {
            setStatusSwitcher(false);
            setStatusLabel(dictionary?.away);
        }
    }, [status]);

    const inConversationHandler = () => {
        if (statusSwitcher) {
            setUserStatusCounter(clearTimeout(userStatusCounter));
            sendOfflineRequest();
        } else {
            setUserStatusCounter(clearTimeout(userStatusCounter));
            if (startOfWorkTime <= currentTime && endOfWorkTime >= currentTime) {
                countDown();
            }
            sendOnlineRequest();
        }
    };

    const takeDialoguesHandler = () => {
        if (dialogueSwitcher) {
            sendDoNotTakeDialoguesRequest();
        } else {
            sendTakeDialoguesRequest();
        }
    };

    return (
        <>
            {role !== 'op' && (
                <BaseSwitcher
                    switcherClass={'header__switcher'}
                    label={dictionary?.accept_dialogues}
                    id={'accept_dialogues'}
                    serverRequest={true}
                    sendServerRequest={takeDialoguesHandler}
                    checked={dialogueSwitcher}
                    setChecked={setDialogueSwitcher}
                />
            )}
            {role === 'op' && (
                <BaseSwitcher
                    switcherClass={'header__switcher header__switcher--conversation'}
                    label={statusLabel}
                    id={'in_conversation'}
                    serverRequest={true}
                    sendServerRequest={inConversationHandler}
                    checked={statusSwitcher}
                    setChecked={setStatusSwitcher}
                />
            )}
        </>
    );
};

export default HeaderSwitchers;
