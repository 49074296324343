import { useCallback, useState, useLayoutEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { usePassAuthorizationMutation } from '../../../api/apiAuthorizationSlice';
import { useClientRegistrationMutation } from '../../../api/clientPartApi/apiRegistrationSlice';
import {
  usePasswordResetRequestMutation,
  useSetNewPasswordMutation,
} from '../../../api/clientPartApi/apiResetPasswordSlice';

import {
  setToken,
  setLogin,
  setRole,
  setClientId,
  setIndexPath,
  setUserOnlineSection,
  setStatus,
  setTakeDialogues,
  setWorkTime,
  setSounds,
  setSoundStatus,
} from '../../../reducers/authorizationSlice';

import { Typography } from '@mui/material';

import { ReactComponent as CallIcon } from '../../../assets/icons/phone-call.svg';

import FormContainer from '../FormContainer';
import RegistrationForm from './RegistrationForm';
import PasswordInput from '../FormInputs/PasswordInput';
import GeneralInput from '../FormInputs/GeneralInput';
import RedirectButtonGroup from './RedirectButtonGroup';
import Title from '../../ui/Title/Title';
import FormCall from './FormCall';

import { ReactComponent as Logo } from '../../../assets/logo-en.svg';

import {
  clientAuthorizationSchema as authorizationSchema,
  clientNewPasswordSchema as newPasswordSchema,
} from '../../../settings/formSchema';

import { PageContext } from '../../../pages/Page';

const ClientPartAuthRegForm = ({ dictionary }) => {
  const {
    resetCode,
    verificationCodeFetchingError,
    resetCodeSuccess,
    resetCodeFetchingError,
    setServerValidationError,
    alertMessageContent,
    setAlertMessage,
  } = useContext(PageContext);

  const [showForm, setShowForm] = useState('authorization');
  const [formTitle, setFormTitle] = useState('');
  const [formHelperText, setFormHelperText] = useState('');
  const [schema, setSchema] = useState(authorizationSchema);
  const [submitButtonTitle, setSubmitButtonTitle] = useState('');
  // since we have one wrapper for all forms, we need to remove registration of
  // the fields to remove validation errors when navigating between forms
  const [unregisterFields, setUnregisterFields] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');
  const [phoneValidationError, setPhoneValidationError] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (resetCodeSuccess) {
      changeFormContentHandler(
        'newPassword',
        dictionary?.enter_new_password,
        dictionary?.email_and_password,
        dictionary?.send,
        newPasswordSchema
      );
    }
  }, [verificationCodeFetchingError, resetCodeSuccess, resetCodeFetchingError]);

  useLayoutEffect(() => {
    if (showForm === 'authorization' && dictionary) {
      setFormTitle(dictionary?.authorization);
      setFormHelperText(dictionary?.enter_to_start);
      setSubmitButtonTitle(dictionary?.enter);
    }
  }, [showForm, dictionary]);

  const [
    checkPass,
    { isLoading: checkPassLoading, isFetching: checkPassFetching },
  ] = usePassAuthorizationMutation();

  const [
    registration,
    { isLoading: registrationLoading, isFetching: registrationFetching },
  ] = useClientRegistrationMutation();

  const [
    resetPassword,
    { isLoading: resetPasswordLoading, isFetching: resetPasswordFetching },
  ] = usePasswordResetRequestMutation();

  const [
    setNewPassword,
    { isLoading: newPasswordLoading, isFetching: newPasswordFetching },
  ] = useSetNewPasswordMutation();

  function changeFormContentHandler(
    form,
    title,
    text,
    buttonTitle,
    schema,
    leaveAlertMessage
  ) {
    setShowForm(form);
    setFormTitle(title);
    setFormHelperText(text);
    setSubmitButtonTitle(buttonTitle);
    setServerValidationError([]);

    if (schema) {
      setUnregisterFields(true);
      setSchema(schema);
    }

    if (!leaveAlertMessage) {
      setAlertMessage({});
    }
  }

  const formSubmitHandler = useCallback(
    (data) => {
      const authorizationData = {
        login: data?.login,
        password: data?.password,
      };

      const registrationData = {
        surname: data?.surname,
        name: data?.name,
        patronymic: data?.patronymic ? data?.patronymic : '',
        phone: data?.phone,
        phone_data: data?.phone_data,
        email: data?.email,
        password: data?.password || data?.registration_password,
        role: selectedRole.role,
      };

      const registrationDataWithCode = {
        ...registrationData,
        code: data?.code,
      };

      if (showForm === 'authorization') {
        checkPass({ data: authorizationData, path: 'client' })
          .unwrap()
          .then((res) => {
            console.log(res);
            if (res?.data?.success) {
              dispatch(setToken(res?.data?.token));
              dispatch(
                setLogin(
                  `${res?.data?.client?.name} ${res?.data?.client?.surname}`
                )
              );
              dispatch(setRole(res?.data?.client?.role));
              dispatch(setClientId(res?.data?.client?.id));
              dispatch(setUserOnlineSection(res?.data?.client?.userOnline));
              dispatch(setStatus(res?.data?.client?.user_status));
              dispatch(setTakeDialogues(res?.data?.client?.take_dialogs));
              dispatch(setWorkTime(res?.data?.client?.workTime));
              dispatch(setIndexPath(''));
              dispatch(setSoundStatus(res?.data?.client?.soundStatus));
              dispatch(setSounds(res?.data?.client?.sounds));
            } else {
              if (res?.errors?.login) {
                setServerValidationError(['login', 'password']);
                alertMessageContent(dictionary?.wrong_email_phone, 'error');
              } else {
                alertMessageContent(dictionary?.something_go_wrong, 'error');
              }
            }
          })
          .catch((error) => {
            console.log(error);
            if (error?.data?.message === 'chat::error.account_blocked') {
              alertMessageContent(dictionary?.account_blocked, 'error');
            } else if (error?.data?.message === 'chat::error.account_stopped') {
              alertMessageContent(dictionary?.account_stopped, 'error');
            }
            if (error?.data?.errors?.login) {
              setServerValidationError(['login', 'password']);
              alertMessageContent(dictionary?.account_stopped, 'error');
              // account_stopped
            }
          });
      } else if (showForm === 'registration') {
        registration({
          data:
            selectedRole === 'adm'
              ? registrationData
              : registrationDataWithCode,
          payload: selectedRole === 'adm' ? '' : '/code',
        })
          .unwrap()
          .then((res) => {
            alertMessageContent(dictionary?.registration_success, 'success');
            setIsSubmited(false);
            changeFormContentHandler(
              'authorization',
              dictionary?.authorization,
              dictionary?.enter_to_start,
              dictionary?.enter,
              authorizationSchema,
              true
            );
          })
          .catch((error) => {
            console.log(error);
            if (error?.data?.errors?.email) {
              setServerValidationError(['email']);
              alertMessageContent(dictionary?.email_exists, 'error');
            } else if (error?.data?.errors?.phone) {
              alertMessageContent(dictionary?.phone_exists, 'error');
              setServerValidationError(['phone']);
            } else if (error?.data?.errors?.code) {
              setServerValidationError(['code']);
              alertMessageContent(dictionary?.wrong_invitation_code, 'error');
            } else {
              alertMessageContent(dictionary?.something_go_wrong, 'error');
            }
          });
      } else if (showForm === 'resetPassword') {
        resetPassword({ email: data?.your_email })
          .unwrap()
          .then((res) => {
            alertMessageContent(dictionary?.instructions_sent, 'success');
          })
          .catch((error) => {
            console.log(error);
            if (error?.data?.errors?.email) {
              setServerValidationError(['email']);
              alertMessageContent(dictionary?.email_not_found, 'error');
            } else {
              alertMessageContent(dictionary?.something_go_wrong, 'error');
            }
          });
      } else if (showForm === 'newPassword') {
        setNewPassword({
          email: data?.email,
          password: data?.new_password,
          code: resetCode,
        })
          .unwrap()
          .then((res) => {
            alertMessageContent(dictionary?.password_has_changed, 'success');
            changeFormContentHandler(
              'authorization',
              dictionary?.authorization,
              dictionary?.enter_to_start,
              dictionary?.enter,
              authorizationSchema,
              true
            );
          })
          .catch((error) => {
            console.log(error);
            if (error?.data?.errors?.email) {
              setServerValidationError(['email']);
              alertMessageContent(dictionary?.wrong_email, 'error');
            } else {
              alertMessageContent(dictionary?.something_go_wrong, 'error');
            }
          });
      }
    },
    [showForm, selectedRole]
  );

  return (
    <FormContainer
      schema={schema}
      submitButtonTitle={submitButtonTitle}
      submitButtonClass={
        showForm === 'initialRegistration'
          ? 'btn--none'
          : 'btn--primary btn--big'
      }
      submitButtonHandler={() => setIsSubmited(true)}
      useButtonHandler={true}
      formClass={'form--main'}
      formSubmitHandler={formSubmitHandler}
      unregisterFields={unregisterFields}
      setUnregisterFields={setUnregisterFields}
      isLoading={
        checkPassLoading ||
        registrationLoading ||
        resetPasswordLoading ||
        newPasswordLoading ||
        !dictionary
      }
      isFetching={
        checkPassFetching ||
        registrationFetching ||
        resetPasswordFetching ||
        newPasswordFetching ||
        !dictionary
      }
    >
      {/* <FormCall /> */}
      <Logo className="logo" />
      <Title heading="h3" title={formTitle} />
      <Typography variant="body1">
        {showForm === 'authorization' ? dictionary?.hello : null}
        {showForm === 'authorization' && <br />}
        {formHelperText}
      </Typography>
      {(showForm === 'registration' || showForm === 'initialRegistration') && (
        <RegistrationForm
          showForm={showForm}
          selectedRole={selectedRole}
          setSelectedRole={setSelectedRole}
          changeFormContent={changeFormContentHandler}
          phoneValidationError={phoneValidationError}
          setPhoneValidationError={setPhoneValidationError}
          dictionary={dictionary}
          isSubmited={isSubmited}
        />
      )}
      {showForm === 'authorization' && (
        <>
          <GeneralInput
            fieldName={'login'}
            label={`Email / ${dictionary?.phone}`}
          />
          <PasswordInput fieldName={'password'} label={dictionary?.password} />
        </>
      )}
      {showForm === 'resetPassword' && (
        <GeneralInput fieldName={'your_email'} label={'Email '} />
      )}
      {showForm === 'newPassword' && (
        <>
          <GeneralInput fieldName={'email'} label={'Email '} />
          <PasswordInput
            fieldName={'new_password'}
            label={dictionary?.new_password}
          />
          <PasswordInput
            fieldName={'confirm_password'}
            label={dictionary?.repeate_password}
          />
        </>
      )}
      {showForm !== 'newPassword' && (
        <RedirectButtonGroup
          changeFormContent={changeFormContentHandler}
          showForm={showForm}
          dictionary={dictionary}
        />
      )}
    </FormContainer>
  );
};

export default ClientPartAuthRegForm;
