import { useContext } from 'react';

import { Toolbar, Box } from '@mui/material';

import FilterAccordion from '../Filter/FilterAccordion';
import MyButton from '../ui/Button/MyButton';
import SearchBar from '../SearchBar/SearchBar';
import TblPageDisplay from '../Table/TablePageDisplay';

import { PageContext } from '../../pages/Page';

const MyToolbar = ({
  changeContentButton = true,
  buttonHandler,
  myButtonTitle,
  buttonSx = {},
  buttonClass,
  paginationData,
  filter,
  search,
  sx = {},
  moveToLeft,
  parametr,
  removedParametr,
  withoutSearchQueryString = false,
  setSearch,
  infobar = '',
  disabled = false,
}) => {
  const { dictionary } = useContext(PageContext);

  return (
    <Toolbar
      sx={{
        alignItems:
          search || filter || changeContentButton ? 'normal' : 'flex-end',
        flexDirection: search ? 'column' : 'row',
      }}
    >
      {(filter || changeContentButton) && (
        <Box>
          {filter && (
            <FilterAccordion disabled={disabled} moveToLeft={moveToLeft} />
          )}
          {changeContentButton && (
            <MyButton
              title={myButtonTitle ? myButtonTitle : dictionary?.create}
              sx={{ ...buttonSx }}
              buttonHandler={buttonHandler}
              buttonClasses={buttonClass ? buttonClass : 'btn--primary'}
            />
          )}
        </Box>
      )}
      {(search || paginationData) && (
        <Box
          sx={{
            ...sx,
            justifyContent: search ? 'space-between' : 'flex-end',
            alignItems: filter && !search ? 'flex-end' : 'normal',
            maxWidth: filter && !search ? '220px !important' : 'inherit',
          }}
        >
          {search && (
            <SearchBar
              placeholder={search}
              withoutSearchQueryString={withoutSearchQueryString}
              setSearch={setSearch}
              //   disabled={disabled}
            />
          )}
          {paginationData && (
            <TblPageDisplay
              data={paginationData}
              sx={{
                position: filter && !search ? 'absolute' : 'relative',
                bottom: filter && !search ? 5 : 0,
              }}
              parametr={parametr}
              removedParametr={removedParametr}
            />
          )}
          {infobar}
        </Box>
      )}
    </Toolbar>
  );
};

export default MyToolbar;
