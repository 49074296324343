import { useEffect, useState, useMemo, useLayoutEffect, useContext } from 'react';
import { useDropzone } from 'react-dropzone';

import classNames from 'classnames';

import { Controller, useFormContext } from 'react-hook-form';

import { ReactComponent as SelectFileIcon } from '../../../assets/icons/select-file.svg';

import { PageContext } from '../../../pages/Page';

import './uploadFile.scss';

const focusedStyle = {
    borderColor: 'var(--secondary-color)',
};

const acceptStyle = {
    borderColor: 'var(--color-accent-first)',
};

const rejectStyle = {
    borderColor: 'var(--color-accent-second)',
};

const UploadFile = ({ fieldName, file, setFile, title, receivedValue = false, deleteImage }) => {
    const { dictionary } = useContext(PageContext);
    const {
        control,
        formState: { errors },
        setValue,
    } = useFormContext();

    // const [file, setFile] = useState([]);
    // console.log(receivedValue);

    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        accept: {
            'image/jpeg': ['.jpg'],
            'image/png': ['.png'],
        },
        onDrop: (acceptedFiles) => {
            setFile(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            );
        },
    });

    const style = useMemo(
        () => ({
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
            ...(errors[fieldName] ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject, errors[fieldName]]
    );

    const hideDropzone = classNames({ hide: file?.length > 0 });

    const thumbs = file?.map((file) => (
        <div className="dropzone__thumb" key={file || file?.name}>
            <div
                className="dropzone__thumb-inner"
                onClick={() => {
                    setFile([]);
                    deleteImage();
                    setValue(fieldName, '');
                }}
            >
                <img
                    className="dropzone__thumb-avatar"
                    src={file?.preview || file}
                    // Revoke data uri after image is loaded
                    onLoad={() => {
                        URL.revokeObjectURL(file || file?.preview);
                    }}
                />
            </div>
        </div>
    ));

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => file?.forEach((file) => URL.revokeObjectURL(file?.preview));
    }, []);

    useLayoutEffect(() => {
        if (receivedValue) {
            if (Array.isArray(receivedValue)) {
                setFile(receivedValue);
            } else {
                setFile([receivedValue]);
            }
        }
    }, [receivedValue]);

    useEffect(() => {
        if (file?.length > 0) {
            // console.log(file);
            // const some = file[0]?.name

            if (
                (file[0]?.name &&
                    (file[0]?.name?.includes('.jpg') || file[0]?.name?.includes('.png'))) ||
                (!file[0]?.name && (file[0]?.includes('.png') || file[0]?.includes('.jpg')))
            ) {
                setValue(fieldName, file);
            } else {
                setValue(fieldName, '');
            }
        }
    }, [file]);

    // console.log(dictionary,'dsadasd')

    return (
        <Controller
            name={fieldName}
            control={control}
            render={() => (
                <div className="dropzone">
                    <h2 className="dropzone__title">{title}</h2>
                    <div className={'dropzone__container ' + hideDropzone}>
                        <div {...getRootProps({ style, className: 'dropzone__field' })}>
                            <input {...getInputProps()} multiple={false} />
                            <SelectFileIcon />
                            <p className="dropzone__text dropzone__text--main">
                                {dictionary?.select_file}
                            </p>
                        </div>
                        <p className="dropzone__text dropzone__text--secondary">
                            JPG / PNG, {dictionary?.image_size}
                        </p>
                    </div>
                    <aside className="dropzone__thumb-container">{thumbs}</aside>
                </div>
            )}
        />
    );
};

export default UploadFile;
