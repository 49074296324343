export const setNavLinkName = (dictionary) => {
    return [
        {
            id: 'users',
            linkName: dictionary?.users,
        },
        {
            id: 'functional',
            linkName: dictionary?.functional,
        },
        {
            id: 'clients',
            linkName: dictionary?.clients,
        },
        {
            id: 'notifications',
            linkName: dictionary?.notifications,
        },
        {
            id: 'support',
            linkName: dictionary?.support,
        },
        {
            id: 'support/categories',
            linkName: dictionary?.support,
        },
        {
            id: 'support/categories',
            linkName: dictionary?.categories,
            hiddenRoutes: true,
        },
        {
            id: 'support/appeals',
            linkName: dictionary?.appeals,
            hiddenRoutes: true,
        },
        {
            id: 'channels',
            linkName: dictionary?.channels,
        },
        {
            id: 'employees',
            linkName: dictionary?.employees,
        },
        {
            id: 'visitors',
            linkName: dictionary?.visitors,
        },
        {
            id: 'visitors/online',
            linkName: dictionary?.visitors,
        },
        {
            id: 'visitors/online',
            linkName: dictionary?.visitors_online,
            hiddenRoutes: true,
        },
        {
            id: 'visitors/all',
            linkName: dictionary?.visitors_list,
            hiddenRoutes: true,
        },
        {
            id: 'dialogues/active',
            linkName: dictionary?.dialogues,
        },
        {
            id: 'dialogues/all',
            linkName: dictionary?.all,
            hiddenRoutes: true,
        },
        {
            id: 'dialogues/active',
            linkName: dictionary?.active_dialogues,
            hiddenRoutes: true,
        },
        {
            id: 'dialogues/waiting',
            linkName: dictionary?.waiting_dialogues,
            hiddenRoutes: true,
        },
        {
            id: 'templates/commands',
            linkName: dictionary?.templates,
        },
        {
            id: 'templates/commands',
            linkName: dictionary?.commands,
            hiddenRoutes: true,
        },
        {
            id: 'templates/answers',
            linkName: dictionary?.answers,
            hiddenRoutes: true,
        },
    ];
};
