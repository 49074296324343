import * as yup from 'yup';

const sheduleSchema = yup.object().shape({
    mon_switcher: yup.boolean().notRequired(),
    mon_start: yup.string().when('mon_switcher', {
        is: true,
        then: (schema) => schema.required().min(5),
        otherwise: (schema) => schema.notRequired(),
    }),
    mon_end: yup.string().when('mon_switcher', {
        is: true,
        then: (schema) =>
            schema
                .required()
                .min(5)
                .test('isLarger', 'mon_end must be larger than mon_start', (value, testContext) => {
                    if (testContext.parent.mon_start >= value) return false;
                    return true;
                }),
        otherwise: (schema) => schema.notRequired(),
    }),
    tue_switcher: yup.boolean().notRequired(),
    tue_start: yup.string().when('tue_switcher', {
        is: true,
        then: (schema) => schema.required().min(5),
        otherwise: (schema) => schema.notRequired(),
    }),
    tue_end: yup.string().when('tue_switcher', {
        is: true,
        then: (schema) =>
            schema
                .required()
                .min(5)
                .test('isLarger', 'tue_end must be larger than tue_start', (value, testContext) => {
                    if (testContext.parent.tue_start >= value) return false;
                    return true;
                }),
        otherwise: (schema) => schema.notRequired(),
    }),
    wed_switcher: yup.boolean().notRequired(),
    wed_start: yup.string().when('wed_switcher', {
        is: true,
        then: (schema) => schema.required().min(5),
        otherwise: (schema) => schema.notRequired(),
    }),
    wed_end: yup.string().when('wed_switcher', {
        is: true,
        then: (schema) =>
            schema
                .required()
                .min(5)
                .test('isLarger', 'wed_end must be larger than wed_start', (value, testContext) => {
                    if (testContext.parent.wed_start >= value) return false;
                    return true;
                }),
        otherwise: (schema) => schema.notRequired(),
    }),
    thu_switcher: yup.boolean().notRequired(),
    thu_start: yup.string().when('thu_switcher', {
        is: true,
        then: (schema) => schema.required().min(5),
        otherwise: (schema) => schema.notRequired(),
    }),
    thu_end: yup.string().when('thu_switcher', {
        is: true,
        then: (schema) =>
            schema
                .required()
                .min(5)
                .test('isLarger', 'thu_end must be larger than thu_start', (value, testContext) => {
                    if (testContext.parent.thu_start >= value) return false;
                    return true;
                }),
        otherwise: (schema) => schema.notRequired(),
    }),
    fri_switcher: yup.boolean().notRequired(),
    fri_start: yup.string().when('fri_switcher', {
        is: true,
        then: (schema) => schema.required().min(5),
        otherwise: (schema) => schema.notRequired(),
    }),
    fri_end: yup.string().when('fri_switcher', {
        is: true,
        then: (schema) =>
            schema
                .required()
                .min(5)
                .test('isLarger', 'fri_end must be larger than fri_start', (value, testContext) => {
                    if (testContext.parent.fri_start >= value) return false;
                    return true;
                }),
        otherwise: (schema) => schema.notRequired(),
    }),
    sat_switcher: yup.boolean().notRequired(),
    sat_start: yup.string().when('sat_switcher', {
        is: true,
        then: (schema) => schema.required().min(5),
        otherwise: (schema) => schema.notRequired(),
    }),
    sat_end: yup.string().when('sat_switcher', {
        is: true,
        then: (schema) =>
            schema
                .required()
                .min(5)
                .test('isLarger', 'sat_end must be larger than sat_start', (value, testContext) => {
                    if (testContext.parent.sat_start >= value) return false;
                    return true;
                }),
        otherwise: (schema) => schema.notRequired(),
    }),
    sun_switcher: yup.boolean().notRequired(),
    sun_start: yup.string().when('sun_switcher', {
        is: true,
        then: (schema) => schema.required().min(5),
        otherwise: (schema) => schema.notRequired(),
    }),
    sun_end: yup.string().when('sun_switcher', {
        is: true,
        then: (schema) =>
            schema
                .required()
                .min(5)
                .test('isLarger', 'sun_end must be larger than sun_start', (value, testContext) => {
                    if (testContext.parent.sun_start >= value) return false;
                    return true;
                }),
        otherwise: (schema) => schema.notRequired(),
    }),
});

export const emptySchema = yup.object().shape({});

const personalDataSchema = yup.object().shape({
    surname: yup.string().min(3).max(100).required(),
    name: yup.string().min(3).max(100).required(),
    patronymic: yup.string().max(100).notRequired(),
    // patronymic: yup.string().max(100).required(),
    email: yup.string().email().required(),
});

const personalDataSchemaPatronymicNotRequired = personalDataSchema.shape({
    patronymic: yup.string().max(100).notRequired(),
});

const chengePasswordSchema = yup.object().shape({
    new_password: yup.string().min(4).max(100).required(),
    confirm_password: yup
        .string()
        .min(4)
        .max(100)
        .oneOf([yup.ref('new_password')], 'noMatch')
        .required(),
});

export const clientNewPasswordSchema = chengePasswordSchema.shape({
    email: yup.string().email().required(),
});

export const adminAuthorizationSchema = yup.object().shape({
    login: yup.string().max(100).required(),
    password: yup.string().required(),
});

export const contactsSchema = yup.object().shape({
    email: yup.string().email().required(),
    phone: yup.string().required(),
});

export const personalInformationSchema = personalDataSchema.shape({
    phone: yup.string().required(),
});

export const personalInformationSchemaIsNotRequired = personalDataSchemaPatronymicNotRequired.shape({
    phone: yup.string().required(),
});

export const settingsSchema = chengePasswordSchema.shape({
    current_password: yup.string().min(4).max(100).required(),
});

export const createUserSchema = yup.object().shape({
    login: yup.string().min(3).max(100).required(),
    password: yup.string().min(4).max(100).required(),
    role: yup.string().required(),
});

export const editUserSchema = yup.object().shape(
    {
        login: yup.string().min(3).max(100).required(),
        editedPassword: yup
            .string()
            .notRequired()
            .when('editedPassword', {
                is: (value) => value?.length,
                then: (rule) => rule.min(4).max(100),
            }),
        role: yup.string().required(),
    },
    ['editedPassword', 'editedPassword']
);

export const editeFunctionalSchema = yup.object().shape({
    name_uk: yup.string().min(3).max(100).required(),
    name_en: yup.string().min(3).max(100).required(),
    name_ru: yup.string().min(3).max(100).required(),
    type: yup.string().required(),
    price: yup.number().required(),
});

export const createNotoficationSchema = yup.object().shape({
    title_uk: yup.string().min(3).max(100).required(),
    title_en: yup.string().min(3).max(100).required(),
    title_ru: yup.string().min(3).max(100).required(),
    editor_uk: yup.string().trim().required(),
    editor_en: yup.string().trim().required(),
    editor_ru: yup.string().trim().required(),
    functional: yup.string().required(),
    functionalValue: yup.number().when('functional', {
        is: (value) => value === 'allFunctional',
        then: (schema) =>
            schema
                .nullable()
                .transform((_, val) => (val !== '' ? Number(val) : null))
                .notRequired(),
        otherwise: (schema) => schema.required(),
    }),
    role: yup.string().required(),
});

export const createCategorySchema = yup.object().shape({
    name_uk: yup.string().min(3).max(100).required(),
    name_en: yup.string().min(3).max(100).required(),
    name_ru: yup.string().min(3).max(100).required(),
});

export const customersFilterSchema = yup.object().shape({
    number: yup
        .number()
        .nullable()
        .moreThan(0)
        .transform((_, val) => (val !== '' ? Number(val) : null)),
});

export const clientAuthorizationSchema = yup.object().shape({
    login: yup.string().min(3).max(100).required(),
    password: yup.string().required(),
});

export const clientAdminRegistrationSchema = personalInformationSchemaIsNotRequired.shape({
    registration_password: yup.string().required(),
});

export const clientAdminRegistrationSchemaIsNotRequired = personalInformationSchema.shape({
    registration_password: yup.string().required(),
});

export const clientRegistrationSchema = personalInformationSchema.shape({
    registration_password: yup.string().required(),
    code: yup.string().required(),
});

export const personalInformationSchemaWithoutPatronymic = personalDataSchemaPatronymicNotRequired.shape({
    phone: yup.string().required().min(13),
});

export const clientRegistrationSchemaIsNotRequired = personalInformationSchemaWithoutPatronymic.shape({
    registration_password: yup.string().required(),
    code: yup.string().required(),
});

export const clientResetPasswordSchema = yup.object().shape({
    your_email: yup.string().email().required(),
});

export const createSiteFirstStepSchema = yup.object().shape({
    default_language: yup.string().required(),
});

export const createSiteSecondStepSchema = yup.object().shape({
    logotype: yup.array().required(),
    name: yup.string().min(3).max(100).required(),
    font: yup.string().required(),
    button_bg: yup.string().min(7).max(7).required(),
    button_icon: yup.string().min(7).max(7).required(),
    window_bg: yup.string().min(7).max(7).required(),
    window_content: yup.string().min(7).max(7).required(),
    windiw_bg_message: yup.string().min(7).max(7).required(),
    header_and_copyright_bg: yup.string().min(7).max(7).required(),
    header_and_copyright_content: yup.string().min(7).max(7).required(),
    position: yup.string().required(),
    margin_first: yup
        .number()
        .nullable()
        .moreThan(0)
        .transform((_, val) => (val !== '' ? Number(val) : null))
        .required(),
    margin_second: yup
        .number()
        .nullable()
        .moreThan(0)
        .transform((_, val) => (val !== '' ? Number(val) : null))
        .required(),
    button_rounding: yup
        .number()
        .nullable()
        .moreThan(0)
        .transform((_, val) => (val !== '' ? Number(val) : null))
        .required(),
    window_rounding: yup
        .number()
        .nullable()
        .moreThan(0)
        .transform((_, val) => (val !== '' ? Number(val) : null))
        .required(),
    timeout_switcher: yup.boolean().notRequired(),
    timeout: yup.number().when('timeout_switcher', {
        is: true,
        then: (schema) =>
            schema.transform((_, val) => (val !== '' ? Number(val) : null)).required(),
        otherwise: (schema) =>
            schema
                .nullable()
                .transform((_, val) => (val !== '' ? Number(val) : null))
                .notRequired(),
    }),
});

export const createSiteThirdStepSchema = sheduleSchema.shape({
    timezone: yup.string().required(),
});

export const createSiteFourthStepSchema = yup.object().shape({
    work_greeting_uk: yup.string().when({
        is: (exists) => exists !== undefined,
        then: (schema) => schema.min(3).max(100).required(),
        otherwise: (schema) => schema.notRequired(),
    }),
    work_greeting_en: yup.string().when({
        is: (exists) => exists !== undefined,
        then: (schema) => schema.min(3).max(100).required(),
        otherwise: (schema) => schema.notRequired(),
    }),
    work_greeting_ru: yup.string().when({
        is: (exists) => exists !== undefined,
        then: (schema) => schema.min(3).max(100).required(),
        otherwise: (schema) => schema.notRequired(),
    }),
    work_result_uk: yup.string().when({
        is: (exists) => exists !== undefined,
        then: (schema) => schema.min(3).max(500).required(),
        otherwise: (schema) => schema.notRequired(),
    }),
    work_result_en: yup.string().when({
        is: (exists) => exists !== undefined,
        then: (schema) => schema.min(3).max(500).required(),
        otherwise: (schema) => schema.notRequired(),
    }),
    work_result_ru: yup.string().when({
        is: (exists) => exists !== undefined,
        then: (schema) => schema.min(3).max(500).required(),
        otherwise: (schema) => schema.notRequired(),
    }),
    non_work_greeting_uk: yup.string().when({
        is: (exists) => exists !== undefined,
        then: (schema) => schema.min(3).max(500).required(),
        otherwise: (schema) => schema.notRequired(),
    }),
    non_work_greeting_en: yup.string().when({
        is: (exists) => exists !== undefined,
        then: (schema) => schema.min(3).max(500).required(),
        otherwise: (schema) => schema.notRequired(),
    }),
    non_work_greeting_ru: yup.string().when({
        is: (exists) => exists !== undefined,
        then: (schema) => schema.min(3).max(500).required(),
        otherwise: (schema) => schema.notRequired(),
    }),
});

export const createSiteFifthStepSchema = yup.object().shape({
    first_message_switcher: yup.boolean().notRequired(),
    first_button_text_uk: yup.string().when('first_switcher', {
        is: true,
        then: (schema) =>
            schema.when({
                is: (exists) => exists !== undefined,
                then: (schema) => schema.min(3).max(100).required(),
                otherwise: (schema) => schema.notRequired(),
            }),
        otherwise: (schema) => schema.notRequired(),
    }),
    first_user_message_uk: yup.string().when('first_switcher', {
        is: true,
        then: (schema) =>
            schema.when({
                is: (exists) => exists !== undefined,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.notRequired(),
            }),
        otherwise: (schema) => schema.notRequired(),
    }),
    first_button_text_en: yup.string().when('first_switcher', {
        is: true,
        then: (schema) =>
            schema.when({
                is: (exists) => exists !== undefined,
                then: (schema) => schema.min(3).max(100).required(),
                otherwise: (schema) => schema.notRequired(),
            }),
        otherwise: (schema) => schema.notRequired(),
    }),
    first_user_message_en: yup.string().when('first_switcher', {
        is: true,
        then: (schema) =>
            schema.when({
                is: (exists) => exists !== undefined,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.notRequired(),
            }),
        otherwise: (schema) => schema.notRequired(),
    }),
    first_button_text_ru: yup.string().when('first_switcher', {
        is: true,
        then: (schema) =>
            schema.when({
                is: (exists) => exists !== undefined,
                then: (schema) => schema.min(3).max(100).required(),
                otherwise: (schema) => schema.notRequired(),
            }),
        otherwise: (schema) => schema.notRequired(),
    }),
    first_user_message_ru: yup.string().when('first_switcher', {
        is: true,
        then: (schema) =>
            schema.when({
                is: (exists) => exists !== undefined,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.notRequired(),
            }),
        otherwise: (schema) => schema.notRequired(),
    }),
    second_message_switcher: yup.boolean().notRequired(),
    second_button_text_uk: yup.string().when('second_switcher', {
        is: true,
        then: (schema) =>
            schema.when({
                is: (exists) => exists !== undefined,
                then: (schema) => schema.min(3).max(100).required(),
                otherwise: (schema) => schema.notRequired(),
            }),
        otherwise: (schema) => schema.notRequired(),
    }),
    second_user_message_uk: yup.string().when('second_switcher', {
        is: true,
        then: (schema) =>
            schema.when({
                is: (exists) => exists !== undefined,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.notRequired(),
            }),
        otherwise: (schema) => schema.notRequired(),
    }),
    second_button_text_en: yup.string().when('second_switcher', {
        is: true,
        then: (schema) =>
            schema.when({
                is: (exists) => exists !== undefined,
                then: (schema) => schema.min(3).max(100).required(),
                otherwise: (schema) => schema.notRequired(),
            }),
        otherwise: (schema) => schema.notRequired(),
    }),
    second_user_message_en: yup.string().when('second_switcher', {
        is: true,
        then: (schema) =>
            schema.when({
                is: (exists) => exists !== undefined,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.notRequired(),
            }),
        otherwise: (schema) => schema.notRequired(),
    }),
    second_button_text_ru: yup.string().when('second_switcher', {
        is: true,
        then: (schema) =>
            schema.when({
                is: (exists) => exists !== undefined,
                then: (schema) => schema.min(3).max(30).required(),
                otherwise: (schema) => schema.notRequired(),
            }),
        otherwise: (schema) => schema.notRequired(),
    }),
    second_user_message_ru: yup.string().when('second_switcher', {
        is: true,
        then: (schema) =>
            schema.when({
                is: (exists) => exists !== undefined,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.notRequired(),
            }),
        otherwise: (schema) => schema.notRequired(),
    }),
    third_message_switcher: yup.boolean().notRequired(),
    third_button_text_uk: yup.string().when('third_switcher', {
        is: true,
        then: (schema) =>
            schema.when({
                is: (exists) => exists !== undefined,
                then: (schema) => schema.min(3).max(100).required(),
                otherwise: (schema) => schema.notRequired(),
            }),
        otherwise: (schema) => schema.notRequired(),
    }),
    third_user_message_uk: yup.string().when('third_switcher', {
        is: true,
        then: (schema) =>
            schema.when({
                is: (exists) => exists !== undefined,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.notRequired(),
            }),
        otherwise: (schema) => schema.notRequired(),
    }),
    third_button_text_en: yup.string().when('third_switcher', {
        is: true,
        then: (schema) =>
            schema.when({
                is: (exists) => exists !== undefined,
                then: (schema) => schema.min(3).max(100).required(),
                otherwise: (schema) => schema.notRequired(),
            }),
        otherwise: (schema) => schema.notRequired(),
    }),
    third_user_message_en: yup.string().when('third_switcher', {
        is: true,
        then: (schema) =>
            schema.when({
                is: (exists) => exists !== undefined,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.notRequired(),
            }),
        otherwise: (schema) => schema.notRequired(),
    }),
    third_button_text_ru: yup.string().when('third_switcher', {
        is: true,
        then: (schema) =>
            schema.when({
                is: (exists) => exists !== undefined,
                then: (schema) => schema.min(3).max(100).required(),
                otherwise: (schema) => schema.notRequired(),
            }),
        otherwise: (schema) => schema.notRequired(),
    }),
    third_user_message_ru: yup.string().when('third_switcher', {
        is: true,
        then: (schema) =>
            schema.when({
                is: (exists) => exists !== undefined,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.notRequired(),
            }),
        otherwise: (schema) => schema.notRequired(),
    }),
});

export const createSiteSixthStepSchema = yup.object().shape({
    surname_switcher: yup.boolean().notRequired(),
    name_switcher: yup.boolean().notRequired(),
    patronymic_switcher: yup.boolean().notRequired(),
    email_switcher: yup.boolean().notRequired(),
    phone_switcher: yup.boolean().notRequired(),
});

export const createChannelSchema = yup.object().shape({
    token: yup.string().required(),
    name: yup.string().min(3).max(100).required(),
    sites: yup.array().min(1).required(),
});

export const employeeSchema = sheduleSchema.shape({
    role: yup.string().required(),
    sites: yup.array().min(1).required(),
});

export const employeeSchemaWithPersonalInformation = employeeSchema
    .concat(personalInformationSchema)
    .shape({});

export const emailInvitationEmployeeSchema = employeeSchema
    .concat(
        personalInformationSchemaWithoutPatronymic
    )
    .shape({
        invitation_type: yup.string().required(),
    });

export const codeInvitationEmployeeSchema = employeeSchema.shape({
    invitation_type: yup.string().required(),
    invitation_code: yup.string().notRequired(),
});

export const createAppealSchema = yup.object().shape({
    category: yup.string().required(),
    subject: yup.string().min(3).max(100).required(),
    message: yup.string().required(),
});

export const editVisitorSchema = yup.object().shape({
    surname: yup.string().max(100).notRequired(),
    name: yup.string().max(100).notRequired(),
    patronymic: yup.string().max(100).notRequired(),
    email: yup.string().email().notRequired(),
    telegram: yup.string().notRequired(),
    viber: yup.string().notRequired(),
    messenger: yup.string().notRequired(),
});

export const createCommandSchema = yup.object().shape({
    categorie: yup.array().min(1).required(),
    trigger: yup.string().max(100).required(),
    text: yup.string().required(),
});
