import { createContext, useLayoutEffect, useState, useContext } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { setContent, setSkip, setSelectedElementId } from '../reducers/sectionSlice';

import PopupMessage from '../components/PopupMessage/PopupMessage';

import { createMuiComponent } from '../mui/functions';

import { PageContext } from './Page';

const MyPageContainer = createMuiComponent('div', 'MuiPageContainer');

export const PageContainerContext = createContext(null);

const PageContainer = ({ children }) => {
    const { setServerValidationError, content, setExpandedAccordionOfNav, setAlertMessage } =
        useContext(PageContext);

    const [queryParams, setQueryParams] = useSearchParams();

    const { pathname, hash } = useLocation();

    const [siteFormAlert, setSiteFormAlert] = useState('');
    const [dialogueMessagesArray, setDialogueMessagesArray] = useState([]);
    const [messageId, setMessageId] = useState(0);
    const [selectedDialogueWithRedirection, setSelectedDialogueWithRedirection] = useState({
        isClicked: false,
        status: '',
        redirectedFromActiveOrWaitingDialoguesSection: '',
    });

    const dispatch = useDispatch();
    const skip = useSelector((state) => state.sectionSlice.skip);
    const selectedElementId = useSelector((state) => state.sectionSlice.selectedElementId);

    useLayoutEffect(() => {
        if (pathname !== 'editSite' && siteFormAlert) {
            alert(siteFormAlert);
            setSiteFormAlert('');
        }
    }, [pathname]);

    const changeContentHandler = (content, id = '', skip = false, accordion = '') => {
        dispatch(setContent(content));
        dispatch(setSkip(skip));
        dispatch(setSelectedElementId(id));

        setExpandedAccordionOfNav(accordion);
        setAlertMessage({});
        setServerValidationError([]);
    };

    const createSlug = (slug, type, limit = '?limit=15') => {
        if (slug === 'section') {
            return queryParams.get(slug);
        } else if (type === 'array') {
            return queryParams.get(slug) ? `&${queryParams.get(slug)}` : '';
        } else if (slug === 'limit') {
            return queryParams.get(slug) ? `?${slug}=${queryParams.get(slug)}` : limit;
        } else {
            return queryParams.get(slug) ? `&${slug}=${queryParams.get(slug)}` : '';
        }
    };

    return (
        <PageContainerContext.Provider
            value={{
                queryParams,
                setQueryParams,
                createSlug,
                content,
                setContent,
                changeContentHandler,
                skip,
                setSkip,
                setSelectedElementId,
                selectedElementId,
                pathname,
                hash,
                setSiteFormAlert,
                dialogueMessagesArray,
                setDialogueMessagesArray,
                messageId,
                setMessageId,
                selectedDialogueWithRedirection,
                setSelectedDialogueWithRedirection,
            }}
        >
            <MyPageContainer>
                {children}
                <PopupMessage
                    dialogueMessagesArray={dialogueMessagesArray}
                    setDialogueMessagesArray={setDialogueMessagesArray}
                    messageId={messageId}
                    setMessageId={setMessageId}
                    setSelectedDialogueWithRedirection={setSelectedDialogueWithRedirection}
                />
            </MyPageContainer>
        </PageContainerContext.Provider>
    );
};

export default PageContainer;
